import { Box, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useHeader } from "~/app/ubdn-app/providers/HeaderProvider";
import { IconButtonAnimate } from "~/components/animate";
import Iconify from "~/components/iconify";
import MenuPopover from "~/components/menu-popover";
import Scrollbar from "~/components/scrollbar";
import { useSettingsContext } from "~/components/settings/SettingsContext";
import BadgeDot from "~/components/settings/drawer/BadgeDot";
import Block from "~/components/settings/drawer/Block";
import ColorPresetsOptions from "~/components/settings/drawer/ColorPresetsOptions";
import ModeOptions from "~/components/settings/drawer/ModeOptions";
import { isRoot } from "~/utils/utils";
// import NetworkOptions from "~/components/settings/drawer/NetworkOptions";

export default function SettingsPopover({ sx, ...other }: any) {
  const { themeMode, themeLayout, themeStretch, themeContrast, themeDirection, themeColorPresets, onResetSetting } =
    useSettingsContext();
  const theme = useTheme();
  const root = isRoot();

  const defaultSettings: any = {
    themeMode: "light",
    themeDirection: "ltr",
    themeContrast: "default",
    themeLayout: "vertical",
    themeColorPresets: "default",
    themeStretch: false,
  };

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch ||
    themeContrast !== defaultSettings.themeContrast ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets;

  const { settingsOpen, setSettingsOpen } = useHeader();
  const [settingsElement, setSettingsElement] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (settingsOpen && !settingsElement) {
      setSettingsElement(document.querySelector("#settings-icon") as HTMLButtonElement); // refs WILL NOT WORK
    } else {
      setSettingsElement(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsOpen]);

  return (
    <>
      <IconButtonAnimate id="settings-icon" onClick={() => setSettingsOpen(true)}>
        <Iconify
          width="22px"
          height="22px"
          icon="solar:settings-linear"
          sx={{
            ...(root && { color: theme.palette.secondary.main }),
          }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        sx={{ width: 400, height: "400px" }}
        anchorEl={settingsElement}
      >
        <Stack sx={{ height: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2 }}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              Settings
            </Typography>

            <Tooltip title="Reset">
              <Box sx={{ position: "relative" }}>
                {notDefault && <BadgeDot />}
                <IconButton onClick={onResetSetting}>
                  <Iconify icon="ic:round-refresh" />
                </IconButton>
              </Box>
            </Tooltip>

            <IconButton onClick={() => setSettingsOpen(false)}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>

          <Divider sx={{ borderStyle: "dashed" }} />

          <Scrollbar sx={{ p: 2, pb: 0 }}>
            {/* {isAuthorized && <Block title="Network"> */}
            {/* <NetworkOptions auto /> */}
            {/* </Block>} */}

            <Block title="Mode">
              <ModeOptions />
            </Block>

            <Block title="Presets">
              <ColorPresetsOptions />
            </Block>
          </Scrollbar>
        </Stack>
      </MenuPopover>
    </>
  );
}
