import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { useSignLogin } from "./useSignLogin";
import { useSignRegister } from "./useSignRegister";

export const useLogin = (isNewUser = false) => {
  const { open } = useWeb3Modal();
  const { open: web3ModalOpen } = useWeb3ModalState();
  const { address, chain } = useAccount();
  const [flowPending, setFlowPending] = useState(false);
  const { signLogin, loading: signLoading } = useSignLogin();
  const { signRegister, loading: signRegisterLoading } = useSignRegister();
  const [loading, setLoading] = useState(false);

  const { disconnect } = useDisconnect();

  const ready = address && chain;

  const onClick = () => {
    setLoading(true);
    if (ready) {
      return isNewUser ? signRegister() : signLogin();
    } else {
      setFlowPending(true);
      return open();
    }
  };

  useEffect(() => {
    if (flowPending && ready) {
      setFlowPending(false);
      if (isNewUser) signRegister();
      else signLogin();
    }
  }, [ready]);

  useEffect(() => {
    if (!flowPending)
      if (signLoading) {
        setLoading(true);
      } else {
        setLoading(false);
      }
  }, [signLoading]);

  useEffect(() => {
    if (!flowPending)
      if (signRegisterLoading && isNewUser) {
        setLoading(true);
      } else {
        setLoading(false);
      }
  }, [signRegisterLoading]);

  useEffect(() => {
    if (web3ModalOpen == false && loading && flowPending) setLoading(false);
  }, [web3ModalOpen]);

  return { onClick, loading };
};
