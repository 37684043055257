import { useMediaQuery } from "@mui/material";

export const Certic = () => {
  const lap = useMediaQuery("(max-width:1370px)");

  return (
    <svg
      width={lap ? "48" : "32"}
      height={lap ? "48" : "32"}
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2141 5.85922L30.4098 6.98463C30.5565 8.01211 30.63 9.06411 30.6055 10.1161C30.6055 12.4402 30.3364 14.7399 29.7737 16.9907C29.0397 20.0488 27.7186 22.9357 25.9327 25.529C23.8777 28.4892 20.893 31.425 16.5382 33.7736L16 34.0672L15.4618 33.7736C11.107 31.425 8.12234 28.5136 6.06728 25.529C4.28134 22.9357 2.98471 20.0488 2.2263 16.9907C1.66361 14.7399 1.39449 12.4402 1.39449 10.1161C1.39449 9.06411 1.44343 8.01211 1.59021 6.98463L1.78593 5.85922C1.8104 5.76136 1.8104 5.6635 1.78593 5.59011C1.76147 5.49225 1.737 5.41886 1.66361 5.34546C1.61468 5.27207 1.54129 5.19867 1.46789 5.14975C1.39449 5.10082 1.29663 5.07635 1.22324 5.05189C1.14985 5.02742 1.02752 5.05189 0.954127 5.05189C0.880737 5.05189 0.782877 5.10082 0.709481 5.17421C0.636085 5.22314 0.562689 5.29653 0.513761 5.36993C0.464832 5.44332 0.440367 5.54118 0.415902 5.63904L0.220184 6.76441C0.073395 7.86535 0 8.9907 0 10.1161C0 12.5381 0.293578 14.9602 0.880731 17.3087C1.66361 20.5381 3.03364 23.5718 4.91743 26.3118C7.09481 29.4433 10.2508 32.5259 14.8012 34.9724L15.6575 35.4372C15.7554 35.4861 15.8777 35.5106 16 35.5106C16.1223 35.5106 16.2202 35.4861 16.3425 35.4372L17.1988 34.9724C21.7492 32.5259 24.9052 29.4433 27.0825 26.3118C28.9663 23.5962 30.3364 20.5381 31.1193 17.3332C31.7064 14.9846 32 12.5626 32 10.1406C32 9.01517 31.9266 7.88982 31.7798 6.78888L31.5841 5.66351C31.5596 5.56565 31.5351 5.49225 31.4863 5.39439C31.4373 5.321 31.3639 5.24761 31.2905 5.19868C31.2171 5.14975 31.1193 5.10082 31.0459 5.07636C30.948 5.05189 30.8746 5.05189 30.7768 5.07636C30.6789 5.10082 30.6055 5.12528 30.5321 5.17421C30.4587 5.22314 30.3853 5.29653 30.3364 5.36993C30.2874 5.44332 30.2385 5.54118 30.2141 5.61458C30.2141 5.6635 30.2141 5.76136 30.2141 5.85922Z"
        fill="#F1F1F3"
      />
      <path
        d="M5.62684 5.10185L16.0244 1.92142L26.6421 5.17525C26.8133 5.22418 27.0091 5.19971 27.1803 5.12632C27.3516 5.02846 27.4739 4.88167 27.5228 4.71042C27.5718 4.53917 27.5473 4.34344 27.4739 4.17219C27.4005 4.00094 27.2293 3.87861 27.058 3.82969L16.2446 0.526932C16.1222 0.478002 15.9754 0.478002 15.8287 0.526932L5.23539 3.75628C5.13753 3.78075 5.06414 3.82968 4.99075 3.87861C4.91735 3.92754 4.86842 4.00094 4.81949 4.0988C4.77056 4.17219 4.74609 4.27005 4.74609 4.36791C4.74609 4.46576 4.74609 4.56362 4.77056 4.63702C4.79503 4.73488 4.84395 4.80827 4.89289 4.88167C4.94181 4.95506 5.01521 5.004 5.11307 5.05292C5.18646 5.10186 5.28432 5.12632 5.38218 5.12632C5.45557 5.12632 5.55343 5.12631 5.62684 5.10185Z"
        fill="#F1F1F3"
      />
      <path
        d="M0.513215 6.05522L15.3878 35.1194C15.4367 35.2418 15.5346 35.3396 15.6325 35.3885C15.7303 35.4619 15.8771 35.4864 15.9994 35.4864C16.1218 35.4864 16.2441 35.4619 16.3664 35.3885C16.4643 35.3152 16.5622 35.2173 16.6355 35.1194L25.712 18.1408L25.7365 18.1164L31.5591 6.03075C31.6324 5.85952 31.6569 5.66379 31.5836 5.49254C31.5102 5.32129 31.3878 5.1745 31.2166 5.1011C31.0453 5.02771 30.8496 5.00325 30.6783 5.07665C30.5071 5.12557 30.3603 5.27236 30.2869 5.41915L24.4888 17.5047L16.0484 33.3091L1.76092 5.41915C1.71199 5.34576 1.66306 5.27237 1.58967 5.19897C1.51627 5.15004 1.44288 5.10111 1.34502 5.05218C1.24716 5.02771 1.17377 5.00324 1.07591 5.02771C0.978047 5.02771 0.904651 5.05217 0.806791 5.1011C0.7334 5.15003 0.660004 5.19896 0.586608 5.27236C0.53768 5.34576 0.488751 5.41915 0.464286 5.51701C0.439821 5.61487 0.415356 5.68826 0.439821 5.78612C0.439821 5.88398 0.464286 5.98187 0.513215 6.05522Z"
        fill="#F1F1F3"
      />
      <path
        d="M7.63223 18.999H24.195C24.3907 18.999 24.5619 18.9255 24.6842 18.8032C24.8066 18.6809 24.8799 18.4852 24.8799 18.3139C24.8799 18.1182 24.8066 17.9469 24.6842 17.8246C24.5619 17.7023 24.3662 17.6289 24.195 17.6289H7.63223C7.43654 17.6289 7.26531 17.7023 7.14296 17.8246C7.02062 17.9469 6.94727 18.1426 6.94727 18.3139C6.94727 18.5096 7.02062 18.6809 7.14296 18.8032C7.26531 18.9255 7.43654 18.999 7.63223 18.999Z"
        fill="#F1F1F3"
      />
    </svg>
  );
};
