import { Box, Stack, Typography, useMediaQuery } from "@mui/material";

import { useEffect, useState } from "react";
import { GrayLine } from "../icons/GrayLine";
import { LineGradient } from "../icons/LineGradient";
import { SmallTablet } from "./SmallTablet/SmallTablet";

import { platform } from ".";
import s from "../InfoHome.module.css";

export const Platform = () => {
  const smallTablet = useMediaQuery("(max-width:940px)");
  const [active, setActive] = useState(0);

  const getSxActive = (current: number) => {
    if (active === current) {
      return {
        opacity: 1,
        transition: "opacity 0.3s linear, background-image 0.3s linear, color 0.3s linear",
        backgroundImage: "linear-gradient(to right top, #44d3cf, #28d5c5, #09d7b7, #07d8a6, #24d892)",
        color: "#292E41",
        animation: "animate 3s linear infinite",
        animationDelay: "1s",
      };
    } else {
      return {
        opacity: 1,
        transition: "opacity 0.3s linear, background-color 0.3s linear, color 0.3s linear",
        backgroundColor: "#292E41",
        color: "#989BA9",
      };
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prev) => (prev === 4 ? 0 : prev + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box mt={smallTablet ? "72px" : "100px"} sx={{ "@media(max-width:450px)": { mt: "50px" } }}>
      <Typography width="min(100%, 736px)" fontWeight="700" color="#fff" fontSize="24px">
        How to earn rewards and airdrop with DeTrusts early access platform?
      </Typography>
      <Stack
        columnGap="20px"
        display={smallTablet ? "none" : "flex"}
        flexDirection="row"
        flexWrap="wrap"
        alignItems="baseline"
      >
        {platform.map((plat, index) => (
          <Stack
            key={plat.id}
            className={`${s.card} ${active === 0 ? s.active : ""}`}
            alignSelf="baseline"
            width="min(100%, 430px)"
            height="450px"
          >
            <Typography
              sx={{
                transition: "opacity 4s linear, background-image 0.3s linear, color 0.3s linear",
                animation: "animate 3s linear infinite",
                animationDelay: "3s",
              }}
              color={active === index ? "#F1F1F3" : "#292E41"}
              fontSize="150px"
            >
              {plat.name}
            </Typography>
            {active === index ? <LineGradient /> : <GrayLine />}
            <Stack height="250px" justifyContent="space-between">
              <Box mt={2} color="#989BA9" fontSize="16px" lineHeight="19px" component="p">
                {plat.description}
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                p={index === 3 ? 2 : 0}
                sx={{
                  width: "min(100%)",
                  height: "100px",
                  position: "relative",
                  borderRadius: "12px",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  ...getSxActive(index),
                }}
              >
                <Box textAlign={index === 2 ? "left" : undefined} fontSize="16px" component="div">
                  {plat.info}
                </Box>
              </Box>
            </Stack>
          </Stack>
        ))}
      </Stack>
      {smallTablet && <SmallTablet active={active} getSxActive={getSxActive} />}
    </Box>
  );
};
