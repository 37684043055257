import { CircularProgress, IconButton, Input, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Form } from "react-router-dom";
import { EditIcon } from "~/assets/EditIcon";
import { SaveIcon } from "~/assets/SaveIcon";
import { UserInviteLink } from "~/pages/ReferralPage/types";
import { instance } from "~/shared/lib";

const editLink = async (dataQuery: IEditReferralQueryParams) => {
  return (await instance.post("/links/edit", dataQuery)).data;
};

export const EditReferral = ({ referral }: { referral: UserInviteLink }) => {
  const [value, setValue] = useState(referral.name);
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (newName: IEditReferralQueryParams) => editLink(newName),
    onSuccess: () => {
      enqueueSnackbar("Successfully changed name");
      queryClient.invalidateQueries({
        queryKey: ["inviteLinks"],
      });
    },
  });

  const onSubmit = () => {
    console.log("submit");
    if (isEditing) return;

    if (value === referral.name) {
      return;
    }

    if (value.length < 4 || value.length > 16) {
      setValue(referral.name);
      enqueueSnackbar("Name length should be minimum 4 characters, maximum 16 characters", { variant: "error" });
      return;
    }

    const dataQuery = {
      name: value,
      id: referral._id,
    };

    setIsEditing(false);
    return mutate(dataQuery);
  };

  return (
    <Form className="flex" style={{ alignItems: "center" }} onSubmit={onSubmit}>
      {isPending && <CircularProgress size={30} />}

      {!isPending && (
        <>
          <IconButton onClick={() => setIsEditing(!isEditing)} type="submit">
            {isEditing ? (
              <SaveIcon style={{ color: theme.palette.text.secondary }} />
            ) : (
              <EditIcon style={{ color: theme.palette.text.secondary }} />
            )}
          </IconButton>

          {isEditing ? (
            <>
              <Input
                type="text"
                name="name"
                value={value}
                onChange={(event: any) => setValue(event.target.value)}
                sx={{ fontSize: "0.85rem", fontWeight: 400, maxWidth: "500px" }}
              />
            </>
          ) : (
            <Typography variant="body2">{referral.name}</Typography>
          )}
        </>
      )}
    </Form>
  );
};
