import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useAccount, useWriteContract } from "wagmi";
import { abi } from "~/shared/utils/abiLocker";

export const useClaimUbdn = (ubdnAmount: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const {
    writeContract,
    data: hash,
    isSuccess,
    isPending,
  } = useWriteContract({
    mutation: {
      onError(err) {
        enqueueSnackbar(err.message.replace(/^(.*?)\sRequest/, ""), {
          variant: "error",
        });
        // setErrorMessage(err.message)
      },
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess && hash) {
      (window as any).dataLayer.push({
        event: "claimubdnSuccess",
        amount: ubdnAmount,
      });
      queryClient.invalidateQueries({
        queryKey: ["swaps", address],
      });
    }
  }, [isSuccess]);

  const claimTokens = async () => {
    writeContract({
      abi: abi,
      address: import.meta.env.VITE_ADDRESS_UBDNL,
      functionName: "claimTokens",
      args: [],
    });
  };

  return {
    claimTokens,
    loading: isPending,
  };
};
