import Provider from "./provider";
import { Router } from "./router/router";

import "./styles/index.css";

function App() {
  return (
    <div className="wrapper">
      <Provider>
        <Router />
      </Provider>
    </div>
  );
}

export default App;
