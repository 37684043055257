import { useMemo } from "react";
import { useAccount } from "wagmi";
import { SUPPORTED_CHAINS } from "../utils/web3-constants";

export const useValidNetwork = () => {
  const { chain } = useAccount();
  const { address } = useAccount();

  const isValidNetwork = useMemo(() => {
    let isValid = false;

    for (const network of SUPPORTED_CHAINS) {
      if (network.id === chain?.id) {
        isValid = true;
      }
    }

    return isValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, address]);

  return isValidNetwork;
};
