import { useTheme } from "@mui/material";
import { createContext, useContext, useState } from "react";

type ColorTheme = {
  logo: string;
  inActive: string;
  active: string;
  button: string;
  buttonHover: string;
};

interface HeaderProviderProps {
  settingsOpen: boolean;
  setSettingsOpen: (open: boolean) => void;
  connectWalletOpen: boolean;
  setConnectWalletOpen: (open: boolean) => void;
  headerTheme: "light" | "dark" | "primary";
  setHeaderTheme: (value: "light" | "dark" | "primary") => void;
  pallette: ColorTheme;
}

const HeaderContext = createContext<HeaderProviderProps>({} as HeaderProviderProps);

export const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [connectWalletOpen, setConnectWalletOpen] = useState(false);
  const [headerTheme, setHeaderTheme] = useState<"light" | "dark" | "primary">("light");
  const theme = useTheme();

  const COLORS_MAP = {
    light: { logo: "#F1F1F3", inActive: "#989BA9", active: "#FFF", button: "#F1F1F3", buttonHover: "#dedee0" },
    dark: { logo: "#30425A", inActive: "#273852", active: "#FFF", button: "#30425A", buttonHover: "#202d3d" },
    primary: {
      logo: theme.palette.primary.main,
      inActive: "#273852",
      active: "#FFF",
      button: theme.palette.primary.main,
      buttonHover: "#202d3d",
    },
  };

  const pallette = COLORS_MAP[headerTheme];

  return (
    <HeaderContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        pallette,
        headerTheme,
        setHeaderTheme,
        settingsOpen,
        setSettingsOpen,
        connectWalletOpen,
        setConnectWalletOpen,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);
