import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Cookies from "cookies-js";

import { CerticInfo } from "../CerticInfo/CerticInfo";
import s from "../HomePage.module.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLogin } from "~/pages/LoginPage/hooks/useLogin";
import { getJwtInfo } from "~/shared/utils/jwt";

export const TimerComponent: FC<{
  color?: string;
  fill?: string;
  margin?: string;
  tokenPrice: any;
  navigate: (path: string) => void;
}> = ({ navigate, margin, color, fill, tokenPrice }) => {
  const { id } = useParams();
  // const loginUrl = id ? `/${id}/login` : "/login";
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const inviterId = Cookies.get("inviter_id");

  const haveJwt = getJwtInfo();

  useEffect(() => {
    const targetDate = new Date("August 26, 2024 00:00:00").getTime();

    const interval = setInterval(() => {
      const nowDate = new Date().getTime();
      const difference = targetDate - nowDate;

      const date = new Date(difference);
      const secondsLeft = date.getSeconds();
      const minutesLeft = date.getMinutes();
      const hoursLeft = date.getHours();
      const daysLeft = Math.floor(difference / 1000 / 3600 / 24);

      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const ipad = useMediaQuery("(max-width:750px)");

  const { onClick: onClickLogin, loading: loginLoading } = useLogin();
  return (
    <Box
      sx={{
        "@media(max-width:400px)": {
          width: "100%",
          maxWidth: "305px",
          fontSize: "25px",
        },
        margin: "0 auto",
      }}
    >
      <CerticInfo fill={fill} color={color} />
      <Box
        sx={{
          position: "relative",
          borderRadius: "24px",
          backdropFilter: "blur(40px)",
          backgroundColor: "#5d54bd7f",
          paddingTop: "30px",
          paddingBottom: "30px",
          pl: 2,
          pr: 2,
          display: "block",

          "&:before": {
            content: "''",
            position: "absolute",
            inset: 0,
            borderRadius: "20px",
            border: " 1px solid transparent",
            background: "-webkit-linear-gradient(90deg, #5bfcbb, transparent) border-box",
            mask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "destination-out",
            maskComposite: "exclude",
          },
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "30px",
            letterSpacing: "0.02em",
            textAlign: "center",
            "@media(max-width:880px)": {
              fontSize: "17px",
            },
          }}
        >
          Early Access program is succesfully completed!
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            color: "#FFFFFF",
            marginTop: "18px",
          }}
        >
          Launch:
          <br />
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            August 26, 2024
          </span>
        </Typography>
        <div
          style={{
            marginTop: "18px",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            {days} {days === 1 ? "day" : "days"} left
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "4px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <TimeComponent time={hours} />
              </div>
              <Typography className={s.timeDescription} style={{ display: "flex", justifyContent: "center" }}>
                Hours
              </Typography>
            </div>
            <div
              className={s.timerNum}
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              :
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <TimeComponent time={minutes} />
              </div>
              <Typography className={s.timeDescription} style={{ display: "flex", justifyContent: "center" }}>
                Minutes
              </Typography>
            </div>
            <div
              className={s.timerNum}
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              :
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <TimeComponent time={seconds} />
              </div>
              <Typography className={s.timeDescription} style={{ display: "flex", justifyContent: "center" }}>
                Seconds
              </Typography>
            </div>
          </div>
        </div>

        <ToastContainer />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            size="medium"
            className={s.homeJoin || "text-xl text-[#1e2b40]"}
            onClick={onClickLogin}
            sx={{
              marginTop: "18px",
              backgroundColor: "#49FFA8",
              fontWeight: 500,
              fontSize: "14px !important",
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: "#49FFA8D0",
              },
            }}
          >
            Check your statistics
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export const TimeComponent: FC<{ time: number }> = ({ time }) => {
  const f = (t: number) => {
    const value = t.toString();
    return value.length > 1 ? value.split("") : ["0", value];
  };
  const timerMedia = useMediaQuery("(max-width:1129px)");

  return f(time).map((item, index) => (
    <Box
      sx={{
        position: "relative",
        width: timerMedia ? "40px" : "47px",
        height: timerMedia ? "70px" : "80px",
        backgroundColor: "rgba(53, 75, 128, 0.2)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "4px",
        fontSize: timerMedia ? "37px" : "40px",
        borderRadius: "6px",
        zIndex: 1,
        "@media(max-width:400px)": {
          width: "30px",
          height: "50px",
          fontSize: "25px",
        },
        "::before": {
          content: '""',
          position: "absolute",
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          zIndex: -1,
          borderRadius: "6px",
          padding: "1px",
          background: "linear-gradient(rgba(91, 252, 187, 1), rgba(39, 56, 82, 1))",
          WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "destination-out",
          maskComposite: "exclude",
        },
      }}
    >
      {item}
    </Box>
  ));
};
