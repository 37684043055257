export const Check = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      style={{ marginLeft: "-20px", flex: "0 0 120px" }}
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="69.0014" cy="64.9995" r="15.6772" fill="#35FFAA" fill-opacity="0.05" />
      <g filter="url(#filter0_dddd_2568_6882)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69 80.1239C77.3527 80.1239 84.1239 73.3527 84.1239 65C84.1239 64.4469 84.0942 63.9006 84.0363 63.3625L84.7591 62.5918C84.892 63.4174 85 64.3081 85 65C85 73.8366 77.8366 81 69 81C60.1634 81 53 73.8366 53 65C53 56.1634 60.1634 49 69 49C71.4023 49 74.0611 49.797 76.2218 50.8314L75.5756 51.3767C73.5867 50.415 71.3555 49.8761 69 49.8761C60.6473 49.8761 53.8761 56.6473 53.8761 65C53.8761 73.3527 60.6473 80.1239 69 80.1239Z"
          fill="#38FFB7"
        />
      </g>
      <path
        d="M61.623 63.1553L68.0784 69.6107L84.6778 53.0112"
        stroke="#35FFAA"
        stroke-width="2.30548"
        stroke-linecap="round"
      />
      <defs>
        <filter
          id="filter0_dddd_2568_6882"
          x="0.435154"
          y="0.123915"
          width="137.13"
          height="137.13"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.99424" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2568_6882" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.61095" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2568_6882" result="effect2_dropShadow_2568_6882" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9.683" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2568_6882" result="effect3_dropShadow_2568_6882" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.68876" />
          <feGaussianBlur stdDeviation="26.2824" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.25 0 0 0 0 1 0 0 0 0 0.64 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_2568_6882" result="effect4_dropShadow_2568_6882" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_2568_6882" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export const CheckMoble = () => {
  return (
    <svg
      style={{ margin: "-50px" }}
      width="138"
      height="138"
      viewBox="0 0 138 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="69.0014" cy="64.9995" r="15.6772" fill="#35FFAA" fill-opacity="0.05" />
      <g filter="url(#filter0_dddd_2814_3630)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69 80.1239C77.3527 80.1239 84.1239 73.3527 84.1239 65C84.1239 64.4469 84.0942 63.9006 84.0363 63.3625L84.7591 62.5918C84.892 63.4174 85 64.3081 85 65C85 73.8366 77.8366 81 69 81C60.1634 81 53 73.8366 53 65C53 56.1634 60.1634 49 69 49C71.4023 49 74.0611 49.797 76.2218 50.8314L75.5756 51.3767C73.5867 50.415 71.3555 49.8761 69 49.8761C60.6473 49.8761 53.8761 56.6473 53.8761 65C53.8761 73.3527 60.6473 80.1239 69 80.1239Z"
          fill="#38FFB7"
        />
      </g>
      <path
        d="M61.623 63.1558L68.0784 69.6111L84.6778 53.0117"
        stroke="#35FFAA"
        stroke-width="2.30548"
        stroke-linecap="round"
      />
      <defs>
        <filter
          id="filter0_dddd_2814_3630"
          x="0.435154"
          y="0.123915"
          width="137.13"
          height="137.13"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.99424" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2814_3630" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.61095" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2814_3630" result="effect2_dropShadow_2814_3630" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="9.683" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2814_3630" result="effect3_dropShadow_2814_3630" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.68876" />
          <feGaussianBlur stdDeviation="26.2824" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.25 0 0 0 0 1 0 0 0 0 0.64 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_2814_3630" result="effect4_dropShadow_2814_3630" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_2814_3630" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
