interface XIconProps {
  style?: React.CSSProperties;
}

export const XIcon = ({ style }: XIconProps) => {
  return (
    <svg
      viewBox="0 0 28 21"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.4979 8.89219L20.3156 0H18.4629L11.675 7.72078L6.25332 0H0L8.1986 11.6753L0 21H1.85272L9.02117 12.8466L14.7467 21H21L12.4979 8.89219Z"
          fill="currentColor"
        />
      </svg>
    </svg>
  );
};
