import { useMemo } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAccount } from "wagmi";
import * as Page from "~/pages";
import LoginPage from "~/pages/LoginPage/LoginPage";
import { LayoutLight, MainLayout } from "~/screens";
import * as Path from "~/shared/const/route";
import { Loading } from "~/shared/ui";
import * as Loader from "./loader";

const router = (address: string | undefined) =>
  createBrowserRouter([
    {
      id: "root",
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "*",
          Component: Page.NotFound,
        },
        {
          loader: Loader.loaderPublic(address),
          children: [
            {
              path: Path.HOME_ROUTE,
              element: <Page.HomePage />,
              children: [],
            },
            {
              path: Path.HOME_ROUTE + ":id",
              element: <LoginPage />,
            },
            {
              path: Path.HOME_ROUTE + "sign-in",
              element: <LoginPage />,
            },
            {
              path: "/:id/login",
              element: <LoginPage />,
            },
            {
              path: Path.LOGIN_ROUTE,
              element: <LoginPage />,
            },
          ],
        },
      ],
    },
    {
      element: (
        <>
          <LayoutLight />
        </>
      ),
      loader: (params) => {
        return Loader.loaderPrivate(params.request.url, address);
      },
      children: [
        {
          path: Path.ABOUT_ROUTE,
          Component: Page.AboutPage,
        },

        {
          path: Path.DASHBOARD_ROUTE,
          Component: Page.DashboardPage,
        },
        {
          path: Path.CLAIM_ROUTE,
          Component: Page.ClaimPage,
        },
        {
          path: Path.LEADERBOARD_ROUTE,
          Component: Page.LeaderboardPage,
        },
        {
          path: Path.REFERRAL_ROUTE,
          Component: Page.ReferralPage,
        },
        {
          path: Path.REFERRAL_FAQ_ROUTE,
          Component: Page.ReferralFAQPage,
        },
        // auth social
        {
          loader: Loader.loaderEmail,
          path: "veremail/:id",
          Component: Page.EmailConfirmPage,
        },
        {
          loader: Loader.loaderDiscord,
          path: "discord/callback",
          Component: Page.DiscordCallback,
        },
        {
          loader: Loader.loaderTwitter,
          path: "/twitter/callback",
          Component: Page.TwitterCallback,
        },
      ],
    },
  ]);

export const Router = () => {
  const { address } = useAccount();
  const memRouter = useMemo(() => router(address), [address]);
  return <RouterProvider router={memRouter} fallbackElement={<Loading />} />;
};
