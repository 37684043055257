import { Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import CertikIcon from "~/assets/CertikIcon";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { useProjectInfo } from "~/hooks/useProjectInfo";
import { getJwtInfo } from "~/shared/utils/jwt";
import themePalette from "~/theme/palette";
import { Container } from "../Container/Container";

export const Footer = () => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const wallet = useWallet();
  const isAuthorized = !!getJwtInfo() && wallet.isAuthorized;

  const [palette, setPalette] = useState(() => themePalette("dark"));
  const { securityScore } = useProjectInfo();

  const location = useLocation();
  const isRootLoginPage = location.pathname.includes("login") || location.pathname === "/";

  const isAboutPage = location.pathname.includes("about");

  console.log("isRootLoginPage", isRootLoginPage);
  useLayoutEffect(() => {
    if (isRootLoginPage) {
      setPalette(themePalette("dark"));
      return;
    }
    //  else if (isAboutPage) {
    //   if (!isAuthorized) {
    //     setPalette(themePalette("dark"));
    //   } else {
    //     setPalette(themePalette(theme.palette.mode));
    //   }
    // } else {
    //   setPalette(themePalette(theme.palette.mode));
    // }
    setPalette(themePalette(theme.palette.mode));
  }, [isAuthorized, theme, isRootLoginPage, isAboutPage]);

  const media = useMediaQuery("(max-width:575px)");

  return (
    <Stack
      bgcolor={palette.background.paper}
      sx={{
        position: "relative",
        zIndex: 1,
        py: 1.5,
        px: 3,
        width: "100%",
        height: media ? "90px" : "50px",
        gap: 1,
      }}
      alignItems={"center"}
      justifyContent={isDesktop ? "space-between" : "center"}
      flexDirection={isDesktop ? "row" : "column"}
      flexWrap={"wrap"}
      mt="50px"
    >
      <Container>
        <Stack flexDirection={media ? "column" : "row"} alignItems="center" justifyContent="space-between">
          <Stack flexDirection={"row"} gap={3}>
            <Stack flexDirection={"row"} gap={1}>
              <Typography variant="caption" color={palette.text.primary}>
                ©{new Date().getFullYear()} United Blockchain Dollar Network
              </Typography>
            </Stack>
          </Stack>

          <Stack
            flexDirection={"row"}
            gap={3}
            display={isRootLoginPage ? "none" : ""}
            sx={{ cursor: "pointer" }}
            onClick={() => window.open("https://skynet.certik.com/projects/ubd-network", "_blank")}
          >
            <Stack flexDirection={"row"} gap={1} justifyContent="center" alignItems="center">
              <Typography variant="caption" color={theme.palette.text.secondary}>
                Audit&nbsp;by
              </Typography>
              <CertikIcon height="18px" position={"relative"} top={"1px"} fill={"#637381"} />
              <img className="pl-4" src="https://skynet.certik.com/_next/static/media/KYCGoldBadgeDark.0bec3202.svg" />
            </Stack>
            <Stack flexDirection={"row"} gap={0.5} justifyContent="center" alignItems="center">
              <Typography variant="caption" color={theme.palette.text.secondary}>
                Security&nbsp;score:
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.primary.main}
                sx={{
                  fontWeight: "700",
                }}
              >
                {securityScore.securityScore.toFixed(0)}&nbsp;A
              </Typography>
            </Stack>
          </Stack>

          <Stack pt={media ? 1 : 0} flexDirection={"row"} gap={3} alignItems={"center"}>
            <Link href="https://ubdn.com/assets/tos" target="_blank">
              <Typography variant="caption" color={palette.text.primary}>
                Terms of Use
              </Typography>
            </Link>

            <Link href="https://ubdn.com/assets/policy" target="_blank">
              <Typography variant="caption" color={palette.text.primary}>
                Decentralization Policy
              </Typography>
            </Link>
            {/* </Stack> */}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
