export const Left = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0067 5.53331C12.0782 5.60476 12.1349 5.6896 12.1736 5.78298C12.2124 5.87636 12.2323 5.97646 12.2323 6.07755C12.2323 6.17863 12.2124 6.27873 12.1736 6.37212C12.1349 6.4655 12.0782 6.55034 12.0067 6.62178L6.3961 12.2314H21.4624C21.6665 12.2314 21.8621 12.3124 22.0064 12.4567C22.1506 12.601 22.2317 12.7966 22.2317 13.0006C22.2317 13.2046 22.1506 13.4003 22.0064 13.5446C21.8621 13.6888 21.6665 13.7699 21.4624 13.7699H6.3961L12.0067 19.3795C12.151 19.5238 12.2321 19.7196 12.2321 19.9237C12.2321 20.1278 12.151 20.3236 12.0067 20.4679C11.8623 20.6123 11.6666 20.6934 11.4624 20.6934C11.2583 20.6934 11.0626 20.6123 10.9182 20.4679L3.99514 13.5449C3.92362 13.4734 3.86688 13.3886 3.82817 13.2952C3.78946 13.2018 3.76953 13.1017 3.76953 13.0006C3.76953 12.8995 3.78946 12.7994 3.82817 12.7061C3.86688 12.6127 3.92362 12.5278 3.99514 12.4564L10.9182 5.53331C10.9897 5.46179 11.0745 5.40506 11.1679 5.36635C11.2613 5.32763 11.3614 5.30771 11.4624 5.30771C11.5635 5.30771 11.6636 5.32763 11.757 5.36635C11.8504 5.40506 11.9352 5.46179 12.0067 5.53331Z"
        fill="#13151B"
      />
    </svg>
  );
};
