import { useEffect, useMemo, useState } from "react";
import { useReadContract, useAccount } from "wagmi";
import { getContracts } from "../utils/web3-constants";
import UBDNLockerDistributor from "../assets/abi/UBDNLockerDistributor.json";

interface SecurityScore {
  securityScore: number;
  codeSecurity: string;
  communityTrust: string;
}
export const NUMBER_FORMAT_COUNTRY = "en-US";

export const NUMBER_FORMAT_OPTS: Intl.NumberFormatOptions = {
  maximumFractionDigits: 4,
  style: "decimal",
  useGrouping: true,
};
const START_DATE = new Date("2023-07-01");

export const useProjectInfo = () => {
  const [price, setPrice] = useState(3);
  const [securityScore, setSecurityScore] = useState<SecurityScore>({
    securityScore: 89,
    codeSecurity: "92",
    communityTrust: "92",
  });

  // useEffect(() => {
  //   const fetchSecurityScores = async () => {
  //     try {
  //       const response = await fetch("https://api.ubdn.com/stats/certik");
  //       const data = await response.json();
  //       setSecurityScore(data);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };
  //   fetchSecurityScores();

  //   const fetchPrice = async () => {
  //     try {
  //       const response = await fetch("https://api.ubdn.com/stats/ubdn-price");
  //       const data = await response.json();
  //       setPrice(data.value.toFixed(2));
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };

  //   fetchPrice();
  // }, []);

  // const { data: distributedAmountData } = useReadContract({
  //   abi: UBDNLockerDistributor,
  //   functionName: "distributedAmount",
  //   address: getContracts(chain?.id).LOCKER,
  // });
  // const { data: getCurrentRoundData } = useReadContract({
  //   abi: UBDNLockerDistributor,
  //   functionName: "getCurrentRound",
  //   address: getContracts(chain?.id).LOCKER,
  // });
  // // each Epoch is 30 days
  // const currentEpoch = useMemo(() => {
  //   const today = new Date();
  //   const diff = today.getTime() - START_DATE.getTime();
  //   const days = diff / (1000 * 3600 * 24);
  //   return Math.floor(days / 30);
  // }, []);

  // const daysTillNextEpoch = useMemo(() => {
  //   const timeDiff = new Date().getTime() - START_DATE.getTime();
  //   const epochPassed = timeDiff % (1000 * 60 * 60 * 24 * 30);
  //   const epochToNext = 1000 * 60 * 60 * 24 * 30 - epochPassed;
  //   const days = Math.floor(epochToNext / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (epochToNext % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  //   );
  //   const minutes = Math.floor((epochToNext % (1000 * 60 * 60)) / (1000 * 60));
  //   return `${days}d ${hours}h ${minutes}m`;
  // }, []);

  // const currentRound = useMemo(
  //   () => Number(getCurrentRoundData),
  //   [getCurrentRoundData],
  // );

  // const currentEra = 2 ** (currentRound - 1);

  // const distributedAmount = useMemo(() => {
  //   const num = (Number(distributedAmountData) / 10 ** 18 || 0).toFixed(0);
  //   const finalNum = Number(num) + 5_000_000;
  //   if (isNaN(finalNum)) return "...";
  //   return finalNum.toLocaleString(NUMBER_FORMAT_COUNTRY, NUMBER_FORMAT_OPTS);
  // }, [distributedAmountData]);

  return {
    // currentRound,
    // currentEpoch,
    // currentEra,
    // daysTillNextEpoch,
    // distributedAmount,
    securityScore,
    // price,
  };
};
