interface PrevButtonProps {
  style?: React.CSSProperties;
}

export const PrevButton = ({ style }: PrevButtonProps) => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_b_999_3333)">
        <circle cx="35" cy="35.0059" r="35" fill="#F1F1F3" />
      </g>
      <path
        d="M26.8675 36.6016C25.6397 35.8927 25.6397 34.1205 26.8675 33.4117L40.6845 25.4344C41.9123 24.7255 43.4471 25.6116 43.4471 27.0294L43.4471 42.9839C43.4471 44.4016 41.9123 45.2877 40.6845 44.5788L26.8675 36.6016Z"
        fill="#1B2030"
      />
      <defs>
        <filter
          id="filter0_b_999_3333"
          x="-10"
          y="-9.99414"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_999_3333" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_999_3333" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
