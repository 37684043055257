interface LogoIconProps {
  style?: React.CSSProperties;
}

export const LogoIcon = ({ style }: LogoIconProps) => {
  return (
    <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M12.8645 0.111771C12.7649 0.175128 12.5098 0.400397 12.3044 0.618627C9.54163 3.51897 6.25615 4.96914 2.00618 5.16625C0.823906 5.22257 0.736791 5.24369 0.475446 5.49712C0.0336482 5.94062 0.0523157 5.79278 0.0149807 9.42525C-0.0410218 14.6064 0.0523157 16.9155 0.425666 19.5624C1.24081 25.3349 3.51203 29.6995 7.29531 32.7406C8.58959 33.7825 10.2137 34.7681 11.9311 35.5495C12.8458 35.9648 12.964 36 13.3934 36C13.8165 36 13.9409 35.9648 14.7623 35.5847C15.845 35.0919 16.99 34.4794 17.7678 33.9867C22.6276 30.861 25.4277 26.1585 26.3611 19.5553C26.7344 16.9155 26.8278 14.5994 26.7717 9.42525C26.7344 5.79278 26.7531 5.94062 26.3113 5.49712C26.0499 5.24369 25.9628 5.22257 24.7805 5.16625C20.543 4.97618 17.2326 3.51193 14.5072 0.646786C14.2894 0.414477 14.0218 0.168088 13.9098 0.104731C13.6547 -0.0360622 13.1071 -0.0360622 12.8645 0.111771ZM13.9969 3.58937C16.6726 6.01805 20.039 7.43303 23.7912 7.70053L24.4508 7.74981L24.4943 10.5798C24.569 15.5638 24.3636 18.4008 23.7227 21.273C22.7707 25.5742 20.6924 28.8125 17.3633 31.2201C16.6353 31.748 14.7499 32.811 13.9409 33.156L13.3934 33.3812L12.852 33.156C11.9 32.7547 9.97721 31.6424 9.09984 30.9807C5.96369 28.6154 3.99116 25.4475 3.06401 21.273C2.42309 18.4008 2.21775 15.5638 2.29242 10.5798L2.33597 7.74981L2.99556 7.70053C5.14855 7.54566 7.50065 6.91913 9.24918 6.03213C10.4563 5.41968 11.8552 4.43413 12.7388 3.58937C12.9628 3.37818 13.3311 3.09659 13.3747 3.09659C13.4183 3.08955 13.6983 3.31482 13.9969 3.58937Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90947 9.72119L6.71923 10.803L12.292 16.9342L8.95419 20.6065L8.95677 20.6088L6.39014 21.2319L6.92734 24.0321L9.76586 24.3003L10.1418 21.6859L10.1444 21.6883L13.3788 18.1299L16.6131 21.6883L16.6157 21.6859L16.9917 24.3002L19.8302 24.032L20.3674 21.2318L17.8008 20.6087L17.8033 20.6064L14.4656 16.9342L20.0383 10.8031L18.8481 9.72123L13.3788 15.7385L7.90947 9.72119ZM7.07543 12.307L6.62055 11.8065L5.38867 12.9262L7.30739 15.0372L8.10281 14.3142L8.58674 14.8466L7.79132 15.5696L8.34546 16.1793L10.1308 14.5565L7.62891 11.8039L7.07543 12.307ZM20.8401 22.0497L20.3937 24.3762L20.35 24.604L20.1192 24.6258L17.7611 24.8486L21.3685 25.7244L20.8401 22.0497ZM19.6764 12.3017L20.1312 11.8013L21.3689 12.9263L19.4502 15.0372L18.6548 14.3143L18.1709 14.8467L18.9663 15.5697L18.4121 16.1793L16.6267 14.5566L19.1287 11.8039L19.6764 12.3017ZM5.91748 22.0498L6.36381 24.3763L6.40749 24.6041L6.63834 24.6259L8.99648 24.8487L5.38904 25.7245L5.91748 22.0498Z"
        fill="currentColor"
      />
    </svg>
  );
};
