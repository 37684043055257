import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useWriteContract } from "wagmi";

import { instance } from "~/shared/lib";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { abi } from "./abi";
import { IClaim } from "~/pages/ClaimPage/ClaimPage";

const setClaim = async (id: string) => {
  return (await instance.get(`/claims/set/${id}`)).data;
};

export const SetClaim = ({ item }: { item: IClaim }) => {
  const { enqueueSnackbar } = useSnackbar(); // const [errorMessage, setErrorMessage] = useState('')
  const {
    writeContract,
    data: claim,
    isSuccess,
    isPending,
  } = useWriteContract({
    mutation: {
      onError(err) {
        console.error(err);
        if (err.message.includes("FailedInnerCall")) {
          //not enough funds, show error message to contact support
          enqueueSnackbar("Please contact support. Contract error (out of funds)", {
            variant: "error",
          });
          return;
        }

        enqueueSnackbar(err.message.replace(/^(.*?)\sRequest/, ""), {
          variant: "error",
        });
      },
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (id: string) => setClaim(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["withdrawals"],
      });
      enqueueSnackbar("Claim request created successfully", {
        variant: "success",
      });
    },
    onError: (err) => {
      console.error(err);
      enqueueSnackbar(err.message.replace(/^(.*?)\sRequest/, ""), {
        variant: "error",
      });
    },
  });

  // в случае удачного запроса к контракту,
  // отправляется запрос на сервер, что клейм забран

  useEffect(() => {
    if (claim) return mutate(item._id);
  }, [claim]);

  return (
    <>
      {/* {
            errorMessage && <p className="color-red body1 subtitle-margin">{errorMessage}</p>
        } */}
      <LoadingButton
        loading={isPending}
        onClick={() => {
          writeContract({
            abi: abi,
            address: import.meta.env.VITE_ADDRESS_UBDVALIDATOR,
            functionName: "claimReward",
            args: [item.usdtAmount, item.ubdAmount, item.nonce, item.signature],
          });
        }}
        variant="contained"
      >
        Claim
      </LoadingButton>
    </>
  );
};
