import { formatUnits } from "viem";
import { useAccount, useReadContract } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { abi } from "~/shared/utils/abiLocker";

export const useStats = () => {
  const { authInfo } = useWallet();
  const { address } = useAccount();
  const { data: balanceContract, isLoading: isLoadingData }: any = useReadContract({
    abi: abi,
    address: import.meta.env.VITE_ADDRESS_UBDNL,
    functionName: "getUserAvailableAmount",
    args: [address],
  });

  return {
    UBDN: isLoadingData ? "0" : formatUnits(balanceContract[1], 18),
    USDT: authInfo?.usdtBalance.toString() ?? "0",
    UBD: authInfo?.ubdBalance.toString() ?? "0",
  };
};
