import { IconProps } from "./types";

export const LogoFullIcon = ({ style }: IconProps) => {
  return (
    <svg width="100" height="40" viewBox="0 0 256 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.2764 1.16614C41.9492 1.37435 41.1108 2.11465 40.436 2.83182C31.3566 12.3632 20.5596 17.1289 6.5929 17.7766C2.7076 17.9617 2.42131 18.0311 1.56246 18.864C0.110578 20.3214 0.171925 19.8356 0.049231 31.773C-0.13481 48.7999 0.171925 56.388 1.39886 65.0865C4.07768 84.0567 11.5416 98.4001 23.9745 108.394C28.2279 111.818 33.5651 115.057 39.209 117.625C42.215 118.99 42.6035 119.105 44.0145 119.105C45.4051 119.105 45.814 118.99 48.5133 117.74C52.0714 116.121 55.834 114.108 58.3902 112.489C74.3608 102.217 83.5629 86.7635 86.6302 65.0634C87.8571 56.388 88.1639 48.7768 87.9798 31.773C87.8571 19.8356 87.9185 20.3214 86.4666 18.864C85.6077 18.0311 85.3215 17.9617 81.4362 17.7766C67.5104 17.152 56.6315 12.3401 47.6749 2.92436C46.9592 2.16092 46.0799 1.35122 45.7118 1.14301C44.8734 0.680317 43.0739 0.680317 42.2764 1.16614ZM45.9981 12.5945C54.7911 20.5759 65.854 25.2259 78.1848 26.105L80.3524 26.267L80.4955 35.567C80.7409 51.9462 80.0661 61.2693 77.9598 70.7082C74.8311 84.8433 68.0012 95.4851 57.061 103.397C54.6684 105.132 48.4724 108.625 45.814 109.759L44.0145 110.499L42.2355 109.759C39.1068 108.44 32.788 104.785 29.9047 102.611C19.5985 94.8374 13.1161 84.4269 10.0692 70.7082C7.96298 61.2693 7.28817 51.9462 7.53356 35.567L7.6767 26.267L9.84429 26.105C16.9196 25.5961 24.6493 23.5371 30.3955 20.6222C34.3626 18.6095 38.9598 15.3707 41.8636 12.5945C42.5997 11.9005 43.81 10.9751 43.9532 10.9751C44.0963 10.952 45.0165 11.6923 45.9981 12.5945Z"
        fill="#273852"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0785 36.295L25.9899 32.7398L58.5041 68.5119L58.498 68.5174L66.9285 70.5642L65.1628 79.7681L55.8346 80.6494L54.5995 72.0609L54.5927 72.0671L22.0785 36.295ZM17.7033 43.276L23.5705 37.9431L23.5713 37.944L21.7512 39.5984L23.2461 41.2431L25.0662 39.5887L33.2874 48.6336L27.4202 53.9665L25.6011 51.9651L28.2137 49.5904L26.6234 47.8407L24.0107 50.2154L17.7033 43.276ZM67.0147 80.8993L68.4815 73.2536L70.2179 85.3279L58.368 82.451L66.1125 81.7193L66.8712 81.6476L67.0147 80.8993Z"
        fill="#273852"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.8481 36.2924L61.9367 32.7372L29.4225 68.5092L29.4284 68.5146L20.9945 70.5622L22.7602 79.7661L32.0885 80.6474L33.3241 72.0555L33.3339 72.0645L54.6379 48.6259L60.5027 53.9565L62.3218 51.9551L59.7092 49.5805L61.2995 47.8308L63.9121 50.2055L70.2195 43.266L66.1518 39.5688L64.6571 41.2133L62.86 39.5799L65.8481 36.2924ZM20.9083 80.8973L19.4416 73.2518L17.7052 85.3259L29.555 82.449L21.8105 81.7173L21.0519 81.6456L20.9083 80.8973Z"
        fill="#273852"
      />
      <g opacity="0.75">
        <path
          d="M251.193 81.7812L242.429 89.1571V74.2363H239.581V98.0622H242.429V90.3746L251.895 98.0622H256L245.458 89.6201L255.001 81.7812H251.193Z"
          fill="#30425A"
        />
        <path
          d="M166.51 97.1356C167.176 97.7534 168.149 98.0622 169.431 98.0622H173.499V95.8782H170.134C169.542 95.8782 169.098 95.7348 168.803 95.448C168.507 95.1391 168.359 94.72 168.359 94.1905V83.9652H173.24V81.7812H168.359V77.016H165.512V81.7812H162.036V83.9652H165.512V94.4221C165.512 95.5914 165.844 96.4959 166.51 97.1356Z"
          fill="#30425A"
        />
        <path
          d="M137.167 83.1049C138.375 84.3182 138.979 86.0831 138.979 88.3995V98.0622H136.132V88.6973C136.132 86.9766 135.725 85.686 134.912 84.8256C134.123 83.9432 133.05 83.5019 131.694 83.5019C130.708 83.5019 129.784 83.7556 128.921 84.2631C128.058 84.7484 127.356 85.5316 126.813 86.6125C126.271 87.6715 126 89.0503 126 90.749V98.0622H123.152V81.7812H125.963V85.1084C126.21 84.4994 126.519 83.9749 126.887 83.535C127.577 82.7188 128.391 82.1342 129.328 81.7812C130.265 81.4282 131.238 81.2517 132.249 81.2517C134.345 81.2517 135.984 81.8694 137.167 83.1049Z"
          fill="#30425A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.018 93.3963L159.941 95.0509C158.98 96.2422 157.846 97.1357 156.539 97.7313C155.257 98.3049 153.84 98.5917 152.287 98.5917C150.462 98.5917 148.848 98.2166 147.442 97.4666C146.062 96.7165 144.977 95.6907 144.188 94.3891C143.424 93.0654 143.042 91.5763 143.042 89.9217C143.042 88.2671 143.424 86.789 144.188 85.4874C144.977 84.1638 146.037 83.1269 147.368 82.3768C148.724 81.6268 150.24 81.2517 151.917 81.2517C153.568 81.2517 155.023 81.6157 156.28 82.3437C157.562 83.0718 158.561 84.0866 159.275 85.3882C159.99 86.6677 160.348 88.1458 160.348 89.8224V90.8152H146.05C146.148 91.7004 146.39 92.5057 146.777 93.2309C147.319 94.2015 148.071 94.9737 149.032 95.5473C150.018 96.0988 151.165 96.3745 152.471 96.3745C153.605 96.3745 154.628 96.1319 155.541 95.6465C156.453 95.1612 157.279 94.4111 158.018 93.3963ZM155.947 85.2227C156.763 86.148 157.249 87.3172 157.407 88.7304H146.075C146.182 87.9358 146.404 87.2078 146.74 86.5464C147.257 85.5757 147.96 84.8256 148.848 84.2961C149.76 83.7446 150.783 83.4689 151.917 83.4689C153.593 83.4689 154.937 84.0535 155.947 85.2227Z"
          fill="#30425A"
        />
        <path
          d="M201.155 81.7812L195.683 98.0622H192.502L188.325 84.9788L184.182 98.0622H180.965L175.566 81.7812H178.488L182.621 95.315L186.919 81.7812H189.803L194.107 95.4399L198.345 81.7812H201.155Z"
          fill="#30425A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M208.203 97.4666C209.583 98.2166 211.149 98.5917 212.899 98.5917C214.674 98.5917 216.252 98.2166 217.632 97.4666C219.013 96.7165 220.085 95.6907 220.849 94.3891C221.638 93.0875 222.033 91.5983 222.033 89.9217C222.033 88.2451 221.638 86.7559 220.849 85.4543C220.085 84.1527 219.013 83.1269 217.632 82.3768C216.252 81.6268 214.674 81.2517 212.899 81.2517C211.149 81.2517 209.583 81.6268 208.203 82.3768C206.822 83.1269 205.737 84.1527 204.949 85.4543C204.184 86.7559 203.802 88.2451 203.802 89.9217C203.802 91.5983 204.184 93.0875 204.949 94.3891C205.737 95.6907 206.822 96.7165 208.203 97.4666ZM216.116 95.5473C215.204 96.0988 214.132 96.3745 212.899 96.3745C211.691 96.3745 210.631 96.0988 209.719 95.5473C208.807 94.9957 208.092 94.2346 207.574 93.2639C207.056 92.2933 206.797 91.1792 206.797 89.9217C206.797 88.6422 207.056 87.5281 207.574 86.5795C208.092 85.6088 208.807 84.8477 209.719 84.2961C210.631 83.7446 211.691 83.4689 212.899 83.4689C214.132 83.4689 215.204 83.7446 216.116 84.2961C217.028 84.8477 217.743 85.6088 218.261 86.5795C218.779 87.5281 219.037 88.6422 219.037 89.9217C219.037 91.1792 218.779 92.2933 218.261 93.2639C217.743 94.2346 217.028 94.9957 216.116 95.5473Z"
          fill="#30425A"
        />
        <path
          d="M232.778 82.0459C233.74 81.6929 234.713 81.5165 235.699 81.5165H236.291V83.9652H235.662C234.528 83.9652 233.468 84.23 232.482 84.7594C231.521 85.2668 230.744 86.061 230.153 87.142C229.561 88.2009 229.265 89.5687 229.265 91.2454V98.0622H226.418V81.7812H229.228V85.3748C229.49 84.7509 229.823 84.2149 230.227 83.7667C230.966 82.9725 231.817 82.3989 232.778 82.0459Z"
          fill="#30425A"
        />
      </g>
      <path
        d="M149.58 53.657C151.368 51.8154 152.262 49.3408 152.262 46.2332V21.3145H161.027V46.2332C161.027 51.6428 159.348 55.863 155.99 58.894C152.632 61.8865 148.01 63.3828 142.122 63.3828C136.235 63.3828 131.591 61.8865 128.189 58.894C124.831 55.863 123.152 51.6428 123.152 46.2332V21.3145H131.983V46.2332C131.983 49.3408 132.877 51.8154 134.665 53.657C136.453 55.4602 138.939 56.3618 142.122 56.3618C145.306 56.3618 147.792 55.4602 149.58 53.657Z"
        fill="#273852"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.338 61.0233C195.983 61.9824 193.214 62.462 190.03 62.462H170.668V21.3145H189.638C192.516 21.3145 195.023 21.7557 197.16 22.6381C199.341 23.5205 201.042 24.7674 202.263 26.3788C203.484 27.9901 204.094 29.8701 204.094 32.0186C204.094 34.052 203.484 35.8552 202.263 37.4282C201.22 38.7716 199.827 39.8631 198.085 40.7028C198.192 40.7421 198.298 40.7824 198.403 40.8236C200.802 41.706 202.655 42.9912 203.963 44.6794C205.272 46.3291 205.926 48.2858 205.926 50.5493C205.926 52.928 205.25 55.019 203.898 56.8222C202.59 58.6254 200.736 60.0258 198.338 61.0233ZM195.133 46.0605C193.911 45.063 192.232 44.5643 190.096 44.5643H179.499V55.9014H190.096C192.232 55.9014 193.911 55.4026 195.133 54.4051C196.397 53.3693 197.03 51.9497 197.03 50.1465C197.03 48.42 196.397 47.058 195.133 46.0605ZM193.563 29.2562C192.472 28.3354 190.99 27.875 189.114 27.875H179.499V38.1763H189.114C190.99 38.1763 192.472 37.7159 193.563 36.7951C194.653 35.836 195.198 34.5315 195.198 32.8818C195.198 31.3472 194.653 30.1386 193.563 29.2562Z"
        fill="#273852"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.469 23.9617C238.937 22.1969 234.794 21.3145 230.04 21.3145H213.948V62.462H230.04C234.794 62.462 238.937 61.5796 242.469 59.8147C246.045 58.0499 248.814 55.6328 250.777 52.5636C252.783 49.4559 253.786 45.8879 253.786 41.8595C253.786 37.831 252.783 34.2822 250.777 31.2129C248.814 28.1052 246.045 25.6882 242.469 23.9617ZM237.694 54.0598C235.557 55.2108 233.006 55.7863 230.04 55.7863H222.779V27.9901H230.04C233.006 27.9901 235.557 28.5656 237.694 29.7166C239.874 30.8676 241.553 32.479 242.731 34.5507C243.908 36.6225 244.497 39.0587 244.497 41.8595C244.497 44.6602 243.908 47.1156 242.731 49.2257C241.553 51.2975 239.874 52.9089 237.694 54.0598Z"
        fill="#273852"
      />
    </svg>
  );
};
