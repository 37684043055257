export const Okx = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="60"
      height="19"
      viewBox="0 0 60 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2155_7982)">
        <path
          d="M17.5325 0.831909H0.690451C0.586585 0.831909 0.486968 0.873142 0.413553 0.946635C0.340059 1.02005 0.298828 1.11967 0.298828 1.22361V18.0657C0.298828 18.1695 0.340059 18.2691 0.413553 18.3426C0.486968 18.416 0.586585 18.4573 0.690451 18.4573H17.5325C17.6364 18.4573 17.7361 18.416 17.8095 18.3426C17.883 18.2691 17.9242 18.1695 17.9242 18.0657V1.22361C17.9242 1.11967 17.883 1.02005 17.8095 0.946635C17.7361 0.873142 17.6364 0.831909 17.5325 0.831909ZM12.049 12.1905C12.049 12.2944 12.0078 12.394 11.9343 12.4675C11.8609 12.5409 11.7613 12.5822 11.6574 12.5822H6.5656C6.46173 12.5822 6.36212 12.5409 6.28862 12.4675C6.21521 12.394 6.17398 12.2944 6.17398 12.1905V7.09868C6.17398 6.99482 6.21521 6.8952 6.28862 6.82178C6.36212 6.74829 6.46173 6.70706 6.5656 6.70706H11.6574C11.7613 6.70706 11.8609 6.74829 11.9343 6.82178C12.0078 6.8952 12.049 6.99482 12.049 7.09868V12.1905Z"
          fill="#273852"
        />
        <path
          d="M52.794 6.70764H47.7022C47.4859 6.70764 47.3105 6.88295 47.3105 7.09926V12.1911C47.3105 12.4074 47.4859 12.5828 47.7022 12.5828H52.794C53.0103 12.5828 53.1857 12.4074 53.1857 12.1911V7.09926C53.1857 6.88295 53.0103 6.70764 52.794 6.70764Z"
          fill="#273852"
        />
        <path
          d="M46.9151 0.831909H41.8233C41.607 0.831909 41.4316 1.00722 41.4316 1.22361V6.31535C41.4316 6.53166 41.607 6.70705 41.8233 6.70705H46.9151C47.1315 6.70705 47.3068 6.53166 47.3068 6.31535V1.22361C47.3068 1.00722 47.1315 0.831909 46.9151 0.831909Z"
          fill="#273852"
        />
        <path
          d="M58.6652 0.831909H53.5733C53.357 0.831909 53.1816 1.00722 53.1816 1.22361V6.31535C53.1816 6.53166 53.357 6.70705 53.5733 6.70705H58.6652C58.8815 6.70705 59.0568 6.53166 59.0568 6.31535V1.22361C59.0568 1.00722 58.8815 0.831909 58.6652 0.831909Z"
          fill="#273852"
        />
        <path
          d="M46.9151 12.5835H41.8233C41.607 12.5835 41.4316 12.7588 41.4316 12.9751V18.0669C41.4316 18.2833 41.607 18.4586 41.8233 18.4586H46.9151C47.1315 18.4586 47.3068 18.2833 47.3068 18.0669V12.9751C47.3068 12.7588 47.1315 12.5835 46.9151 12.5835Z"
          fill="#273852"
        />
        <path
          d="M58.6652 12.5835H53.5733C53.357 12.5835 53.1816 12.7588 53.1816 12.9751V18.0669C53.1816 18.2833 53.357 18.4586 53.5733 18.4586H58.6652C58.8815 18.4586 59.0568 18.2833 59.0568 18.0669V12.9751C59.0568 12.7588 58.8815 12.5835 58.6652 12.5835Z"
          fill="#273852"
        />
        <path
          d="M38.0948 0.831909H33.003C32.7867 0.831909 32.6113 1.00722 32.6113 1.22361V6.31535C32.6113 6.53166 32.7867 6.70705 33.003 6.70705H38.0948C38.3111 6.70705 38.4865 6.53166 38.4865 6.31535V1.22361C38.4865 1.00722 38.3111 0.831909 38.0948 0.831909Z"
          fill="#273852"
        />
        <path
          d="M38.0948 12.5835H33.003C32.7867 12.5835 32.6113 12.7588 32.6113 12.9751V18.0669C32.6113 18.2833 32.7867 18.4586 33.003 18.4586H38.0948C38.3111 18.4586 38.4865 18.2833 38.4865 18.0669V12.9751C38.4865 12.7588 38.3111 12.5835 38.0948 12.5835Z"
          fill="#273852"
        />
        <path
          d="M32.6136 7.09427C32.6136 6.99041 32.5723 6.89079 32.4989 6.81738C32.4254 6.74388 32.3257 6.70265 32.2219 6.70265H26.7384V1.22361C26.7384 1.11967 26.6971 1.02005 26.6237 0.946635C26.5502 0.873142 26.4506 0.831909 26.3467 0.831909H21.255C21.151 0.831909 21.0514 0.873142 20.978 0.946635C20.9045 1.02005 20.8633 1.11967 20.8633 1.22361V18.0568C20.8633 18.1607 20.9045 18.2603 20.978 18.3337C21.0514 18.4072 21.151 18.4485 21.255 18.4485H26.3467C26.4506 18.4485 26.5502 18.4072 26.6237 18.3337C26.6971 18.2603 26.7384 18.1607 26.7384 18.0568V12.5778H32.2219C32.3257 12.5778 32.4254 12.5365 32.4989 12.4631C32.5723 12.3896 32.6136 12.29 32.6136 12.1861V7.09427Z"
          fill="#273852"
        />
      </g>
      <defs>
        <clipPath id="clip0_2155_7982">
          <rect width="58.7003" height="17.6258" fill="white" transform="translate(0.324219 0.831665)" />
        </clipPath>
      </defs>
    </svg>
  );
};
