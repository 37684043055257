import { Box } from "@mui/material";

import s from "../InfoHome.module.css";

export const platform = [
  {
    id: 1,
    name: "01",
    description: <>Connect your social media accounts here after sign-up with your special invite code</>,
    info: (
      <Box display="flex">
        <Box mr={1} fontWeight="600">
          1000
        </Box>{" "}
        airdrop points
      </Box>
    ),
  },
  {
    id: 2,
    name: "02",
    description: <>Buy as much UBDN tokens as you can</>,
    info: (
      <>
        15 airdrop points for every <br className={s.purchased} /> 1 UBDN purchased
      </>
    ),
  },
  {
    id: 3,
    name: "03",
    description: (
      <>
        Invite as much friends as you can and enforce them to buy UBDN to receive leaderboard points and percentage of
        referral rewards
      </>
    ),
    info: (
      <>
        <Box>
          <Box component="span" fontWeight="700">
            10
          </Box>{" "}
          airdrop points for every invited friend
        </Box>
        <Box>
          <Box component="span" fontWeight="700">
            8
          </Box>{" "}
          airdrop points for every $1 from your friend
        </Box>
        <Box>
          <Box component="span" fontWeight="700">
            4
          </Box>{" "}
          airdrop points for every $1 from friend's friend
        </Box>
      </>
    ),
  },
  {
    id: 4,
    name: "04",
    description: <>Spin every 24 hours for every $1000 attracted and get extra points and rewards in UBD</>,
    info: (
      <Box fontSize="16px" component="div" textAlign="left">
        <Box component="div" fontWeight="600">
          A legendary way to earn airdrop points
        </Box>{" "}
        and native stablecoins. The drops are massive and the rewards multiply incredibly. Do not miss!
      </Box>
    ),
  },
  {
    id: 5,
    name: "05",
    description: (
      <>
        Be active member of the community. Discuss, make creatives, post, share, retweet, participate quests. We will
        reward the best performers also even without a large amount of raised funds
      </>
    ),
    info: (
      <Box component="div" textAlign="left">
        <Box component="span" fontWeight="600" mr={1}>
          100 000 000
        </Box>
        airdrop points for quest and social <br className={s.break} />
        media performers
      </Box>
    ),
  },
];

