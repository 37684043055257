import { Box, Stack, Typography } from "@mui/material";
import { ArrowLineMobile } from "../../icons/ArrowLine";
import { MobileBall } from "../../icons/Ball";
import { CheckMoble } from "../../icons/Check";
import { MobileLine } from "../../icons/Line";

const wallets = [
  {
    id: 1,
    name: "Bitcoin core and first “cold” wallets",
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 2,
    name: "Paper wallets and “brain” wallets",
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 3,
    name: "Exchanges and custodial services",
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 4,
    name: (
      <>
        Web wallets <br /> & plug-ins
      </>
    ),
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 5,
    name: "Mobile wallets",
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 6,
    name: "Hardware wallets",
    check: <CheckMoble />,
    line: <MobileLine />,
  },
  {
    id: 7,
    name: (
      <>
        DeFi & NFT <br /> integrated wallets
      </>
    ),
    check: <CheckMoble />,
    line: <ArrowLineMobile />,
  },
  {
    id: 8,
    name: "DeTrust Multi-sig revolution!",
    check: <MobileBall />,
  },
];

export const WalletsFlex = () => {
  return (
    <Box>
      <Typography mt="30px" mb="40px" textAlign="center" color="#F1F1F3" variant="h3">
        Crypto wallets evolution
      </Typography>
      <Stack
        width="min(500px, 100%)"
        sx={{ margin: "0 auto" }}
        justifyContent="center"
        alignItems="baseline"
        flexDirection={"row"}
        flexWrap="wrap"
      >
        {wallets.map((wallet) => (
          <Box sx={{ width: "min(254px, 100%)", textAlign: "center" }} key={wallet.id} mb="20px">
            <Stack
              borderRadius="20px"
              sx={{
                backgroundColor: wallet.id === 8 ? "transparent" : "#141822",
                padding: wallet.id === 8 ? "0 20px 20px" : "20px",
              }}
              flexDirection={wallet.id === 8 ? "column" : "row"}
              alignItems="center"
              textAlign="center"
              justifyContent={wallet.id === 5 || wallet.id === 6 ? "flex-start" : "space-between"}
            >
              <Box sx={{ margin: wallet.id === 8 ? "0px" : "" }} display="flex" justifyContent="center">
                {wallet.check}
              </Box>
              <Box
                ml={wallet.id === 5 || wallet.id === 6 ? "50px" : wallet.id === 8 ? "0" : "20px"}
                fontSize="14px"
                color="#F1F1F3"
                mt={wallet.id === 8 ? "20px" : ""}
                component="p"
                sx={{ margin: wallet.id === 4 || wallet.id === 7 ? "0 auto" : null }}
              >
                {wallet.name}
              </Box>
            </Stack>
            <Box mt="16px" display={"flex"} justifyContent="center">
              {wallet?.line}
            </Box>
            {wallet.id === 7 && (
              <Box fontSize="20px" color="#fff" mt="11px">
                We are here now!
              </Box>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
