import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { useDataLayer } from "~/hooks/useDataLayer";
import { instance } from "~/shared/lib";

export const useSignLogin = () => {
  const { signMessageAsync, error } = useSignMessage();
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { refetchAuth } = useWallet();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect({});

  const errorFallback = (message: string = "", info: any = {}) => {
    enqueueSnackbar(message, info);
    disconnect();
  };

  const signLogin = async () => {
    setLoading(true);
    const signData = await signMessageAsync({ message: 'LOGIN_REQUEST' });
    if (!signData) return errorFallback("Failed to sign message", { variant: "error" });
    const { setDataLayer } = useDataLayer();

    const response = await instance.post("/auth/login", {
      address: address!.toLowerCase(),
      signature: signData,
    });
    if (response.data.ok == true) {
      localStorage.setItem(
        "jwt",
        JSON.stringify({
          address: address!.toLowerCase(),
          token: response.data.details,
        }),
      );
      setDataLayer("signIn");
    } else errorFallback(response.data.details, { variant: "error" });

    await refetchAuth();
    setLoading(false);
    if (response.data.ok) navigate("/dashboard");
  };

  useEffect(() => {
    if (error) {
      errorFallback(error.name, { variant: "error" });
      console.error(error);
      setLoading(false);
    }
  }, [error]);

  return { signLogin, loading };
};
