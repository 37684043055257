import { styled, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useAccount } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { DEFAULT_CUSTOM_TOKENS, getSwapTokensUbdn } from "~/utils/web3-constants";
import { resolveCustomToken } from "~/utils/web3-utils";
import { FieldStatus } from "../hooks/useFieldsHelper";
import { BaseSwapperFormikValues, HandleFieldChange } from "../types";
import SwapperCustomTokenBalance from "./SwapperCustomTokenBalance";
import SwapperInputAdornment, { SwapperIACoinInfo } from "./SwapperInputAdornment";

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => prop !== "disableTextAlign",
})<{
  disableTextAlign?: boolean;
}>`
  ${(props) =>
    !props.disableTextAlign &&
    `
  input {
    text-align: right;
  }
  `}

  & .MuiInputBase-root {
    height: 72px;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    right: 0;
    bottom: 4px;
  }
`;

interface SwapperTextFieldProps {
  label: string;
  style?: React.CSSProperties;
  name: string;
  tokenVariants: SwapperIACoinInfo[];
  tokenField: string;
  disabled: boolean;
  fieldStatus: FieldStatus;
  handleFieldChange?: HandleFieldChange;
  balance?: string;
}

const SwapperTextField: React.FC<SwapperTextFieldProps> = ({
  tokenField,
  tokenVariants,
  disabled,
  fieldStatus,
  handleFieldChange,
  label,
  name,
  style,
  balance,
}: SwapperTextFieldProps) => {
  const { values, handleChange } = useFormikContext<BaseSwapperFormikValues>();
  const { balancesMap } = useWallet();
  const token = values[tokenField];
  const tokenInVariants = tokenVariants.find((item) => item.name === token);
  const { chain } = useAccount();

  const resolveBalance = () => {
    if (disabled) return "";
    if (balance) return `Balance: ${balance}`;

    let customToken = resolveCustomToken(chain?.id ?? 0, token) || tokenInVariants?.mapName;
    const ubdnCustomToken = getSwapTokensUbdn(chain?.id)[token];
    if (!customToken && !ubdnCustomToken)
      customToken = DEFAULT_CUSTOM_TOKENS.find((item) => item.symbol == token && item.chainId == chain?.id);

    if (customToken || ubdnCustomToken)
      return (
        <SwapperCustomTokenBalance
          token={
            customToken ||
            ({
              address: ubdnCustomToken,
              chainId: chain?.id,
              symbol: token,
            } as any)
          }
        />
      );

    return `Balance: ${balancesMap[token] ? balancesMap[token] : "0"} ${token}`;
  };

  return (
    <StyledTextField
      disabled={disabled || fieldStatus === "loading"}
      onChange={(event) => {
        const { value } = event.target;
        if (value.length > 0) {
          const regex = /^[0-9]*\.?[0-9]*$/;
          if (!regex.test(value)) return;
        }
        if (Number(value) > 999999999) return;

        handleChange({ target: { name, value } });
        handleFieldChange?.(name, event);
      }}
      inputProps={{
        pattern: `[0-9]*`,
        inputMode: "numeric",
      }}
      value={values[name]}
      label={label}
      variant="outlined"
      type="string"
      fullWidth
      style={style}
      InputProps={{
        startAdornment: <SwapperInputAdornment disabled={disabled} variants={tokenVariants} name={tokenField} />,
      }}
      helperText={resolveBalance()}
    />
  );
};

export default SwapperTextField;
