import { IconProps } from "./types";

export const SpinsIcon = ({ style }: IconProps) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33302 3.5391C12.7864 1.2316 16.8466 0 21 0C26.5675 0.0065 31.9052 2.2211 35.842 6.158C39.7789 10.0948 41.9934 15.4325 42 21C42 25.1534 40.7684 29.2135 38.4608 32.667C36.1533 36.1204 32.8736 38.812 29.0363 40.4015C25.1991 41.9909 20.9767 42.4068 16.9031 41.5965C12.8295 40.7862 9.08772 38.7861 6.15072 35.8492C3.21382 32.9123 1.21382 29.1705 0.403519 25.0969C-0.406781 21.0233 0.00911999 16.8009 1.59852 12.9636C3.18802 9.1264 5.87962 5.8466 9.33302 3.5391ZM21 40C31.4934 40 40 31.4934 40 21C40 10.5066 31.4934 2 21 2C10.5066 2 2.00002 10.5066 2.00002 21C2.00002 31.4934 10.5066 40 21 40Z"
        fill="currentColor"
      />
      <path
        d="M21 37.5C16.6239 37.5 12.4271 35.7616 9.3327 32.6673C6.2384 29.5729 4.5 25.3761 4.5 21C4.5 16.6239 6.2384 12.4271 9.3327 9.3327C12.4271 6.2384 16.6239 4.5 21 4.5C25.3761 4.5 29.5729 6.2384 32.6673 9.3327C35.7616 12.4271 37.5 16.6239 37.5 21C37.5 25.3761 35.7616 29.5729 32.6673 32.6673C29.5729 35.7616 25.3761 37.5 21 37.5ZM21 39C23.3638 39 25.7044 38.5344 27.8883 37.6298C30.0722 36.7252 32.0565 35.3994 33.7279 33.7279C35.3994 32.0565 36.7253 30.0722 37.6298 27.8883C38.5344 25.7044 39 23.3638 39 21C39 18.6362 38.5344 16.2956 37.6298 14.1117C36.7253 11.9278 35.3994 9.9435 33.7279 8.2721C32.0565 6.6006 30.0722 5.2748 27.8883 4.3702C25.7044 3.4656 23.3638 3 21 3C16.2261 3 11.6477 4.8964 8.2721 8.2721C4.8964 11.6477 3 16.2261 3 21C3 25.7739 4.8964 30.3523 8.2721 33.7279C11.6477 37.1036 16.2261 39 21 39Z"
        fill="currentColor"
      />
      <path
        d="M31.2718 18.4063V11.5966L28.8025 14.0658C27.8344 13.0935 26.6836 12.3225 25.4162 11.7969C24.1489 11.2713 22.7901 11.0017 21.4181 11.0036C15.6561 11.0036 10.9844 15.6752 10.9844 21.4371C10.9844 27.1992 15.6561 31.8708 21.4181 31.8708C25.2646 31.8708 28.6251 29.7893 30.4345 26.6905L30.4614 26.6409L28.1469 25.3055C26.7818 27.6487 24.2804 29.1989 21.4181 29.1989C20.1413 29.1989 18.8842 28.8838 17.7583 28.2818C16.6323 27.6797 15.6723 26.8091 14.9633 25.7473C14.2543 24.6854 13.8182 23.4652 13.6936 22.1945C13.569 20.9237 13.7599 19.6419 14.2492 18.4625C14.7385 17.2832 15.5112 16.2428 16.4988 15.4336C17.4864 14.6243 18.6583 14.0713 19.9108 13.8233C21.1633 13.5753 22.4576 13.64 23.6791 14.012C24.9005 14.3839 26.0113 15.0512 26.9131 15.9551L24.4612 18.407L31.2718 18.4063Z"
        fill="currentColor"
      />
    </svg>
  );
};
