import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";

export const useLoginFromTg = () => {
  const { open } = useWeb3Modal();
  const { address, chain } = useAccount();
  const { refetchAuth } = useWallet();

  const ready = address && chain;

  const signInTg = async (token: string) => {
    if (ready ) {
      localStorage.setItem(
        "jwt",
        JSON.stringify({
          address: address!.toLowerCase(),
          token,
        }),
      );
      await refetchAuth();
      window.location.replace("/dashboard");
    } else {
      open();
    }
  };

  useEffect(() => {}, []);

  return { signInTg };
};

