import { IconProps } from "@mui/material";

export const UbdIcon = ({ style }: IconProps) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M21 39.375C16.1266 39.375 11.4529 37.4391 8.00691 33.9931C4.56093 30.5471 2.625 25.8734 2.625 21C2.625 16.1266 4.56093 11.4529 8.00691 8.00691C11.4529 4.56093 16.1266 2.625 21 2.625C25.8734 2.625 30.5471 4.56093 33.9931 8.00691C37.4391 11.4529 39.375 16.1266 39.375 21C39.375 25.8734 37.4391 30.5471 33.9931 33.9931C30.5471 37.4391 25.8734 39.375 21 39.375ZM21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42Z"
      fill="currentColor"
    />
    <path
      d="M20.8828 35.3208C17.0537 35.3208 13.3815 33.7997 10.674 31.0922C7.9664 28.3846 6.44531 24.7124 6.44531 20.8833C6.44531 17.0542 7.9664 13.382 10.674 10.6744C13.3815 7.96689 17.0537 6.4458 20.8828 6.4458C24.7119 6.4458 28.3841 7.96689 31.0917 10.6744C33.7992 13.382 35.3203 17.0542 35.3203 20.8833C35.3203 24.7124 33.7992 28.3846 31.0917 31.0922C28.3841 33.7997 24.7119 35.3208 20.8828 35.3208ZM20.8828 36.6333C22.9511 36.6333 24.9992 36.2259 26.9101 35.4344C28.821 34.6429 30.5572 33.4828 32.0197 32.0202C33.4823 30.5577 34.6424 28.8214 35.4339 26.9106C36.2254 24.9997 36.6328 22.9516 36.6328 20.8833C36.6328 18.815 36.2254 16.7669 35.4339 14.856C34.6424 12.9452 33.4823 11.2089 32.0197 9.74637C30.5572 8.28385 28.821 7.12371 26.9101 6.3322C24.9992 5.54069 22.9511 5.1333 20.8828 5.1333C16.7057 5.1333 12.6996 6.79267 9.74588 9.74637C6.79218 12.7001 5.13281 16.7061 5.13281 20.8833C5.13281 25.0605 6.79218 29.0665 9.74588 32.0202C12.6996 34.9739 16.7057 36.6333 20.8828 36.6333Z"
      fill="currentColor"
    />
    <path
      d="M19.8957 10.1038C19.8957 10.2111 19.8087 10.2981 19.7013 10.2981H17.286C17.1528 10.2981 17.0255 10.3528 16.9337 10.4494L14.1336 13.3969C14.0478 13.4872 14 13.607 14 13.7315V20.1634C14 20.2875 14.0475 20.407 14.1329 20.4972L14.4468 20.8292L16.4295 18.7209V14.7034C16.4295 14.5792 16.4771 14.4597 16.5624 14.3695L17.9713 12.8797C18.063 12.7827 18.1907 12.7277 18.3243 12.7277H23.9363C24.0699 12.7277 24.1975 12.7827 24.2893 12.8797L25.6585 14.3275C25.7438 14.4178 25.7914 14.5372 25.7914 14.6614V15.222C25.7914 15.3294 25.8784 15.4164 25.9857 15.4164H26.8377C26.9468 15.4164 27.0528 15.3796 27.1385 15.3121L28.1469 14.5171C28.1936 14.4803 28.2209 14.424 28.2209 14.3645V13.6946C28.2209 13.5704 28.1734 13.4509 28.088 13.3607L25.3356 10.4502C25.2438 10.3531 25.1161 10.2981 24.9826 10.2981H22.5196C22.4122 10.2981 22.3252 10.2111 22.3252 10.1038V5.85944C22.3252 5.59108 22.1077 5.37354 21.8393 5.37354H20.3816C20.1132 5.37354 19.8957 5.59108 19.8957 5.85944V10.1038Z"
      fill="currentColor"
    />
    <path
      d="M16.2938 23.0617C16.2938 22.9815 16.3093 22.9029 16.3387 22.8298C16.3676 22.7581 16.4098 22.6918 16.4638 22.6346L17.8122 21.2089C17.8726 21.145 17.9451 21.0953 18.0247 21.0622C18.0997 21.0309 18.1808 21.0144 18.2638 21.0144H23.9759C24.0733 21.0144 24.1663 20.9738 24.2326 20.9024L25.5363 19.5478C25.5987 19.4855 25.6273 19.4456 25.6091 19.3829C25.5909 19.3202 25.1888 18.9355 25.1888 18.9355C25.1413 18.8849 25.0749 18.8562 25.0055 18.8562H17.2596C17.1642 18.8562 17.0729 18.8951 17.0069 18.9639L14.2332 21.8544C14.1706 21.9196 14.1357 22.0065 14.1357 22.0969V23.1493C14.1357 23.1657 14.1426 23.1814 14.1547 23.1925L15.2508 24.198C15.2616 24.2079 15.2757 24.2134 15.2904 24.2134H16.2352C16.2676 24.2134 16.2938 24.1872 16.2938 24.1548V23.0617Z"
      fill="currentColor"
    />
    <path
      d="M16.6768 23.2008L16.8913 23.4276C16.9831 23.5246 17.1107 23.5796 17.2443 23.5796H24.9485C25.0809 23.5796 25.2075 23.5257 25.2991 23.4302L28.0728 20.5397C28.1596 20.4492 28.2081 20.3286 28.2081 20.2032V19.6827C28.2081 19.5334 28.1395 19.3924 28.022 19.3003L27.0461 18.5352C27.0119 18.5084 26.9697 18.4938 26.9262 18.4938H26.2278C26.1 18.5199 26.0038 18.633 26.0038 18.7686V19.4401C26.0038 19.6095 25.9381 19.7723 25.8206 19.8943L24.4186 21.3492C24.3187 21.4528 24.181 21.5114 24.0371 21.5114H18.2789L16.6768 23.2008Z"
      fill="currentColor"
    />
    <path
      d="M25.7914 23.7475V27.5865C25.7914 27.7107 25.7438 27.8301 25.6585 27.9204L24.2497 29.4101C24.1579 29.5072 24.0302 29.5622 23.8966 29.5622H18.2846C18.1511 29.5622 18.0234 29.5072 17.9316 29.4101L16.487 27.9774C16.4502 27.9409 16.4295 27.8913 16.4295 27.8394V27.0678C16.4295 26.9605 16.3425 26.8735 16.2352 26.8735H15.3001C15.2575 26.8735 15.2161 26.8875 15.1822 26.9133L14.0765 27.757C14.0283 27.7938 14 27.8509 14 27.9115V28.5953C14 28.7195 14.0475 28.8389 14.1329 28.9292L16.8853 31.8397C16.9771 31.9367 17.1048 31.9917 17.2383 31.9917H19.7013C19.8087 31.9917 19.8957 32.0787 19.8957 32.1861V36.1792C19.8957 36.2865 19.9827 36.3735 20.0901 36.3735H22.1309C22.2382 36.3735 22.3252 36.2865 22.3252 36.1792V32.1861C22.3252 32.0787 22.4123 31.9917 22.5196 31.9917H24.9349C25.0681 31.9917 25.1954 31.937 25.2872 31.8405L28.0873 28.893C28.1731 28.8027 28.2209 28.6829 28.2209 28.5583V22.1265C28.2209 22.0023 28.1734 21.8828 28.0881 21.7926L27.8526 21.607L25.7914 23.7475Z"
      fill="currentColor"
    />
  </svg>
);
