export const Line = () => {
  return (
    <svg
      style={{ width: "min(100%, 250px)" }}
      width="250"
      height="3"
      viewBox="0 0 250 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.5"
        y1="1.5"
        x2="248.5"
        y2="1.5"
        stroke="url(#paint0_linear_2568_7004)"
        stroke-width="3"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2568_7004"
          x1="48.913"
          y1="3.36"
          x2="52.5858"
          y2="33.2263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#45D3D1" />
          <stop offset="1" stop-color="#23D98F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MobileLine = () => {
  return (
    <svg
      style={{ transform: "rotate(180deg)" }}
      width="3"
      height="20"
      viewBox="0 0 3 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1.5"
        y1="18.5"
        x2="1.5"
        y2="1.5"
        stroke="url(#paint0_linear_2601_8989)"
        stroke-width="3"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2601_8989"
          x1="3.36"
          y1="16.087"
          x2="12.3755"
          y2="2.22867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#45D3D1" />
          <stop offset="1" stop-color="#23D98F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
