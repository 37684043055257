/* eslint-disable react/destructuring-assignment */
import { SvgIcon, SvgIconProps } from "@mui/material";
import { CSSProperties } from "react";
import Iconify from "~/components/iconify";

export function UbdnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} style={{ width: "20px", height: "20px", ...(props.style ?? {}) }} viewBox="0 0 16 16">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2035_6232)">
          <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="#D9D9D9" />
          <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="#18D47A" />
          <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 20 0)" fill="url(#paint0_linear_2035_6232)" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4359 17.9824L11.1533 11.5098L14.1719 9.08816L15.2725 10.3411L12.6704 12.5852L10.4359 17.9824Z" fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84468 11.5098L9.56201 17.9824L7.29627 12.5852L4.72549 10.3411L5.82609 9.08816L8.84468 11.5098Z" fill="white" />
          <path d="M7.12927 15.5595L4.72549 10.3411L7.29627 12.5852L9.56201 17.9824L7.12927 15.5595Z" fill="url(#paint1_linear_2035_6232)" />
          <path d="M12.8687 15.5595L10.4359 17.9824L12.6704 12.5852L15.2725 10.3411L12.8687 15.5595Z" fill="url(#paint2_linear_2035_6232)" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 2.04102L14.9976 7.25548L9.9976 10.3411L10.0003 2.04102Z" fill="white" />
          <path d="M5.00031 7.25548L10.0003 2.04102L9.9976 10.3411L5.00031 7.25548Z" fill="url(#paint3_linear_2035_6232)" />
          <path d="M5.00031 7.25548L10.0003 2.04102M5.00031 7.25548L9.9976 10.3411M5.00031 7.25548V8.4327L5.82609 9.08816M10.0003 2.04102L14.9976 7.25548M10.0003 2.04102L9.9976 10.3411M9.9976 10.3411L14.9976 7.25548M14.9976 7.25548V8.44971L14.1719 9.08816M10.4359 17.9824L12.8687 15.5595L15.2725 10.3411M10.4359 17.9824L11.1533 11.5098L14.1719 9.08816M10.4359 17.9824L12.6704 12.5852L15.2725 10.3411M10.4359 17.9824H9.56201M15.2725 10.3411L14.1719 9.08816M9.56201 17.9824L8.84468 11.5098L5.82609 9.08816M9.56201 17.9824L7.12927 15.5595L4.72549 10.3411M9.56201 17.9824L7.29627 12.5852L4.72549 10.3411M5.82609 9.08816L4.72549 10.3411" stroke="white" stroke-width="0.170068" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_2035_6232" x1="4.04297" y1="2.92969" x2="16.4844" y2="17.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#18D47A" />
            <stop offset="1" stop-color="#00A28E" />
          </linearGradient>
          <linearGradient id="paint1_linear_2035_6232" x1="4.72824" y1="18.4837" x2="4.98335" y2="11.2218" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
          <linearGradient id="paint2_linear_2035_6232" x1="13.6568" y1="11.579" x2="10.3065" y2="19.9123" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <linearGradient id="paint3_linear_2035_6232" x1="9.99897" y1="2.04102" x2="5.10239" y2="10.9497" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <clipPath id="clip0_2035_6232">
            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
          </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  );
}

export function UbdIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} style={{ width: "20px", height: "20px", ...(props.style ?? {}) }} viewBox="0 0 16 16">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2678_4070)">
          <circle
            cx="20"
            cy="20"
            r="19.0476"
            fill="url(#paint0_linear_2678_4070)"
            stroke="white"
            stroke-width="1.90476"
          />
          <path
            d="M18.477 6.13352C18.477 6.26805 18.3679 6.37711 18.2334 6.37711H15.2063C15.0393 6.37711 14.8797 6.44564 14.7648 6.56666L11.2554 10.2608C11.1478 10.3739 11.0879 10.5241 11.0879 10.6802V18.7412C11.0879 18.8969 11.1475 19.0466 11.2544 19.1597L11.6478 19.5757L14.1328 16.9334V11.8982C14.1328 11.7425 14.1924 11.5928 14.2994 11.4797L16.0651 9.61262C16.1801 9.49098 16.3401 9.42207 16.5076 9.42207H23.5411C23.7085 9.42207 23.8685 9.49098 23.9835 9.61262L25.6995 11.4272C25.8065 11.5402 25.8661 11.69 25.8661 11.8456V12.5482C25.8661 12.6828 25.9751 12.7918 26.1097 12.7918H27.1774C27.3142 12.7918 27.447 12.7458 27.5544 12.6611L28.8182 11.6648C28.8768 11.6186 28.911 11.5481 28.911 11.4735V10.6338C28.911 10.4782 28.8514 10.3285 28.7445 10.2154L25.2949 6.56766C25.1798 6.44603 25.0198 6.37711 24.8524 6.37711H21.7655C21.631 6.37711 21.5219 6.26805 21.5219 6.13352V0.814069C21.5219 0.477733 21.2493 0.205078 20.9129 0.205078H19.086C18.7496 0.205078 18.477 0.477733 18.477 0.814069V6.13352Z"
            fill="white"
          />
          <path
            d="M13.9628 22.3737C13.9628 22.2732 13.9822 22.1746 14.019 22.083C14.0552 21.9932 14.1081 21.9101 14.1758 21.8385L15.8657 20.0516C15.9414 19.9715 16.0323 19.9093 16.132 19.8677C16.226 19.8286 16.3277 19.8078 16.4317 19.8078H23.5907C23.7128 19.8078 23.8294 19.7569 23.9124 19.6674L25.5464 17.9697C25.6246 17.8916 25.6604 17.8416 25.6376 17.7631C25.6148 17.6845 25.1109 17.2024 25.1109 17.2024C25.0513 17.1389 24.9682 17.103 24.8811 17.103H15.1731C15.0536 17.103 14.9392 17.1517 14.8564 17.238L11.3802 20.8606C11.3017 20.9424 11.2579 21.0513 11.2579 21.1645V22.4835C11.2579 22.5041 11.2666 22.5237 11.2818 22.5377L12.6555 23.7979C12.669 23.8103 12.6868 23.8172 12.7052 23.8172H13.8892C13.9299 23.8172 13.9628 23.7843 13.9628 23.7437V22.3737Z"
            fill="white"
          />
          <path
            d="M14.4427 22.5481L14.7115 22.8323C14.8266 22.9539 14.9866 23.0229 15.154 23.0229H24.8097C24.9756 23.0229 25.1343 22.9552 25.2491 22.8355L28.7254 19.2129C28.8342 19.0995 28.895 18.9484 28.895 18.7912V18.1388C28.895 17.9517 28.809 17.775 28.6617 17.6596L27.4387 16.7007C27.3958 16.667 27.3429 16.6488 27.2884 16.6488H26.413C26.2528 16.6815 26.1323 16.8233 26.1323 16.9932V17.8348C26.1323 18.0471 26.05 18.2511 25.9027 18.404L24.1456 20.2274C24.0204 20.3573 23.8478 20.4307 23.6674 20.4307H16.4506L14.4427 22.5481Z"
            fill="white"
          />
          <path
            d="M25.8661 23.2333V28.0447C25.8661 28.2003 25.8065 28.35 25.6995 28.4631L23.9338 30.3302C23.8188 30.4519 23.6588 30.5208 23.4914 30.5208H16.4578C16.2904 30.5208 16.1304 30.4519 16.0154 30.3302L14.2049 28.5346C14.1588 28.4889 14.1328 28.4266 14.1328 28.3617V27.3946C14.1328 27.2601 14.0238 27.151 13.8892 27.151H12.7173C12.6639 27.151 12.612 27.1686 12.5695 27.201L11.1837 28.2584C11.1233 28.3045 11.0879 28.3761 11.0879 28.4521V29.309C11.0879 29.4646 11.1475 29.6144 11.2544 29.7274L14.7041 33.3752C14.8191 33.4968 14.9791 33.5657 15.1465 33.5657H18.2334C18.3679 33.5657 18.477 33.6748 18.477 33.8093V38.8139C18.477 38.9484 18.586 39.0575 18.7206 39.0575H21.2783C21.4129 39.0575 21.5219 38.9484 21.5219 38.8139V33.8093C21.5219 33.6748 21.631 33.5657 21.7655 33.5657H24.7926C24.9596 33.5657 25.1192 33.4972 25.2342 33.3762L28.7435 29.6821C28.8511 29.5689 28.911 29.4188 28.911 29.2626V21.2016C28.911 21.046 28.8514 20.8963 28.7445 20.7832L28.4494 20.5506L25.8661 23.2333Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2678_4070"
            x1="8.08594"
            y1="5.85938"
            x2="32.9688"
            y2="35"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#18D47A" />
            <stop offset="1" stop-color="#00A28E" />
          </linearGradient>
          <clipPath id="clip0_2678_4070">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export const tokenIconMap: Record<string, string> = {
  USDT: "cryptocurrency-color:usdt",
  USDC: "cryptocurrency-color:usdc",
  DAI: "cryptocurrency-color:dai",
  ETH: "cryptocurrency-color:eth",
  // UBDN: <UbdnIcon />,
  // UBD: <UbdnIcon />,
};

export const CoinIcon = (props: { symbol: string; style?: CSSProperties }) => {
  if (tokenIconMap[props.symbol]) {
    return <Iconify icon={tokenIconMap[props.symbol]} style={props.style} />;
  }

  if (props.symbol === "UBDN") return <UbdnIcon style={props.style} />;
  if (props.symbol === "UBD") return <UbdIcon style={props.style} />;

  return <Iconify icon="solar:dollar-bold" style={props.style} />;
};
