import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useEffect, useLayoutEffect, useRef } from "react";

import { elements } from "../elements";
import { Item } from "../Item";

import { IAnimationSpin } from "../interface";
import sItem from "../Item/item.module.css";
import s from "../roulette.module.css";

// timline самого спина
const timeline = gsap.timeline({ paused: true });
// timline для выигрышного спина
const timelineS = gsap.timeline({ paused: true });

export const AnimationSpin = ({ result, loading }: IAnimationSpin) => {
  const refWrapper = useRef<any>(null);
  const refElem = useRef<any>(null);

  const tl = useRef(timeline);
  const tlS = useRef(timelineS);

  useGSAP(() => {
    gsap.set(`.${sItem.item}`, {
      x: (i, el) => {
        const width = Math.floor(el.getBoundingClientRect().width) + 15;
        const offset = (window.innerWidth % width) / 2;
        return i * width + offset;
      },
    });
  });

  useLayoutEffect(() => {
    const elemItem: HTMLDivElement | any = document.querySelector(`.${sItem.item}`);
    // width самого элемента
    const elemWidth = Math.floor(elemItem?.getBoundingClientRect().width) + 15;

    // width рулетки
    const width = (Math.floor(elemItem.getBoundingClientRect().width) + 15) * elements.length;

    // координат x выигрышного спина
    const elemActive: HTMLDivElement | any = document.querySelector(`.${s.active}`);
    const rect = elemActive?.getBoundingClientRect();
    const x: any = Math.floor(rect?.left);

    const wrap = Math.floor(refWrapper.current.getBoundingClientRect().width);
    const elementsWrap = Math.floor(wrap / elemWidth);
    // сколько надо пройти рулетке, чтобы выигрышный спин был по центру
    let roulette: number = width + wrap / 2 + (width - x);

    // небольшая погрешность, чтобы рулетка не смещалась на другой спин
    //roulette += 70;
    if (elementsWrap <= 5) {
      //roulette -= Math.floor(Math.random() * 5);
      roulette += Math.floor(Math.random() * 150);
    } else if (elementsWrap > 5 && elementsWrap < 8) {
      roulette += Math.floor(Math.random() * 15);
    } else if (elementsWrap >= 8 && elementsWrap < 10) {
      roulette += Math.floor(Math.random() * 100);
    } else {
      roulette += elemWidth + Math.floor(Math.random() * 100);
    }

    // анимация прокрутки рулетки
    const ctx = gsap.context(() => {
      tl.current.to(`.${sItem.item}`, {
        x: `+=${roulette}`,
        modifiers: {
          x: gsap.utils.unitize((x: any) => parseFloat(x) % width),
        },
        repeat: 0,
        onComplete: () => {
          // анимация у выигрышного спина при остановке
          tlS.current.fromTo(`.${s.active}`, { animationName: "changeBackground" }, { animationName: "none" }, 4);
        },
      });
    });

    return () => ctx.revert();
  });

  useEffect(() => {
    if (result && !loading) {
      // setIsEndAnim(false)
      timelineS.play();
      // обнуление перед прокруткой
      timeline.duration(6).restart();
    }
  }, [result, loading]);

  return (
    <div className={s.roulette}>
      <div className={s.triangle} />
      <div className={s.wrapper}>
        <div ref={refWrapper} className={s.flex}>
          {elements.map((item: any) => (
            <Item refElem={refElem} className={item.id === result ? s.active : ""} key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
