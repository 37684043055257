import { useAccount, useBalance } from "wagmi";
import { getTokens } from "../web3-constants";

export const useBalances = () => {
  const { chain } = useAccount();
  const { address } = useAccount();
  const usdtBalance = useBalance({
    address,
    token: getTokens(chain?.id || 0)?.USDT,
    chainId: chain?.id,
  });
  const ubdnBalance = useBalance({
    address,
    token: getTokens(chain?.id || 0)?.UBDN,
    chainId: chain?.id,
  });
  const usdcBalance = useBalance({
    address,
    token: getTokens(chain?.id || 0)?.USDC,
    chainId: chain?.id,
  });
  const daiBalance = useBalance({
    address,
    token: getTokens(chain?.id || 0)?.DAI,
    chainId: chain?.id,
  });
  const ubdBalance = useBalance({
    address,
    token: getTokens(chain?.id || 0)?.UBD,
    chainId: chain?.id,
  });

  return { usdtBalance, ubdnBalance, usdcBalance, daiBalance, ubdBalance };
};
