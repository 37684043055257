import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";
import { rouletteElements } from "./Roulette.elements";

interface RouletteProviderContextType {
  isFinished: boolean;
  setIsFinished: (isFinished: boolean) => void;
  prizeId: number;
  setPrizeId: (prizeId: number) => void;
  start: boolean;
  handleStart: () => void;
  handlePrizeDefined: () => void;
  spinning: boolean;
  loading: boolean;
  modalOpen: boolean;
  handleModalClose: () => void;
  spinStats: SpinStats;
}

interface SpinData {
  isError: boolean;
  text?: string;
  details: {
    _id: string;
  };

  result: string;
  isActivated: boolean;
}

interface SpinStats {
  ubd: number;
  points: number;
}

// Create a context for the RouletteProvider
const RouletteContext = createContext<RouletteProviderContextType>({
  isFinished: false,
  setIsFinished: () => {},
  prizeId: 0,
  setPrizeId: () => {},
  start: false,
  handleStart: () => {},
  handlePrizeDefined: () => {},
  spinning: false,
  loading: false,
  modalOpen: false,
  handleModalClose: () => {},
  spinStats: {
    ubd: 0,
    points: 0,
  },
});

// Create the RouletteProvider component
const RouletteProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFinished, setIsFinished] = useState(false);
  const [prizeId, setPrizeId] = useState(0);
  const [start, setStart] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const { refetchAuth } = useWallet();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [spinId, setSpinId] = useState<string>("");
  const stats = useQuery({
    queryKey: ["spinStats"],
    queryFn: async () => {
      const response = await instance.get<{ details: SpinStats }>("/roulette/info");
      return response.data.details;
    },
  });

  useEffect(() => {
    stats.refetch();
  }, []);

  const handleSpinFinished = async (response: any) => {

    if (response.message !== "success") {
      enqueueSnackbar("Error", { variant: "error" });
      setStart(false);
      setPrizeId(0);
      setIsFinished(false);
      setLoading(false);
      return;
    }
    const data = JSON.parse(response.result);
    if (response.ok == false) {
      enqueueSnackbar(data as any as string, { variant: "error" });
      setLoading(false);
      return;
    }

    await refetchAuth();
    let result = 0;

    for (const item of rouletteElements) {
      if (data?.multiplier && item.name === "multiplier" && data.multiplier === +item.amount) {
        result = item.id;
      } else if (data?.ubd && item.name === "UBD" && data.ubd === item.amount) {
        result = item.id;
      } else if (data.points && item.name === "points" && data?.points === +item.amount) result = item.id;
    }

    setLoading(false);
    setPrizeId(result);
    setStart(true);
    setSpinning(true);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!spinId) {
        clearInterval(interval);
        return;
      }
      const response = await instance.get<InstanceResponse<SpinData>>(`/roulette/spin/${spinId}`);
      const details = response.data.details;
      if (details?.isActivated) {
        clearInterval(interval);
        handleSpinFinished(details);
      }
    }, 1000);
  }, [spinId]);

  const handleStart = async () => {
    setStart(false);
    setPrizeId(0);
    setIsFinished(false);
    setLoading(true);
    let response;
    try {
      response = (await instance.get<InstanceResponse<SpinData>>("/roulette/spin")).data;
    } catch (e) {}
    console.log("response", response);
    if (response === undefined || response.details.isError) {
      setLoading(false);
      if (response?.details?.text) {
        enqueueSnackbar(response.details.text, { variant: "error" });
      } else {
        enqueueSnackbar("Timeout", { variant: "error" });
      }
      return;
    }
    const spinData = response.details;
    console.log("Spin data", spinData.details._id);
    setSpinId(spinData.details._id);
  };

  const handlePrizeDefined = () => {
    stats.refetch();
    console.log("Prize defined");
    setIsFinished(true);
    setSpinning(false);
    setModalOpen(true);
  };

  return (
    <RouletteContext.Provider
      value={{
        start,
        handleStart,
        handlePrizeDefined,
        isFinished,
        setIsFinished,
        prizeId,
        setPrizeId,
        spinning,
        loading,
        modalOpen,
        handleModalClose: () => setModalOpen(false),
        spinStats: stats.data || {
          ubd: 0,
          points: 0,
        },
      }}
    >
      {children}
    </RouletteContext.Provider>
  );
};

// Create a custom hook to access the RouletteContext values
const useRoulette = () => {
  const context = useContext(RouletteContext);
  if (!context) {
    throw new Error("useRoulette must be used within a RouletteProvider");
  }
  return context;
};

export { RouletteProvider, useRoulette };
