import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import useLocalStorage from "~/hooks/useLocalStorage";
import localStorageAvailable from "~/utils/localStorageAvailable";
import { defaultSettings } from "./config-setting";
import { defaultPreset, getPresets, presetsOption } from "./presets";
import { SettingsContextProps } from "./types";

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => {},
  onChangeMode: () => {},
  changeTheme: () => {},
  onToggleDirection: () => {},
  onChangeDirection: () => {},
  onChangeDirectionByLang: () => {},
  onToggleLayout: () => {},
  onChangeLayout: () => {},
  onToggleContrast: () => {},
  onChangeContrast: () => {},
  onChangeColorPresets: () => {},
  presetsColor: defaultPreset,
  presetsOption: [],
  onToggleStretch: () => {},
  onResetSetting: () => {},
  toggleShowSettingsDrawer: () => {},
  showSettingsDrawer: false,
  toggleShowConnectDrawer: () => {},
  showConnectDrawer: false,
};

export const SettingsContext = createContext(initialState);
export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error("useSettingsContext must be use inside SettingsProvider");
  return context;
};

type SettingsProviderProps = {
  children: React.ReactNode;
};

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage("settings", defaultSettings);

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem("i18nextLng") : "";

  const isArabic = langStorage === "ar";

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang("ar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);

  const toggleShowSettingsDrawer = useCallback(() => {
    setSettings({
      ...settings,
      showSettingsDrawer: !settings.showSettingsDrawer,
    });
  }, [setSettings, settings]);

  const toggleShowConnectDrawer = useCallback(() => {
    setSettings({
      ...settings,
      showConnectDrawer: !settings.showConnectDrawer,
    });
  }, [setSettings, settings]);

  const onToggleMode = useCallback(() => {
    const themeMode = settings.themeMode === "light" ? "dark" : "light";
    setSettings({ ...settings, themeMode });
  }, [setSettings, settings]);

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log("onChangeMode event", event.target.value);
      const themeMode = event.target.value;
      setSettings({ ...settings, themeMode });
    },
    [setSettings, settings],
  );

  // const changeMode = useCallback(
  //   (value: string) => {
  //     console.log("changeMode", changeMode);
  //     const themeMode = value;
  //     setSettings({ ...settings, themeMode });
  //   },
  //   [setSettings, settings],
  // );

  const changeTheme = (value: string) => {
    const themeMode = value;
    setSettings({ ...settings, themeMode });
  };

  const onToggleDirection = useCallback(() => {
    const themeDirection = settings.themeDirection === "rtl" ? "ltr" : "rtl";
    setSettings({ ...settings, themeDirection });
  }, [setSettings, settings]);

  const onChangeDirection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeDirection = event.target.value;
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings],
  );

  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      const themeDirection = lang === "ar" ? "rtl" : "ltr";
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings],
  );

  const onToggleLayout = useCallback(() => {
    const themeLayout = settings.themeLayout === "vertical" ? "mini" : "vertical";
    setSettings({ ...settings, themeLayout });
  }, [setSettings, settings]);

  const onChangeLayout = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeLayout = event.target.value;
      setSettings({ ...settings, themeLayout });
    },
    [setSettings, settings],
  );

  const onToggleContrast = useCallback(() => {
    const themeContrast = settings.themeContrast === "default" ? "bold" : "default";
    setSettings({ ...settings, themeContrast });
  }, [setSettings, settings]);

  const onChangeContrast = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeContrast = event.target.value;
      setSettings({ ...settings, themeContrast });
    },
    [setSettings, settings],
  );

  const onChangeColorPresets = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log("onChangeColorPresets", event.target.value);
      const themeColorPresets = event.target.value;
      setSettings({ ...settings, themeColorPresets });
    },
    [setSettings, settings],
  );

  const onToggleStretch = useCallback(() => {
    const themeStretch = !settings.themeStretch;
    setSettings({ ...settings, themeStretch });
  }, [setSettings, settings]);

  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  const memoizedValue: SettingsContextProps = useMemo(
    () => ({
      ...settings,
      onToggleMode,
      onChangeMode,
      changeTheme,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      onToggleLayout,
      onChangeLayout,
      onChangeContrast,
      onToggleContrast,
      onToggleStretch,
      onChangeColorPresets,
      presetsOption,
      presetsColor: getPresets(settings.themeColorPresets),
      // Reset
      onResetSetting,
      toggleShowSettingsDrawer,
      toggleShowConnectDrawer,
      showSettingsDrawer: settings.showSettingsDrawer,
      showConnectDrawer: settings.showConnectDrawer,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      settings,
      onToggleMode,
      onChangeMode,
      changeTheme,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      onToggleLayout,
      onChangeLayout,
      onChangeContrast,
      onToggleContrast,
      onToggleStretch,
      onChangeColorPresets,
      onResetSetting,
      toggleShowSettingsDrawer,
      toggleShowConnectDrawer,
      settings.showSettingsDrawer,
      settings.showConnectDrawer,
    ],
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
