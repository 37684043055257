import { Box, Card, CardContent, Stack, Typography, useMediaQuery } from "@mui/material";
import { Certic } from "../icons/Certic";
import { Github } from "../icons/Github";

export const SmartContract = () => {
  const lap = useMediaQuery("(max-width:1370px)");
  const small = useMediaQuery("(max-width:450px)");

  return (
    <Box mb={small ? "50px" : "100px"} sx={{ "@media(max-width:1026px)": { mb: "72px" } }}>
      <Box>
        <Typography fontWeight="700" fontSize="24px" color="#fff">
          Smart contract on GitHub
        </Typography>
        <Box color="#989BA9" mt="20px" mb="30px" component="p">
          We publish our source code on GitHub because of a strong will to be fair and square
        </Box>
        <Stack columnGap="30px" flexDirection="row" flexWrap={lap ? "wrap" : "nowrap"}>
          <img style={{ flex: "1 1 auto" }} src="/image 110.png" alt="" />
          <Stack
            width="100%"
            justifyContent={lap ? "space-between" : ""}
            flexDirection={lap ? "row" : "column"}
            rowGap="30px"
            columnGap={lap ? "12px" : 0}
            mt={lap ? "24px" : 0}
            sx={{ "@media(max-width:600px)": { flexDirection: "column", rowGap: "0" } }}
          >
            <Card
              sx={{
                width: lap ? "min(100%)" : "280px",
                height: lap ? "min(159px, 100%)" : "100%",
                backgroundColor: "#292E41",
                marginBottom: lap ? "20px" : "",
              }}
            >
              <CardContent
                onClick={() => window.open("https://github.com/UBDNetwork/Smartcontract", "_blank")}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: lap ? "0" : "60px",
                  cursor: "pointer",
                }}
              >
                <Github />
                <Box mt="24px" color="#fff" component="p" display={lap ? "flex" : "block"} gap={lap ? 1 : 0}>
                  <p>Check UBD</p>
                  <p>Network GitHub</p>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: lap ? "min(100%)" : "280px",
                height: lap ? "min(159px, 100%)" : "100%",
                backgroundColor: "#292E41",
              }}
            >
              <CardContent
                onClick={() => window.open("https://www.certik.org/projects/ubd-network", "_blank")}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: lap ? "0" : "60px",
                  cursor: "pointer",
                }}
              >
                <Certic />
                <Box mt="24px" color="#fff" component="p" display={lap ? "flex" : "block"} gap={lap ? 1 : 0}>
                  <p>Check CertiK</p>
                  <p>audit results</p>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
