export const utmParser = () => {
  // можно улучшить
  //   const utmSource = window.location.search.match(/utm_source=([^&]+)/)?.[1];
  //   const utmMedium = window.location.search.match(/utm_medium=([^&]+)/)?.[1];
  //   const utm_campaign = window.location.search.match(/utm_campaign=([^&]+)/)?.[1];
  //   const utm_content = window.location.search.match(/utm_content=([^&]+)/)?.[1];
  const httpReferrer = document.referrer;
  const paramsFromUrl = new URLSearchParams(window.location.search);

  localStorage.setItem("httpReferrer", JSON.stringify({ httpReferrer }));
  localStorage.setItem("utmTags", paramsFromUrl.toString());

  return { paramsFromUrl, httpReferrer };
};

