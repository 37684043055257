export const Right = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9933 20.4667C13.9218 20.3952 13.8651 20.3104 13.8264 20.217C13.7876 20.1236 13.7677 20.0235 13.7677 19.9225C13.7677 19.8214 13.7876 19.7213 13.8264 19.6279C13.8651 19.5345 13.9218 19.4497 13.9933 19.3782L19.6039 13.7686H4.53756C4.33354 13.7686 4.13789 13.6876 3.99363 13.5433C3.84937 13.399 3.76833 13.2034 3.76833 12.9994C3.76833 12.7954 3.84937 12.5997 3.99363 12.4554C4.13789 12.3112 4.33354 12.2301 4.53756 12.2301H19.6039L13.9933 6.62053C13.849 6.47619 13.7679 6.28043 13.7679 6.0763C13.7679 5.87217 13.849 5.67641 13.9933 5.53207C14.1377 5.38773 14.3334 5.30664 14.5376 5.30664C14.7417 5.30664 14.9374 5.38773 15.0818 5.53207L22.0049 12.4551C22.0764 12.5266 22.1331 12.6114 22.1718 12.7048C22.2105 12.7982 22.2305 12.8983 22.2305 12.9994C22.2305 13.1005 22.2105 13.2006 22.1718 13.2939C22.1331 13.3873 22.0764 13.4722 22.0049 13.5436L15.0818 20.4667C15.0103 20.5382 14.9255 20.5949 14.8321 20.6337C14.7387 20.6724 14.6386 20.6923 14.5376 20.6923C14.4365 20.6923 14.3364 20.6724 14.243 20.6337C14.1496 20.5949 14.0648 20.5382 13.9933 20.4667Z"
        fill="#13151B"
      />
    </svg>
  );
}
