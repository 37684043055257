import { useMediaQuery } from "@mui/material";

export const Github = () => {
  const lap = useMediaQuery("(max-width:1370px)");

  return (
    <svg
      width={lap ? "48" : "32"}
      height={lap ? "48" : "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3328 29.3694V25.5427C21.3828 24.9069 21.2969 24.2678 21.0808 23.6678C20.8647 23.0678 20.5234 22.5206 20.0795 22.0627C24.2661 21.5961 28.6661 20.0094 28.6661 12.7294C28.6658 10.8678 27.9497 9.07765 26.6661 7.72938C27.2739 6.10072 27.2309 4.30052 26.5461 2.70272C26.5461 2.70272 24.9728 2.23605 21.3328 4.67605C18.2768 3.84781 15.0554 3.84781 11.9995 4.67605C8.35945 2.23605 6.78612 2.70272 6.78612 2.70272C6.10129 4.30052 6.05831 6.10072 6.66612 7.72938C5.37295 9.08765 4.65615 10.894 4.66612 12.7694C4.66612 19.996 9.06612 21.5827 13.2528 22.1027C12.8141 22.556 12.4758 23.0966 12.2599 23.6893C12.0439 24.282 11.9552 24.9135 11.9995 25.5427V29.3694"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9993 26.7031C7.99935 28.0005 4.66602 26.7031 2.66602 22.7031"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
