import { getContracts, getTokens } from "~/utils/web3-constants";
import { resolveCustomToken } from "~/utils/web3-utils";

const DEV_BACKEND_URL = "https://api-dev.ubdn.com";
const PROD_BACKEND_URL = "https://api.ubdn.com";

const STAGE_URL = "app-ubdn.oxygenlab.io";
const PROD_URL = "app.ubdn.com";

export const resolveTokenAddress = (
  chainId: number | undefined,
  fromSelect: string | undefined,
  isAllowance: boolean = false,
): `0x${string}` => {
  if (!fromSelect) return "" as `0x${string}`;

  // eslint-disable-next-line no-prototype-builtins
  if (getTokens(chainId).hasOwnProperty(fromSelect)) {
    return getTokens(chainId)[fromSelect] as `0x${string}`;
  }

  const customToken = resolveCustomToken(chainId ?? 0, fromSelect);
  if (!!customToken && isAllowance) return getTokens(chainId).USDT as `0x${string}`;
  if (customToken) return customToken.address as `0x${string}`;

  // console.warn("Unknown fromSelect", fromSelect);
  return "" as `0x${string}`;
};

export const resolveFromAddress = (
  chainId: number | undefined,
  fromSelect: string | undefined,
): `0x${string}` | undefined => {
  switch (fromSelect) {
    case "USDT":
    case "UBD": // will be incorrect if dai will be present
      return getContracts(chainId).SANDBOX_USDT as `0x${string}`;
    case undefined:
    case null:
      return "0x0000000000000000000000000000000000000000" as `0x${string}`;
    default:
      // if (resolveCustomToken(chainId ?? 0, fromSelect) === undefined)
      //   console.warn("Unknown fromSelect", fromSelect);
      return getContracts(chainId).SANDBOX_USDT as `0x${string}`;
  }
};

export const resolveSwapAbi = (fromSelect: string | undefined): any => {
  switch (fromSelect) {
    case "USDT":
    case "UBD": // will be incorrect if dai will be present
      return "SandBox1Abi";
    case undefined:
    case null:
      return null;
    default:
      // if (resolveCustomToken(chainId ?? 0, fromSelect) === undefined)
      //   console.warn("Unknown fromSelect", fromSelect);
      return "SandBox1Abi";
  }
};

export const isStage = location.host.includes(STAGE_URL);
export const isProd = location.host.includes(PROD_URL);
export const isDev = !isStage && !isProd;

export const BACKEND_URL = import.meta.env.VITE_API_URL;

console.log("isStage: ", isStage);
console.log("isProd: ", isProd);
console.log("API_URL: ", BACKEND_URL);

export const isRoot = () => window.location.pathname === "/" || window.location.pathname.includes("login");
