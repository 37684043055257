export interface JwtInfo {
  token: string;
  address: string;
}

export const getJwtInfo = (): JwtInfo | undefined => {
  const localJwt = localStorage.getItem("jwt");
  if (!localJwt) return undefined;
  else return JSON.parse(localJwt);
};
