import { Button, IconButton, Stack, Typography, styled, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useAccount } from "wagmi";
import Iconify from "~/components/iconify/Iconify";
import { ETHERSCAN_URLS } from "~/utils/web3-constants";
import { CoinIcon } from "../tokenIconMap";
import { BaseSwapperFormikValues } from "../types";

interface SwapperSuccessProps {
  shown: boolean;
  handleClose: () => void;
  customText?: string;
}

const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6665 51.9974C8.6665 28.0651 28.0675 8.66406 51.9998 8.66406C63.4926 8.66406 74.5146 13.2295 82.6411 21.3561C90.7677 29.4827 95.3332 40.5047 95.3332 51.9974C95.3332 75.9297 75.9322 95.3307 51.9998 95.3307C28.0675 95.3307 8.6665 75.9297 8.6665 51.9974ZM50.8298 67.6407L70.6332 41.6407V41.5107C71.5775 40.2722 71.7894 38.624 71.189 37.1868C70.5886 35.7497 69.2671 34.7421 67.7224 34.5435C66.1776 34.3449 64.6442 34.9855 63.6998 36.2241L47.3198 57.8907L40.2565 48.8774C39.3044 47.6544 37.7721 47.0322 36.2369 47.2452C34.7016 47.4582 33.3967 48.4741 32.8135 49.9102C32.2304 51.3463 32.4577 52.9844 33.4098 54.2074L43.9832 67.6841C44.8101 68.7305 46.0728 69.3379 47.4065 69.3307C48.7477 69.3275 50.0117 68.7035 50.8298 67.6407Z"
      fill="#00AB55"
    />
  </svg>
);

const CoinRowWrap = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const SwapperSuccessWrapper = styled("div")`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CoinRow = ({ symbol, amount, customText }: { symbol: string; amount?: string; customText?: string }) => (
  <CoinRowWrap>
    <CoinIcon symbol={symbol} style={{ marginRight: "8px" }} />
    {customText ? (
      <Typography variant="body2" sx={{ ml: 1 }}>
        {customText}
      </Typography>
    ) : (
      <>
        <Typography variant="body2">{amount}</Typography>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {symbol}
        </Typography>
      </>
    )}
  </CoinRowWrap>
);

const SwapperSuccess: React.FC<SwapperSuccessProps> = ({ handleClose, shown, customText }) => {
  const { values } = useFormikContext<BaseSwapperFormikValues>();
  const { chain } = useAccount();
  const theme = useTheme();

  if (!shown) {
    return null;
  }

  const handleClick = () => {
    const { txId } = values;
    const link = `${ETHERSCAN_URLS[chain?.id ?? 1]}/tx/${txId}`;
    window.open(link, "_blank");
  };

  return (
    <SwapperSuccessWrapper sx={{ bgcolor: theme.palette.background.paper }}>
      <IconButton
        sx={{
          position: "absolute",
          top: "24px",
          right: "24px",
        }}
        onClick={handleClose}
      >
        <Iconify icon="mdi:close" width={28} height={28} />
      </IconButton>
      <Stack gap={2} direction="column" alignItems="center" position="relative">
        <SuccessIcon />
        <Typography variant="h4" sx={{ mt: "5px" }}>
          Success!
        </Typography>
        <Stack sx={{ mt: "25px" }} gap={2} direction="column" alignItems="center">
          {customText ? (
            <CoinRow symbol={values.fromToken} customText={customText} />
          ) : (
            <>
              <CoinRow symbol={values.fromToken} amount={values.fromValue} />
              <Iconify icon="mdi:arrow-down" />
              <CoinRow symbol={values.toToken} amount={values.toValue} />
            </>
          )}
        </Stack>
        <Button sx={{ mt: "21px" }} variant="outlined" fullWidth onClick={handleClick}>
          View on Etherscan
        </Button>
      </Stack>
    </SwapperSuccessWrapper>
  );
};

export default SwapperSuccess;
