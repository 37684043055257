import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";
import { getJwtInfo } from "~/shared/utils/jwt";

export interface AuthInfo {
  _id: string;
  address: string;
  tgReward: boolean;
  pendingUbdn: number;
  points: number;
  spins: number;
  maxSpins: number;
  spinsReset: string;
  referrals: any[];
  ubdPrizePool: number;
  usdtBalance: number;
  ubdBalance: number;
  isApproved: boolean;
  discordId?: string;
  twitterId?: string;
  telegramId?: string;
  referralPoints: number;
  email?: string;
  __v: number;
}

export const useJwtAuth = () => {
  const [authInfo, setAuthInfo] = useState<AuthInfo | undefined>(undefined);
  const [isAuthInit, setIsAuthInit] = useState<boolean | undefined>(undefined);
  const { address, chain, isConnected } = useAccount();

  const fetchInfo = async () => {
    const jwtInfo = getJwtInfo();

    if (!jwtInfo) {
      setIsAuthInit(false);
      setAuthInfo(undefined);
      return;
    }

    if (!!address && jwtInfo.address.toLowerCase() !== address?.toLowerCase()) {
      localStorage.removeItem("jwt");
      setAuthInfo(undefined);
      setIsAuthInit(false);
      window.location.href = "/login";
      return;
    }

    const response = await instance.get<InstanceResponse<AuthInfo>>("/auth/info");
    if (response.data.ok == true) {
      setAuthInfo({...response.data.details, points: Math.ceil(response.data.details.points) , referralPoints: Math.ceil(response.data.details.points)});
      setIsAuthInit(true);
    } else {
      localStorage.removeItem("jwt");
      setIsAuthInit(false)
      window.location.href = "/login";
    };

    const responseReferrals = await instance.get<InstanceResponse<any>>("/auth/info/referrals");
    setAuthInfo({...response.data.details, points: Math.ceil(response.data.details.points), referralPoints: Math.ceil(responseReferrals.data.details.points)});
  };

  useEffect(() => {
    // console.log("fetching auth info");
    fetchInfo();
  }, [address, chain, isConnected]);

  return { authInfo, fetchInfo, isAuthInit };
};
