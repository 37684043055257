export const HOME_ROUTE = "/";

export const ABOUT_ROUTE = "about";
export const CLAIM_ROUTE = HOME_ROUTE + "claim";
export const DASHBOARD_ROUTE = HOME_ROUTE + "dashboard/";
export const LEADERBOARD_ROUTE = HOME_ROUTE + "leaderboard";
export const LOGIN_ROUTE = HOME_ROUTE + "login";
export const REFERRAL_ROUTE = HOME_ROUTE + "referral";
export const REFERRAL_FAQ_ROUTE = HOME_ROUTE + "referral-faq";
export const SOCIAL_ROUTE = DASHBOARD_ROUTE + "social";
export const SPIN_ROUTE = HOME_ROUTE + "spin";
export const SWAP_ROUTE = HOME_ROUTE + "swap";
