import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Footer } from "~/modules/Footer/Footer";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { bgBlur } from "../../../utils/cssStyles";
import NavVertical from "../nav/NavVertical";
import navConfig from "../nav/config-navigation";
import { useMenuContext } from "./MenuDrawerContext";

export function MenuDrawer() {
  const { open, setOpen } = useMenuContext();

  const theme = useTheme();
  const location = useLocation();
  const isRootLoginPage =
    !navConfig[0].items.some((path) => path.path === location.pathname) || location.pathname.includes("login");
  const isAboutPage = location.pathname.includes("about");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        sx: {
          ...bgBlur({
            color: theme.palette.background.default,
            opacity: 0.9,
          }),
          width: "100%",
          height: "100%",
          boxShadow: `-24px 12px 40px 0 ${alpha(
            theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.common.black,
            0.16,
          )}`,
          ...(isRootLoginPage && { bgcolor: "#292E41" }),
        },
      }}
    >
      <Stack direction="row-reverse" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2 }}>
        <IconButton onClick={handleClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Scrollbar sx={{ p: 2, pb: 0 }}>
        <NavVertical />
      </Scrollbar>
      <Box
        sx={{
          p: 2,
          pt: 2,
          background: theme.palette.background.default,
          mt: "auto",
        }}
      >
        <Footer />
      </Box>
    </Drawer>
  );
}
