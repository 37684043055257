export const GrayLine = () => {
  return (
    <svg
      style={{ width: "min(100%, 414px)" }}
      width="414"
      height="2"
      viewBox="0 0 414 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H413" stroke="#292E41" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
};

export const GrayLineMobile = () => {
  return (
    <svg
      style={{ width: "min(100%, 622px)" }}
      width="622"
      height="2"
      viewBox="0 0 622 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H621" stroke="#292E41" stroke-width="2" stroke-linecap="round" />
    </svg>
  );
};
