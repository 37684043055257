import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import Cookies from "cookies-js";
import { useSnackbar } from "notistack";
import { useEffect, useLayoutEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { DiscordIcon } from "~/assets/DiscordIcon";
import { TelegramIcon } from "~/assets/TelegramIcon";
import { XIcon } from "~/assets/XIconProps";
import { instance } from "~/shared/lib";
import { getJwtInfo } from "~/shared/utils/jwt";
import { Gradient } from "../gradientJs/Gradient";
import gradientStyle from "../gradientJs/gradient.module.css";
import maskStyle from "./Code/mask.module.css";
import { useLogin } from "./hooks/useLogin";

const COLOR = "#30425a";

const initGradient = () => {
  const gradient = new Gradient();
  gradient.initGradient("#gradient-canvas");
};

const validCode = (code: string) => {
  const data = instance.get(`/referral/isexist/${code}`);
  return data;
};

export default function LoginPage() {
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [codeIsValid, setCodeIsValid] = useState(false);
  const { id } = useParams();
  const inviter_id = Cookies.get("inviter_id");
  const [loading, setLoading] = useState(false);
  const { onClick: onClickLogin, loading: loginLoading } = useLogin();
  const { onClick: onClickRegister, loading: registerLoading } = useLogin(true);
  const { isAuthorized } = useWallet();
  const haveJwt = getJwtInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!inviter_id) return;
    if (inviter_id && inviter_id.length === 6) {
      setCodeIsValid(true);
      setVerificationCode(inviter_id);
    }
  }, [inviter_id]);

  useLayoutEffect(() => {
    setCodeIsValid(false);
    if (!id) return;
    if (id && id.length === 6) {
      Cookies.set("inviter_id", id);
      localStorage.setItem("code", id);
      return navigate("/");
    }
  }, [id]);
  console.log("is id page");
  useLayoutEffect(() => {
    setCodeIsValid(false);
    if (verificationCode && verificationCode.length === 6) {
      setCodeIsValid(true);
    }
  }, [verificationCode]);

  useEffect(() => {
    initGradient();
  }, []);

  useEffect(() => {
    if (isAuthorized && haveJwt) navigate("/dashboard");
  }, [isAuthorized, haveJwt]);

  return (
    <Stack alignItems={"center"}>
      <Box
        sx={{
          top: 0,
          left: 0,
          zIndex: 0,
          width: "100vw",

          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          overflow: "hidden",
        }}
      >
        <canvas
          className={gradientStyle.gradient}
          id="gradient-canvas"
          data-transition-in
          style={{
            width: "100vw",
            // height: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        />

        <Stack flexDirection={"column"} gap={3} pt={"20vh"}>
          <Typography variant="h4" color={"#1e2b40"} textAlign={"center"}>
            Enter your invite code
          </Typography>
          <Box
            sx={{
              py: 3.5,
            }}
            display={"flex"}
            justifyContent={"center"}
          >
            {loading ? (
              <CircularProgress
                size={"2.5rem"}
                sx={{
                  color: COLOR,
                }}
              />
            ) : (
              <Form className={`flex ${maskStyle.block}`}>
                <VerificationInput
                  placeholder="."
                  value={verificationCode}
                  onChange={(val) => setVerificationCode(val)}
                  length={6}
                  classNames={{
                    container: maskStyle.container,
                    character: maskStyle.character,
                    characterSelected: maskStyle.characterSelected,
                    characterInactive: maskStyle.characterText,
                  }}
                />
              </Form>
            )}
          </Box>

          <Typography variant="body2" textAlign={"center"} color={COLOR}>
            Need a code? Ask in our socials
          </Typography>

          <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={3}>
            <Link
              href="https://discord.com/invite/EAkXDyjXTb"
              target="_blank"
              sx={{
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              <DiscordIcon style={{ height: "16px", color: COLOR }} />
            </Link>

            <Link
              href="https://t.me/ubdnet"
              target="_blank"
              sx={{
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              <TelegramIcon style={{ height: "16px", color: COLOR }} />
            </Link>

            <Link
              href="https://twitter.com/ubdnet"
              target="_blank"
              sx={{
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              <XIcon style={{ height: "16px", color: COLOR }} />
            </Link>
          </Stack>
          <Box display={"flex"} justifyContent={"center"} flexDirection="column" gap={2}>
            <LoadingButton
              loading={registerLoading}
              variant="contained"
              disabled={!codeIsValid}
              onClick={() => {
                localStorage.setItem("code", verificationCode);
                onClickRegister();
              }}
              sx={{
                px: 3,
                py: 1,
                fontWeight: 400,
                backgroundColor: COLOR,
                "&:hover": {
                  backgroundColor: "#131b29",
                },
              }}
            >
              Redeem invite code
            </LoadingButton>

            <LoadingButton
              loading={loginLoading}
              variant="outlined"
              color="secondary"
              onClick={onClickLogin}
              sx={{
                px: 3,
                py: 1,
                fontWeight: 400,
              }}
            >
              Sign in
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
