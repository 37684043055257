import { IconProps } from "./types";

export const HackerNoonIcon = ({ style }: IconProps) => {
  return (
    <svg viewBox="0 0 926 115" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M83.0195 89.9494C83.0222 98.2999 83.0275 106.65 83.0302 115.001H18.2481C18.2774 113.188 18.3281 111.375 18.3307 109.563C18.3414 103.583 18.3334 97.6031 18.3334 91.6233V90.0856H9.19197V71.5774L9.16797 71.6014C9.26397 71.5667 9.36264 71.532 9.45864 71.5C12.3867 71.5374 15.312 71.5748 18.2401 71.6121C18.3067 71.6976 18.3761 71.783 18.4427 71.8684V90.0055H36.9443V98.9993C37.0376 99.0554 37.131 99.1114 37.227 99.1675H64.5153C64.5153 96.1082 64.5153 93.1903 64.5153 90.2751C64.5659 90.1817 64.6139 90.0909 64.6646 89.9975C70.79 89.9922 76.9181 89.9841 83.0435 89.9788L83.0195 89.9494Z"
        fill="currentColor"
      />
      <path
        d="M766.306 90.9694C762.532 90.9801 758.756 91.0014 754.983 91.0041C737.217 91.0068 719.452 91.0041 701.686 91.0041H700.209V24.5957H766.522C766.543 24.9401 766.58 25.2364 766.58 25.5354C766.583 47.0121 766.58 68.4889 766.58 89.9656C766.58 90.2299 766.554 90.4969 766.538 90.7611C766.46 90.8306 766.386 90.9 766.308 90.9694H766.306ZM753.162 77.6001V38.0344H713.588V77.6001H753.162Z"
        fill="currentColor"
      />
      <path
        d="M846.015 90.9681C842.242 90.9788 838.466 91.0002 834.693 91.0028C816.927 91.0055 799.162 91.0028 781.393 91.0028H779.916V24.5918H846.231C846.253 24.9362 846.287 25.2325 846.287 25.5315C846.287 47.0082 846.287 68.485 846.287 89.9617C846.287 90.226 846.261 90.493 846.245 90.7572C846.167 90.8267 846.093 90.8961 846.015 90.9655V90.9681ZM793.271 37.9877V77.5775H832.89V37.9877H793.271Z"
        fill="currentColor"
      />
      <path
        d="M593.977 69.7644V77.597H607.137V90.9155H593.902V77.6717H580.769C580.697 77.5943 580.625 77.5142 580.553 77.4368V64.476H554.059V90.758C553.982 90.8274 553.907 90.8968 553.83 90.9662H541.045C540.968 90.8968 540.893 90.8274 540.816 90.7553C540.803 90.3575 540.773 89.9598 540.773 89.5593C540.773 68.3522 540.773 47.1451 540.773 25.9406V24.5898H607.137V64.4173H594.121C594.073 66.1979 594.027 67.9811 593.979 69.7617L593.977 69.7644ZM569.63 37.9084C569.419 37.8603 569.208 37.7669 568.998 37.7669C564.302 37.7589 559.608 37.7562 554.912 37.7722C554.654 37.7722 554.395 37.9537 554.136 38.0525V50.9733H593.785V37.9084C585.675 37.9084 577.651 37.9084 569.63 37.9084Z"
        fill="currentColor"
      />
      <path
        d="M288.431 53.2632C288.444 55.9808 288.455 58.6984 288.468 61.4161C288.468 64.2004 288.468 66.9875 288.468 69.7718V90.8962H275.108V64.4754H235.339V90.9202H222.035V24.6133H288.444C288.444 31.5168 288.444 38.3402 288.444 45.1663C288.439 47.8653 288.433 50.5642 288.428 53.2632H288.431ZM235.531 51.0421C248.67 51.0448 261.812 51.0501 274.95 51.0528C275.006 49.0933 275.06 47.1338 275.116 45.1744V38.0065H235.313V50.7965C235.385 50.8766 235.457 50.9593 235.531 51.0394V51.0421Z"
        fill="currentColor"
      />
      <path
        d="M474.41 38.0518V51.1194C483.234 51.1194 492.002 51.1194 500.77 51.1194C500.837 51.1995 500.904 51.2795 500.973 51.3596C500.973 55.6096 500.973 59.8622 500.973 64.1122C500.906 64.1923 500.837 64.2724 500.77 64.3525C492.512 64.3658 484.255 64.3818 475.997 64.3952C475.514 64.3952 475.031 64.3952 474.431 64.3952V77.4387C474.509 77.5081 474.586 77.5775 474.663 77.6469C483.189 77.6469 491.714 77.6469 500.24 77.6469C508.81 77.6469 517.381 77.6469 525.955 77.6469H527.44V90.9495C527.035 90.9681 526.691 91.0002 526.347 91.0002C504.896 91.0002 483.445 91.0002 461.997 91.0002C461.775 91.0002 461.554 90.9788 461.333 90.9655C461.255 90.8961 461.181 90.8267 461.103 90.7546C461.09 90.4449 461.061 90.1352 461.061 89.8256C461.061 68.4903 461.061 47.1524 461.061 25.8171C461.061 25.4274 461.093 25.0403 461.114 24.5918H527.429V37.7421C526.589 37.7475 525.749 37.7475 524.909 37.7608C522 37.8035 519.088 37.8489 516.179 37.8916C509.666 37.9076 503.152 37.9317 496.64 37.9343C491.28 37.9343 485.917 37.913 480.557 37.8996C478.85 37.8436 477.146 37.7688 475.439 37.7475C475.098 37.7421 474.754 37.9423 474.413 38.0491L474.41 38.0518Z"
        fill="currentColor"
      />
      <path
        d="M872.829 77.7759V90.8969H859.469C859.469 81.0942 859.469 71.3102 859.469 61.5289C859.519 61.0537 859.61 60.5785 859.61 60.1033C859.621 55.6825 859.621 51.2644 859.61 46.8436C859.61 46.2803 859.517 45.717 859.469 45.1537V24.6113H925.942C925.963 24.937 925.998 25.2333 925.998 25.5323C925.998 47.0091 925.998 68.4858 925.998 89.9625C925.998 90.2268 925.971 90.4938 925.955 90.7581C925.878 90.8275 925.803 90.8969 925.726 90.9663C921.424 90.9663 917.125 90.9663 912.659 90.9663V38.0286H872.826C872.826 40.4019 872.826 42.7805 872.826 45.1564C872.776 45.6316 872.685 46.1068 872.685 46.5819C872.674 51.0028 872.674 55.4209 872.685 59.8417C872.685 60.405 872.778 60.9683 872.826 61.5316C872.821 64.2412 872.816 66.9481 872.81 69.6578C872.816 72.3647 872.821 75.0717 872.826 77.7786L872.829 77.7759Z"
        fill="currentColor"
      />
      <path
        d="M155.837 51.052C168.976 51.0467 182.117 51.044 195.256 51.0387C195.328 50.9586 195.4 50.8759 195.475 50.7958V24.6206H208.752V90.9328H195.485V64.4773H155.72V90.9435H142.33V24.6152H155.693C155.693 28.7718 155.693 32.8455 155.693 36.9193C155.637 37.1249 155.533 37.3331 155.533 37.5387C155.522 41.7859 155.52 46.0359 155.538 50.2832C155.538 50.5395 155.733 50.7958 155.837 51.052Z"
        fill="currentColor"
      />
      <path
        d="M673.579 90.7582C673.566 88.8521 673.537 86.9461 673.537 85.0373C673.534 69.8634 673.537 54.6896 673.534 39.5157C673.534 39.0272 673.513 38.5413 673.502 38.0528C673.065 37.954 672.63 37.7725 672.193 37.7725C662.121 37.7565 652.048 37.7591 641.976 37.7671C641.547 37.7671 641.118 37.8739 640.688 37.93H633.814V39.4703C633.814 56.0804 633.814 72.6905 633.814 89.298C633.814 89.7838 633.787 90.2697 633.771 90.7555C633.694 90.825 633.619 90.8944 633.542 90.9638H620.525V24.6328H686.846V90.9691C682.483 90.9691 678.147 90.9691 673.809 90.9691C673.731 90.8997 673.657 90.8303 673.579 90.7582Z"
        fill="currentColor"
      />
      <path
        d="M315.46 37.8737C315.305 38.1327 315.017 38.3916 315.017 38.6506C315.015 51.5793 315.036 64.5054 315.055 77.4342C315.119 77.4982 315.18 77.5596 315.244 77.6237C317.196 77.6317 319.151 77.6451 321.103 77.6477C336.388 77.6477 351.671 77.6477 366.956 77.6477H368.106V90.9155H301.734V24.6406H368.012V37.7509C351.066 37.7536 334.119 37.7563 317.175 37.7669C316.604 37.7669 316.033 37.839 315.46 37.8764V37.8737Z"
        fill="currentColor"
      />
      <path
        d="M9.19516 43.8958V25.5372H18.3366V0H83.02V25.5185L83.044 25.4945C82.948 25.5292 82.8493 25.5639 82.7533 25.5959H64.4385V16.4045H55.337C55.337 16.9838 55.337 17.4644 55.337 17.9449C55.337 28.5885 55.3424 39.2321 55.337 49.8757C55.337 54.0002 55.3104 58.122 55.2944 62.2465C52.353 62.2892 49.4116 62.3346 46.4729 62.3773C46.3236 61.8861 46.0436 61.3949 46.0436 60.9037C46.0196 46.5868 46.0249 32.2672 46.0249 17.9502C46.0249 17.435 46.0249 16.9224 46.0249 16.3058H37.2301C37.1368 16.3618 37.0435 16.4179 36.9501 16.474V25.5719H18.7953L18.5366 25.7774C18.5179 26.2633 18.4859 26.7492 18.4833 27.2377C18.4699 32.6943 18.4593 38.1509 18.4486 43.6075C18.3819 43.693 18.3126 43.7784 18.2459 43.8638C15.3179 43.9012 12.3925 43.9386 9.4645 43.9759C9.3685 43.9412 9.26983 43.9065 9.17383 43.8745L9.19783 43.8985L9.19516 43.8958Z"
        fill="currentColor"
      />
      <path
        d="M434.634 37.9375C434.591 38.0416 434.548 38.1431 434.503 38.2472C434.519 40.5857 434.538 42.927 434.554 45.2655V51.0799H421.316C421.316 55.6075 421.316 60.0096 421.316 64.4144L421.343 64.3877C421.247 64.3957 421.151 64.401 421.055 64.409C412.348 64.409 403.641 64.409 394.785 64.409V90.966H381.62C381.542 90.8966 381.468 90.8272 381.39 90.7551C381.377 90.4454 381.348 90.1357 381.348 89.8261C381.348 68.4962 381.348 47.1636 381.348 25.8337V24.5977H394.737V50.7969C394.809 50.877 394.881 50.9597 394.956 51.0398C403.607 51.0398 412.255 51.0398 421.105 51.0398V45.2415C421.145 42.9109 421.185 40.5831 421.225 38.2525C421.631 38.103 422.033 37.8281 422.439 37.8254C426.383 37.7934 430.33 37.8067 434.418 37.8067V24.5977H447.799V37.8975C443.89 37.8975 439.962 37.8975 436.034 37.8975C435.567 37.9108 435.103 37.9242 434.636 37.9349L434.634 37.9375Z"
        fill="currentColor"
      />
      <path
        d="M83.0202 25.5189H92.1963V43.8535H101.399V53.125H110.666V62.3751H101.492V71.5691H92.2683V89.9491C89.1082 89.9491 86.0629 89.9491 83.0175 89.9491L83.0415 89.9785C83.0415 83.8518 83.0415 77.7251 83.0415 71.521H91.8149L92.0736 71.3155C92.0923 70.0367 92.1269 68.7553 92.1269 67.4766C92.1136 59.7028 92.0923 51.929 92.0736 44.1552C91.9883 44.0858 91.9029 44.019 91.8149 43.9496C88.9082 43.9496 86.0015 43.9496 82.9935 43.9496C82.9935 43.3089 82.9935 42.8338 82.9935 42.3586C82.9748 36.8325 82.9562 31.3092 82.9375 25.7832C82.9722 25.6871 83.0068 25.591 83.0388 25.4922L83.0148 25.5162L83.0202 25.5189Z"
        fill="currentColor"
      />
      <path
        d="M434.555 69.764V77.6152H447.84V90.931H434.504V77.7193H421.189V69.7586C421.243 67.9673 421.296 66.1761 421.347 64.3848L421.32 64.4115C425.672 64.4115 430.027 64.4115 434.379 64.4115C434.438 66.1947 434.496 67.9753 434.552 69.7586L434.555 69.764Z"
        fill="currentColor"
      />
      <path
        d="M9.19477 71.576H0V43.8951C3.10404 43.8951 6.1494 43.8951 9.19477 43.8951L9.17077 43.8711C9.20544 43.9672 9.24011 44.066 9.27211 44.1621C9.25344 45.8466 9.21611 47.5311 9.21877 49.2156C9.22944 56.5809 9.25344 63.9436 9.27211 71.309C9.23744 71.4051 9.20277 71.5012 9.17077 71.6L9.19477 71.576Z"
        fill="currentColor"
      />
      <path
        d="M315.459 37.8735C316.03 37.8361 316.6 37.7641 317.174 37.7641C334.121 37.7561 351.067 37.7534 368.012 37.748C367.609 37.8415 367.204 38.0123 366.801 38.0123C350.075 38.0257 333.35 38.0257 316.624 38.0177C316.235 38.0177 315.848 37.9216 315.459 37.8708V37.8735Z"
        fill="currentColor"
      />
      <path
        d="M640.688 37.9329C641.117 37.8768 641.546 37.77 641.975 37.77C652.048 37.762 662.12 37.7594 672.192 37.7754C672.629 37.7754 673.067 37.9596 673.501 38.0557C663.136 38.0477 652.77 38.0397 642.405 38.0236C641.831 38.0236 641.261 37.9649 640.688 37.9329Z"
        fill="currentColor"
      />
      <path
        d="M872.831 61.5314C872.78 60.9682 872.69 60.4049 872.69 59.8416C872.679 55.4208 872.679 51.0026 872.69 46.5818C872.69 46.1066 872.783 45.6314 872.831 45.1562C872.871 47.3133 872.946 49.4676 872.94 51.6246C872.932 54.9269 872.871 58.2292 872.831 61.5314Z"
        fill="currentColor"
      />
      <path
        d="M859.469 45.1523C859.52 45.7156 859.61 46.2789 859.61 46.8422C859.621 51.263 859.621 55.6812 859.61 60.102C859.61 60.5772 859.517 61.0523 859.469 61.5275C859.429 59.3705 859.354 57.2162 859.36 55.0591C859.368 51.7569 859.429 48.4546 859.469 45.1523Z"
        fill="currentColor"
      />
      <path
        d="M155.836 51.0527C155.732 50.7964 155.54 50.5401 155.537 50.2838C155.518 46.0365 155.521 41.7866 155.532 37.5393C155.532 37.3337 155.636 37.1255 155.692 36.9199C155.724 40.8816 155.753 44.8459 155.788 48.8075C155.793 49.555 155.82 50.3052 155.836 51.0527Z"
        fill="currentColor"
      />
      <path
        d="M516.176 37.8917C519.085 37.8463 521.997 37.8009 524.907 37.7609C525.747 37.7475 526.587 37.7475 527.427 37.7422C527.336 37.8249 527.243 37.9798 527.152 37.9798C523.493 37.9584 519.834 37.9237 516.176 37.8917Z"
        fill="currentColor"
      />
      <path
        d="M474.41 38.0518C474.754 37.945 475.095 37.7448 475.437 37.7501C477.144 37.7715 478.848 37.8462 480.554 37.9023C478.506 37.953 476.458 38.001 474.41 38.0518Z"
        fill="currentColor"
      />
      <path
        d="M434.554 69.7632C434.496 67.9799 434.437 66.1993 434.381 64.416C434.44 66.1993 434.498 67.9799 434.554 69.7632Z"
        fill="currentColor"
      />
      <path
        d="M421.343 64.3906C421.289 66.1819 421.236 67.9732 421.185 69.7645C421.143 67.9812 421.097 66.1953 421.055 64.412C421.151 64.404 421.247 64.3986 421.343 64.3906Z"
        fill="currentColor"
      />
      <path
        d="M434.635 37.9378C435.101 37.9244 435.565 37.9111 436.032 37.9004C435.565 37.9137 435.101 37.9271 434.635 37.9378Z"
        fill="currentColor"
      />
      <path
        d="M394.953 51.0398C394.881 50.9597 394.809 50.877 394.734 50.7969C394.806 50.877 394.878 50.9597 394.953 51.0398Z"
        fill="currentColor"
      />
      <path
        d="M195.475 50.7969C195.403 50.877 195.331 50.9597 195.256 51.0398C195.328 50.9597 195.4 50.877 195.475 50.7969Z"
        fill="currentColor"
      />
      <path
        d="M381.391 90.7578C381.468 90.8272 381.543 90.8966 381.62 90.9687C381.543 90.8993 381.468 90.8299 381.391 90.7578Z"
        fill="currentColor"
      />
      <path
        d="M461.104 90.7578C461.181 90.8272 461.256 90.8966 461.333 90.9687C461.256 90.8993 461.181 90.8299 461.104 90.7578Z"
        fill="currentColor"
      />
      <path
        d="M540.814 90.7578C540.892 90.8272 540.966 90.8966 541.044 90.9687C540.966 90.8993 540.892 90.8299 540.814 90.7578Z"
        fill="currentColor"
      />
      <path
        d="M673.578 90.7578C673.655 90.8272 673.73 90.8966 673.807 90.9687C673.73 90.8993 673.655 90.8299 673.578 90.7578Z"
        fill="currentColor"
      />
      <path
        d="M500.974 51.3594C500.907 51.2793 500.841 51.1992 500.771 51.1191C500.838 51.1992 500.905 51.2793 500.974 51.3594Z"
        fill="currentColor"
      />
      <path
        d="M36.9453 98.9961C37.0386 99.0522 37.132 99.1082 37.228 99.1643C37.1347 99.1082 37.0413 99.0522 36.9453 98.9961Z"
        fill="currentColor"
      />
      <path
        d="M569.628 37.9115C567.026 37.9516 564.426 38.0023 561.823 38.0263C559.26 38.0503 556.697 38.0477 554.135 38.0557C554.393 37.9569 554.652 37.7754 554.911 37.7754C559.607 37.7594 564.3 37.762 568.996 37.77C569.207 37.77 569.418 37.8635 569.628 37.9115Z"
        fill="currentColor"
      />
      <path
        d="M274.953 51.0522C275.009 49.0928 275.062 47.1333 275.118 45.1738C275.065 47.1333 275.009 49.0928 274.953 51.0522Z"
        fill="currentColor"
      />
      <path
        d="M235.314 50.7969C235.386 50.877 235.458 50.9597 235.533 51.0398C235.461 50.9597 235.389 50.877 235.314 50.7969Z"
        fill="currentColor"
      />
      <path
        d="M82.9432 25.7864C82.8792 25.725 82.8179 25.6609 82.7539 25.5995C82.8499 25.5648 82.9486 25.5301 83.0446 25.498C83.0099 25.5942 82.9752 25.6903 82.9432 25.789V25.7864Z"
        fill="currentColor"
      />
      <path
        d="M92.079 44.1567C91.9936 44.0873 91.9083 44.0206 91.8203 43.9512C91.9056 44.0206 91.991 44.0873 92.079 44.1567Z"
        fill="currentColor"
      />
    </svg>
  );
};
