export const Score = ({ className }: { className: string }) => {
  return (
    <svg className={className} width="33" height="33" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2339_3247)">
        <path
          d="M2.23828 7.47842V18.5232C2.23828 19.2219 2.60027 19.867 3.18991 20.2164L12.4943 25.7379C13.0824 26.0874 13.808 26.0874 14.396 25.7379L23.7004 20.2164C24.2885 19.867 24.652 19.2219 24.652 18.5232V7.47842C24.652 6.77969 24.2901 6.13458 23.7004 5.78522L14.396 0.262023C13.808 -0.087341 13.0824 -0.087341 12.4943 0.262023L3.18991 5.78522C2.60186 6.13458 2.23828 6.77969 2.23828 7.47842Z"
          fill="url(#paint0_linear_2339_3247)"
          fill-opacity="0.6"
        />
        <path
          d="M3.36133 8.22282V17.7771C3.36133 18.4275 3.70738 19.028 4.27104 19.3532L12.5386 24.1311C13.1007 24.4563 13.7944 24.4563 14.3565 24.1311L22.624 19.3532C23.1862 19.028 23.5337 18.4275 23.5337 17.7771V8.22282C23.5337 7.57245 23.1877 6.972 22.624 6.64681L14.3565 1.86889C13.7944 1.5437 13.1007 1.5437 12.5386 1.86889L4.27104 6.6453C3.70889 6.97048 3.36133 7.57094 3.36133 8.2213V8.22282Z"
          fill="url(#paint1_linear_2339_3247)"
        />
        <path
          d="M13.6394 4.81592V21.185M20.7515 8.90818L6.52734 17.0927M20.7515 17.0927L6.52734 8.90818"
          stroke="#D1D1D3"
          stroke-width="0.896551"
        />
        <path
          d="M12.799 4.65517C13.216 4.40498 13.7304 4.40498 14.1473 4.65517L20.092 8.22213C20.5072 8.47121 20.7627 8.93046 20.7627 9.42732V16.5723C20.7627 17.0692 20.5072 17.5285 20.092 17.7775L14.1473 21.3444C13.7304 21.5947 13.216 21.5947 12.799 21.3444L6.85429 17.7775C6.43916 17.5285 6.18359 17.0692 6.18359 16.5723V9.42732C6.18359 8.93046 6.43916 8.47121 6.85428 8.22213L12.799 4.65517Z"
          stroke="#D1D1D3"
          stroke-width="0.896551"
        />
        <path
          d="M11.0482 10.2461C10.8851 10.2519 10.7559 10.3986 10.7559 10.5765C10.7559 13.2522 10.7771 14.2747 11.3172 15.1123C11.8626 15.9568 12.5424 16.5992 13.5273 17.141C13.6162 17.1907 13.7232 17.1907 13.8121 17.141C14.797 16.5992 15.4768 15.9568 16.0222 15.1123C16.5623 14.2747 16.5834 13.2522 16.5834 10.5765C16.5834 10.3986 16.4542 10.2519 16.2912 10.2461C15.1126 10.2033 12.2247 10.2033 11.0471 10.2461H11.0482Z"
          fill="#40B884"
        />
        <path
          d="M12.1074 13.1685L13.3582 14.2703L15.2532 12.3154"
          stroke="white"
          stroke-width="0.896551"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.55273 9.99565L13.6361 6.98718L18.7194 9.99565V16.0044L13.6361 19.0129L8.55273 16.0044V9.99565Z"
          stroke="#40B884"
          stroke-width="0.896551"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2339_3247"
          x1="13.4459"
          y1="26.0012"
          x2="13.4459"
          y2="0.000452531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5F5F5F" />
          <stop offset="0.99" stop-color="#CBCBCB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2339_3247"
          x1="13.4475"
          y1="24.6395"
          x2="13.4475"
          y2="1.36047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9E9E9" />
          <stop offset="0.630208" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_2339_3247">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
