import { Box, Stack, Typography } from "@mui/material";
import { platform } from "..";
import { GrayLineMobile } from "../../icons/GrayLine";
import { MobileLineGradient } from "../../icons/LineGradient";

export const SmallTablet = ({ getSxActive, active }: { getSxActive: any; active: number }) => {
  return (
    <Stack columnGap="20px" display="flex" flexDirection="column" flexWrap="wrap" alignItems="baseline">
      {platform.map((plat, index) => (
        <Stack
          key={plat.id}
          sx={{
            mt: plat.id === 1 || plat.id === 2 ? "20px" : "30px",
            height: plat.id === 5 ? "450px" : undefined,
          }}
          alignSelf="baseline"
          width="min(100%, 620px)"
        >
          <Typography
            sx={{
              transition: "opacity 4s linear, background-image 0.3s linear, color 0.3s linear",
              animation: "animate 3s linear infinite",
              animationDelay: "3s",
            }}
            color={active === index ? "#F1F1F3" : "#292E41"}
            fontSize="150px"
          >
            {plat.name}
          </Typography>
          {active === index ? <MobileLineGradient /> : <GrayLineMobile />}
          <Stack justifyContent="space-between">
            <Box mt={2} color="#989BA9" fontSize="16px" lineHeight="19px" component="p">
              {plat.description}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={plat.id === 1 ? "center" : "flex-start"}
              textAlign="center"
              mt="26px"
              sx={{
                width: "min(100%, 622px)",
                position: "relative",
                height:
                  plat.id === 5 || plat.id === 1
                    ? "min(100%, 75px)"
                    : plat.id === 3 || plat.id === 4
                      ? "min(100%, 101px)"
                      : "78px",
                borderRadius: "12px",
                padding: "15px",
                mb: plat.id === 5 ? "20px" : null,
                ...getSxActive(index),
              }}
            >
              <Box textAlign={"left"} fontSize="16px" component="div">
                {plat.info}
              </Box>
            </Box>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
