export const ByBit = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="58"
      height="23"
      viewBox="0 0 58 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2344_4004)">
        <path d="M40.9551 17.0073V2.24048H43.9233V17.0073H40.9551Z" fill="#273852" />
        <path
          d="M6.36327 21.3933H0V6.62646H6.10738C9.07562 6.62646 10.8051 8.24415 10.8051 10.7746C10.8051 12.4125 9.69437 13.4711 8.92555 13.8235C9.84324 14.2381 11.0179 15.1712 11.0179 17.1424C11.0179 19.8999 9.07562 21.3933 6.36327 21.3933ZM5.87245 9.19869H2.9682V12.6001H5.87245C7.1321 12.6001 7.83692 11.9155 7.83692 10.8988C7.83692 9.88323 7.1321 9.19869 5.87245 9.19869ZM6.06435 15.1926H2.9682V18.8222H6.06435C7.41004 18.8222 8.04973 17.9931 8.04973 16.9967C8.04973 16.0015 7.40885 15.1926 6.06435 15.1926Z"
          fill="#273852"
        />
        <path
          d="M20.0717 15.3372V21.3933H17.1245V15.3372L12.5547 6.62646H15.7788L18.619 12.5786L21.4163 6.62646H24.6404L20.0717 15.3372Z"
          fill="#273852"
        />
        <path
          d="M33.0527 21.3933H26.6895V6.62646H32.7969C35.7651 6.62646 37.4946 8.24415 37.4946 10.7746C37.4946 12.4125 36.3838 13.4711 35.615 13.8235C36.5327 14.2381 37.7075 15.1712 37.7075 17.1424C37.7075 19.8999 35.7651 21.3933 33.0527 21.3933ZM32.5619 9.19869H29.6577V12.6001H32.5619C33.8216 12.6001 34.5264 11.9155 34.5264 10.8988C34.5264 9.88323 33.8216 9.19869 32.5619 9.19869ZM32.7538 15.1926H29.6577V18.8222H32.7538C34.0995 18.8222 34.7392 17.9931 34.7392 16.9967C34.7392 16.0015 34.0995 15.1926 32.7538 15.1926Z"
          fill="#273852"
        />
        <path d="M53.4909 9.19869V21.3944H50.5227V9.19869H46.5508V6.62646H57.4629V9.19869H53.4909Z" fill="#273852" />
      </g>
      <defs>
        <clipPath id="clip0_2344_4004">
          <rect width="57.4632" height="22.4569" fill="white" transform="translate(0 0.258301)" />
        </clipPath>
      </defs>
    </svg>
  );
};
