import { IconProps } from "./types";

export const SaveIcon = ({ style }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M22 6.44444V19.7778C22 20.3889 21.7826 20.9122 21.3478 21.3478C20.913 21.7833 20.3896 22.0007 19.7778 22H4.22222C3.61111 22 3.08815 21.7826 2.65333 21.3478C2.21852 20.913 2.00074 20.3896 2 19.7778V4.22222C2 3.61111 2.21778 3.08815 2.65333 2.65333C3.08889 2.21852 3.61185 2.00074 4.22222 2H17.5556L22 6.44444ZM19.7778 7.38889L16.6111 4.22222H4.22222V19.7778H19.7778V7.38889ZM12 18.6667C12.9259 18.6667 13.713 18.3426 14.3611 17.6944C15.0093 17.0463 15.3333 16.2593 15.3333 15.3333C15.3333 14.4074 15.0093 13.6204 14.3611 12.9722C13.713 12.3241 12.9259 12 12 12C11.0741 12 10.287 12.3241 9.63889 12.9722C8.99074 13.6204 8.66667 14.4074 8.66667 15.3333C8.66667 16.2593 8.99074 17.0463 9.63889 17.6944C10.287 18.3426 11.0741 18.6667 12 18.6667ZM5.33333 9.77778H15.3333V5.33333H5.33333V9.77778ZM4.22222 7.38889V19.7778V4.22222V7.38889Z"
        fill="currentColor"
      />
    </svg>
  );
};
