import { AppBar, Stack, Toolbar, useTheme } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { LogoIcon } from "~/assets/LogoIcon";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { getJwtInfo } from "~/shared/utils/jwt";
import themePalette from "~/theme/palette";
import SettingsPopover from "./SettingsPopover";
import SwitchNetwork from "./SwitchNetwork";
import Web3Profile from "./Web3Profile";
import { BurgerButton as BurgerMenu } from "./menuDrawer/BurgerButton";
import NavSectionHorizontal from "./nav/NavHorizontal";
import navConfig from "./nav/config-navigation";

export default function HeaderAuth() {
  const isDesktop = useIsDesktop();
  const wallet = useWallet();
  const isAuthorized = !!getJwtInfo() && wallet.isAuthorized;
  const theme = useTheme();
  const isDashboard = window.location.pathname.includes("/dashboard");

  const [palette, setPalette] = useState(() => themePalette("dark"));

  const location = useLocation();

  console.log("location", location.pathname);

  const isRootLoginPage =
    !location.pathname.includes("dashboard/social") &&
    (!navConfig[0].items.some((path) => path.path === location.pathname) || location.pathname.includes("login"));
  const isAboutPage = location.pathname.includes("about");

  useLayoutEffect(() => {
    if (isRootLoginPage) {
      setPalette(themePalette("dark"));
      return;
    }
    setPalette(themePalette(theme.palette.mode));
  }, [isAboutPage, isRootLoginPage, theme.palette.mode]);

  const isInviteCodePage =
    (location.pathname.includes("/about") || location.pathname.includes("/leaderboard")) && !isAuthorized;

  return (
    <AppBar
      position="relative"
      sx={{
        m: 0,
        overflow: "hidden",
        display: "inline-flex",
        boxShadow: "none",
        bgcolor: palette.background.paper,

        zIndex: 9999999,
        ...(isRootLoginPage && {
          // position: "absolute",
          // top: 0,
          // left: 0,
          backgroundColor: "transparent",
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        <Link to={isAuthorized ? "/dashboard" : "/"}>
          <LogoIcon style={{ color: isRootLoginPage ? theme.palette.secondary.main : theme.palette.primary.main }} />
        </Link>

        <NavSectionHorizontal
          sx={{
            display: `${isDesktop ? "flex" : "none"}`,
            pl: 4,
          }}
        />

        <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1 }}>
          <SwitchNetwork />
          {isAuthorized && <Web3Profile />}
          {isDesktop && !isRootLoginPage && <SettingsPopover sx={{ display: `${isDesktop ? "" : "none"}` }} />}
          <BurgerMenu
            sx={{
              color: isRootLoginPage ? "#273852" : palette.text.primary,
            }}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
