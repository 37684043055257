import { DASHBOARD_ROUTE } from "~/shared/const";
import { getJwtInfo } from "~/shared/utils/jwt";

// проверяет есть токен и совпадает ли адрес с подключенным
// если чего-то редиректит на нужную страницу
export const loaderPublic = (login: any["login"]) => async () => {
  const jwt: any = getJwtInfo();

  const searchParams = new URLSearchParams(window.location.search);
  const url = searchParams.get("url") ?? DASHBOARD_ROUTE;

  if (!login || !jwt) return null;
  // if (jwt?.address === login) return redirect(url);

  return null;
};
