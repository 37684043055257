import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { useLogin } from "~/pages/LoginPage/hooks/useLogin";
import themePalette from "~/theme/palette";
import SettingsDrawerButton from "../SettingsDrawer/SettingsDrawerButton";
import { getJwtInfo } from "~/shared/utils/jwt";

export function MobileBottomNavigation() {
  const theme = useTheme();
  const jwtInfo = getJwtInfo();
  const [palette, setPalette] = useState(() => themePalette("dark"));
  const wallet = useWallet();
  const location = useLocation();
  const isRoot = location.pathname === "/" || location.pathname.includes("login");
  const isAbout = location.pathname === "/about";
  const { onClick: onClickLogin, loading: loginLoading } = useLogin();
  const isAuthorized = !!jwtInfo && wallet.isAuthorized;

  
  useLayoutEffect(() => {
    if (isRoot) {
      setPalette(themePalette("dark"));
      return;
    }
    setPalette(themePalette(theme.palette.mode));
    // } else if (!isAuthorized && isAbout) {
    //   setPalette(themePalette("dark"));
    // } else if (theme.palette.mode === "light") {
    //   setPalette(themePalette("light"));
    // } else if (theme.palette.mode === "dark") {
    //   setPalette(themePalette("dark"));
    // }
  }, [isAuthorized, theme, isRoot, isAbout]);

  let showSettingsButton = true;
  if (isRoot) {
    showSettingsButton = false;
  } else if (isAbout) {
    if (isAuthorized) {
      showSettingsButton = true;
    } else {
      showSettingsButton = false;
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: palette.background.default,
          padding: "20px",
          display: isRoot ? "none" : "flex",
          gap: "20px",
          flexDirection: "row-reverse",
          zIndex: 99999999999999,
        }}
      >
        {showSettingsButton && <SettingsDrawerButton />}
        {!isAuthorized && (
          <LoadingButton
            loading={loginLoading}
            variant="contained"
            onClick={onClickLogin}
            sx={{
              px: 3,
              py: 1,
              fontWeight: 400,
            }}
          >
            Connect wallet
          </LoadingButton>
        )}
      </Box>
    </>
  );
}
