import { Box, useTheme } from "@mui/material";
import { useMemo } from "react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { NextButton } from "~/assets/NextButton";
import { PrevButton } from "~/assets/PrevButton";
import { BenzingaIcon } from "~/assets/benzinga";
import { CmcIcon } from "~/assets/cmc";
import { DecryptIcon } from "~/assets/decrypt";
import { HackerNoonIcon } from "~/assets/hackernoon";
import palette from "~/theme/palette";
import s from "./slider.module.css";

interface CarouselProps {
  palette: ReturnType<typeof palette>;
}

export const Carousel = () => {
  // export const Carousel = ({ palette }: CarouselProps) => {
  const theme = useTheme();
  const BASE_MEDIA = [
    {
      id: 1,
      text: "“UBD Network mitigates the risks associated with human factors and centralized platforms providing users with a secure and transparent environment for managing their digital assets with no middleman involved.”",
      icon: (
        <BenzingaIcon
          style={{
            color: theme.palette.text.primary,
          }}
        />
      ),
      url: "https://www.benzinga.com/markets/cryptocurrency/23/07/33185637/UBDNetwork-Transforming-Crypto-Industry-with-Decentralization-and-innovation#",
    },
    {
      id: 2,
      text: "“The pioneering features allow users to seamlessly manage their digital wealth without worries of losing access to personal funds, since everything is managed according to the algorithm that the user creates.”",
      icon: (
        <CmcIcon
          style={{
            color: theme.palette.text.primary,
          }}
        />
      ),
      url: "https://coinmarketcap.com/community/articles/64ad55cd0403e82ea3d38663/",
    },
    {
      id: 3,
      text: "“UBDN makes it easier than ever for crypto holders to secure their digital assets and to earn on them. With just a couple of clicks, they can access their Trust anytime, anywhere in the world with an internet connection.”",
      icon: (
        <DecryptIcon
          style={{
            color: theme.palette.text.primary,
          }}
        />
      ),
      url: "https://decrypt.co/148066/ubd-network-protects-your-crypto-and-provides-staking-payouts-in-stablecoins",
    },
    {
      id: 4,
      text: "“UBDN will be the first to provide a game-changing solution and easy-to-use tool dedicated to digital asset management.”",
      icon: (
        <HackerNoonIcon
          style={{
            color: theme.palette.text.primary,
          }}
        />
      ),
      url: "https://app.hackernoon.com/mobile/6JY1SDKb51kL0fPtquqN",
    },
  ];
  const media = useMemo(() => [...BASE_MEDIA, ...BASE_MEDIA], [theme.palette.mode]);

  const carousel = ({ swiper, on }: { swiper: any; on: (event: string, handler: any) => void }) => {
    on("beforeInit", () => {
      if ("carousel" !== swiper.params.effect) return;
      (swiper as any).classNames.push(`${swiper.params.containerModifierClass}carousel`);
      const r = {
        watchSlidesProgress: !0,
        centeredSlides: !0,
      };
      Object.assign(swiper.params, r), Object.assign(swiper.originalParams, r);
    });

    on("progress", () => {
      if ("carousel" !== swiper.params.effect) return;
      const r = swiper.slides.length;
      for (let s = 0; s < swiper.slides.length; s += 1) {
        const t = swiper.slides[s],
          o = (swiper.slides[s] as any).progress,
          i = Math.abs(o);
        let a = 1;
        i > 1 && (a = 0.3 * (i - 1) + 1);
        const n = t.querySelectorAll(".swiper-carousel-animate-opacity"),
          l = o * a * 50 * (swiper.rtlTranslate ? -1 : 1) + "%",
          c = 1 - 0.2 * i,
          u = r - Math.abs(Math.round(o));
        (t.style.transform = `translateX(${l}) scale(${c})`),
          (t.style.zIndex = u.toString()),
          (t.style.opacity = (i > 3 ? 0 : 1).toString()),
          n.forEach((e: any) => {
            (e as any).style.opacity = 1 - i / 3;
          });
      }
    });

    on("setTransition", (_: any, s: any) => {
      if ("carousel" === swiper.params.effect)
        for (let t = 0; t < swiper.slides.length; t += 1) {
          const r = swiper.slides[t],
            o = r.querySelectorAll(".swiper-carousel-animate-opacity");
          (r.style.transitionDuration = `${s}ms`),
            o.forEach((e: any) => {
              (e as any).style.transitionDuration = `${s}ms`;
            });
        }
    });
  };

  return (
    <Swiper
      effect={"carousel"}
      grabCursor={false}
      loop={true}
      slidesPerView={"auto"}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      autoplay={{
        delay: 1,
        disableOnInteraction: true,
      }}
      // Swiper types are not up to date
      modules={[Navigation, carousel] as any[]}
      className={s.swiper}
    >
      {media.map((item, index) => (
        <SwiperSlide
          className={s.slide}
          key={index}
          onClick={() => window.open(item.url, "_blank")}
          style={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <div
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {item.text}
          </div>
          <Box width={"60%"}>{item.icon}</Box>
        </SwiperSlide>
      ))}
      <div className={`${s.btn} swiper-button-next ${s.swiperButtonPrev}`}>
        <NextButton />
      </div>

      <div className={`${s.btn} swiper-button-prev ${s.swiperButtonNext}`}>
        <PrevButton />
      </div>
    </Swiper>
  );
};
