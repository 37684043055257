import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { USDTIcon } from "~/assets/USDTIcon";
import { UbdIcon } from "~/assets/UbdIcon";
import { UbdnIcon } from "~/assets/UbdnIcon";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { useClaimUbdn } from "./hooks/useClaimUbdn";
import { useRequest } from "./hooks/useRequest";
import { useStats } from "./hooks/useStats";

const UBDNCard = ({ balance }: { balance: string }) => {
  const theme = useTheme();
  const { claimTokens, loading } = useClaimUbdn(Number(balance));

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <CardContent>
        <Stack gap={2} width="100%" flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} width="100%">
            <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
              <UbdnIcon style={{ width: "2rem" }} />
              <Typography variant="h6">UBDN</Typography>
            </Stack>
          </Stack>
          <Divider sx={{ width: "100%" }} />
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              py: 1.5,
              px: 3,
              justifyContent: "center",
              alignItems: "center",
              //backgroundColor: theme.palette.mode == "dark" ? "#1B2030" : "#F4F6F8",
              background: "linear-gradient(270.02deg, rgba(64, 255, 163, 0) -26.96%, rgba(64, 255, 163, 0.1) 99.98%)",
              boxShadow: "none",
              width: "100%",
              borderRadius: "32px",
            }}
          >
            <Typography variant="body2" color={theme.palette.text.primary}>
              <span style={{ fontWeight: 700 }}>{balance}</span>
            </Typography>
            <Typography variant="body2">to claim</Typography>
          </Card>
          <LoadingButton
            onClick={claimTokens}
            loading={loading}
            variant="contained"
            sx={{ mt: 1, width: "max-content" }}
            disabled={Number(balance) <= 0}
          >
            Claim
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ClaimStats = () => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const stats = useStats();
  const { loading, mutate } = useRequest(Number(stats.UBD), Number(stats.USDT));

  return (
    <Grid
      container
      direction={isDesktop ? "row" : "column"}
      spacing={2}
      width={isDesktop ? "100%" : undefined}
      sx={{ mb: 4 }}
    >
      <Grid item xs={4}>
        <UBDNCard balance={stats.UBDN} />
      </Grid>
      <Grid item xs={8}>
        <Card
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              borderRadius: "32px",
            }}
          >
            <Grid container direction={isDesktop ? "row" : "column"} spacing={2} width={isDesktop ? "100%" : undefined}>
              <Grid item xs={6}>
                <Stack gap={2} width={isDesktop ? "100%" : undefined}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                      <UbdIcon style={{ width: "2rem" }} />
                      <Typography variant="h6">UBD</Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: 1.5,
                      px: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        "linear-gradient(270.02deg, rgba(64, 255, 163, 0) -26.96%, rgba(64, 255, 163, 0.1) 99.98%)",
                      boxShadow: "none",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography variant="body2" color={theme.palette.text.primary}>
                      <span style={{ fontWeight: 700 }}>{stats.UBD}</span>
                    </Typography>
                    <Typography variant="body2">to claim</Typography>
                  </Card>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack gap={2}>
                  <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                      <USDTIcon style={{ width: "2rem" }} />
                      <Typography variant="h6">USDT</Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      py: 1.5,
                      px: 3,
                      justifyContent: "center",
                      alignItems: "center",
                      background:
                        "linear-gradient(270.02deg, rgba(64, 255, 163, 0) -26.96%, rgba(64, 255, 163, 0.1) 99.98%)",
                      boxShadow: "none",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography variant="body2" color={theme.palette.text.primary}>
                      <span style={{ fontWeight: 700 }}>{stats.USDT}</span>
                    </Typography>
                    <Typography variant="body2">to claim</Typography>
                  </Card>
                </Stack>
              </Grid>
            </Grid>

            <LoadingButton
              variant="contained"
              sx={{ mt: 4, width: "max-content" }}
              disabled={Number(stats.UBD) <= 0 && Number(stats.USDT) <= 0}
              onClick={mutate as any}
              loading={loading}
            >
              Create claim request
            </LoadingButton>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
