export const Wallet = () => {
  return (
    <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.7461 18.4546H15.7461C15.1494 18.4546 14.5771 18.2175 14.1551 17.7956C13.7331 17.3736 13.4961 16.8013 13.4961 16.2046C13.4961 15.6079 13.7331 15.0356 14.1551 14.6136C14.5771 14.1916 15.1494 13.9546 15.7461 13.9546H53.9961C54.5928 13.9546 55.1651 13.7175 55.5871 13.2956C56.009 12.8736 56.2461 12.3013 56.2461 11.7046C56.2461 11.1079 56.009 10.5356 55.5871 10.1136C55.1651 9.69164 54.5928 9.45459 53.9961 9.45459H15.7461C13.9559 9.45459 12.239 10.1657 10.9731 11.4316C9.70725 12.6975 8.99609 14.4144 8.99609 16.2046V52.2046C8.99609 53.9948 9.70725 55.7117 10.9731 56.9776C12.239 58.2434 13.9559 58.9546 15.7461 58.9546H60.7461C61.9396 58.9546 63.0842 58.4805 63.9281 57.6366C64.772 56.7927 65.2461 55.6481 65.2461 54.4546V22.9546C65.2461 21.7611 64.772 20.6165 63.9281 19.7726C63.0842 18.9287 61.9396 18.4546 60.7461 18.4546ZM60.7461 54.4546H15.7461C15.1494 54.4546 14.5771 54.2175 14.1551 53.7956C13.7331 53.3736 13.4961 52.8013 13.4961 52.2046V22.5693C14.2185 22.8256 14.9796 22.9559 15.7461 22.9546H60.7461V54.4546ZM47.2461 37.5796C47.2461 36.9121 47.444 36.2596 47.8149 35.7045C48.1857 35.1495 48.7128 34.7169 49.3295 34.4615C49.9462 34.2061 50.6248 34.1392 51.2795 34.2694C51.9342 34.3997 52.5356 34.7211 53.0076 35.1931C53.4796 35.6651 53.801 36.2665 53.9312 36.9212C54.0615 37.5758 53.9946 38.2544 53.7392 38.8711C53.4837 39.4878 53.0512 40.0149 52.4961 40.3858C51.9411 40.7566 51.2886 40.9546 50.6211 40.9546C49.726 40.9546 48.8675 40.599 48.2346 39.9661C47.6017 39.3331 47.2461 38.4747 47.2461 37.5796Z"
        fill="#5BFCBB"
      />
    </svg>
  );
};
