import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Link } from "react-router-dom";

export const ReferralFAQPage = () => {
  return (
    <Stack gap={2}>
      <Typography variant="h4" textTransform={"uppercase"}>
        Referral F.A.Q
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          How much will I earn?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            It does highly depend on your activity, but we can ensure 10% reward in USDT on each swap made by your
            referrals. In addition, 2nd referral level purchases grant you 5%. So there is a two-level earning program
            to snowball your income.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
          Where can I find my referral link?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            After sign up with your special invite code you can use default referral links or create the new ones{" "}
            <Link to={"/referral"}>here.</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          How can I know I am paid with the correct reward tier?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Blockchain is gamechanger. We determine the size of rewards by pulling information directly from smart
            contracts You can check all the transactions in Ethereum blockchain ( https://etherscan.io) and compare the
            rewards that you see in the system with those that take place on-chain.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          When will I receive referral rewards?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Most of the main rewards received in the system are available for withdrawal immediately through a smart
            contract claiming. Some additional rewards are processed manually to ensure the safety of all parties, but
            are also made no later than 3 days from the date of receipt of the withdrawal request.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          Will the referral expire?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Yes, but even earlier you will have time to buy a lambo and a mansion on the islands. Because referral
            payments from an attracted client occur throughout 1 year.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
          Is there a limit to how many rewards I can get?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            No, you can refer as many friends as you like and you will get 10% on 1st level and 5% on 2nd level UBDN
            token purchases.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
