import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Stack, useMediaQuery } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useLayoutEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { DiscordIcon } from "~/assets/DiscordIcon";
import { LogoFullIcon } from "~/assets/LogoFullIcon";
import { TelegramIcon } from "~/assets/TelegramIcon";
import { XIcon } from "~/assets/XIconProps";
import Iconify from "~/components/iconify";
import Scrollbar from "~/components/scrollbar/Scrollbar";
import { useLogin } from "~/pages/LoginPage/hooks/useLogin";
import { useLoginFromTg } from "~/pages/LoginPage/hooks/useSignInFromTg";
import themePalette from "~/theme/palette";
import { bgBlur } from "~/utils/cssStyles";
import { utmParser } from "~/utils/utmParser";
import { Container } from "../Container/Container";
import { Footer } from "../Footer/Footer";
import NavVertical from "../HeaderAuth/nav/NavVertical";
import navConfig from "../HeaderAuth/nav/config-navigation";
import { Menu } from "./Menu";

const HeaderNoAuth = () => {
  const media = useMediaQuery("(max-width:1024px)");
  const ipad = useMediaQuery("(max-width:668px)");
  const { id } = useParams();
  const [palette, setPalette] = useState(() => themePalette("dark"));
  const { onClick: onClickLogin, loading: loginLoading } = useLogin();
  const { signInTg } = useLoginFromTg();
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  if (tokenParam) {
    signInTg(tokenParam);
  }

  utmParser();

  const location = useLocation();
  const isRoot = location.pathname === "/" || location.pathname.includes("login");
  const isAbout = location.pathname === "/about";

  const loginUrl = id ? `/${id}/login` : "/login";
  const isRootLoginPage =
    !navConfig[0].items.some((path) => path.path === location.pathname) || location.pathname.includes("login");

  useLayoutEffect(() => {
    if (isRoot) {
      setPalette(themePalette("dark"));
      return;
    }
    if (isAbout) {
      setPalette(themePalette("light"));
      return;
    }
  }, [isRoot, isAbout]);

  return (
    <Box component="div" sx={{ position: "relative", zIndex: 10, width: "100%", padding: "15px 0" }}>
      <Container>
        <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
          <Stack flexDirection="row" alignItems="center">
            <Link to="/">
              <LogoFullIcon
                style={{
                  color: palette.background.paper,
                  height: "44px",
                }}
              />
            </Link>
            <Link
              style={{ display: media ? "none" : "block", margin: "0 60px 0 45px", color: "#273852" }}
              to="/leaderboard"
            >
              Leaderboard
            </Link>
          </Stack>
          {open && (
            <Drawer
              anchor="bottom"
              open={open}
              onClose={() => setOpen(false)}
              BackdropProps={{ invisible: true }}
              PaperProps={{
                sx: {
                  ...bgBlur({
                    color: theme.palette.background.default,
                    opacity: 0.9,
                  }),
                  width: "100%",
                  height: "100%",
                  boxShadow: `-24px 12px 40px 0 ${alpha(
                    theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.common.black,
                    0.16,
                  )}`,
                  ...(isRootLoginPage && { bgcolor: "#292E41" }),
                },
              }}
            >
              <Stack
                direction="row-reverse"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 2, pr: 1, pl: 2 }}
              >
                <IconButton onClick={() => setOpen(false)}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Stack>
              <Scrollbar sx={{ p: 2, pb: 0 }}>
                <NavVertical />
              </Scrollbar>
              <Box
                sx={{
                  p: 2,
                  pt: 2,
                  mt: "auto",
                }}
              >
                <Footer />
              </Box>
            </Drawer>
          )}
          <Stack flexDirection="row" alignItems="center">
            <Stack direction="row" gap="26px" mr="32px">
              <Link
                style={{ display: media ? "none" : "block" }}
                to="https://discord.com/invite/EAkXDyjXTb"
                target="_blank"
                rel="noopener"
              >
                <DiscordIcon style={{ height: "16px", color: "#273852" }} />
              </Link>

              <Link
                style={{ display: media ? "none" : "block" }}
                target="_blank"
                rel="noopener"
                to="https://twitter.com/ubdnet"
              >
                <XIcon style={{ height: "16px", color: "#273852" }} />
              </Link>

              <Link
                style={{ display: media ? "none" : "block" }}
                to="https://t.me/ubdnet"
                target="_blank"
                rel="noopener"
              >
                <TelegramIcon style={{ margin: "0", height: "16px", color: "#273852" }} />
              </Link>
            </Stack>

            <LoadingButton
              loading={loginLoading}
              variant="outlined"
              color="secondary"
              onClick={onClickLogin}
              size="medium"
              sx={{
                width: ipad ? "94px" : "184px",
                fontSize: 14,
                fontWeight: 400,
                mr: media ? "12px" : "",
                "&:hover": {
                  backgroundColor: palette.background.paper,
                },
              }}
            >
              Sign in
            </LoadingButton>
            {media && <Menu open={open} onClick={setOpen} />}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeaderNoAuth;
