import { MenuDrawer } from "./MenuDrawer";

type Props = {
  children: React.ReactNode;
};

export default function Menu({ children }: Props) {
  return (
    <>
      {children}
      <MenuDrawer />
    </>
  );
}
