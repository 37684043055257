import { Link, useLoaderData, useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { SOCIAL_ROUTE } from "~/shared/const";
import { instance } from "~/shared/lib";
import { Button } from "~/shared/ui";

const TwitterCallback = () => {
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const data = useLoaderData() as any;
  const { enqueueSnackbar } = useSnackbar();
  const { refetchAuth } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Twitter callback");
    const { code, state } = data;
    if (!code) return;
    const fetchTwitterCallback = async () => {
      try {
        if (!code || !state) throw new Error("");
        const response: any = await instance.post(`/socials/twitter/callback`, {
          state: state,
          code: code,
        });
        if (response.data.ok) {
          enqueueSnackbar("Authentication was successful", { variant: "success" });
          refetchAuth();
          navigate(SOCIAL_ROUTE);
          return;
        }
      } catch (error) {
        enqueueSnackbar("Authentication failed", { variant: "error" });
      }
    };
    fetchTwitterCallback();
  }, []);

  return (
    <main className="main">
      <div className="container">
        <div className="subtitle-margin">
          <p className="body1 subtitle-margin">{message}</p>
          {!!link && (
            <Button component="a" variant="containe_light" href={link}>
              Click the button and join us
            </Button>
          )}
        </div>
        <Link to={SOCIAL_ROUTE}>
          <Button variant="outline">Back</Button>
        </Link>
      </div>
    </main>
  );
};

export default TwitterCallback;
