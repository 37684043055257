export const Group = () => {
  return (
    <svg width="82" height="73" viewBox="0 0 82 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.8277 26.4488L26.9773 25.0226L25.7476 24.2847C24.0506 23.2665 22.0909 22.7041 20 22.7041C17.0163 22.7041 14.1548 23.8894 12.0451 25.9991C9.93526 28.1089 8.75 30.9704 8.75 33.9541C8.75 36.9378 9.93526 39.7993 12.045 41.9091C14.1548 44.0188 17.0163 45.2041 20 45.2041C20.1896 45.2041 20.3781 45.1994 20.5654 45.1901L18.0538 45.5237C13.9817 46.0645 10.3059 47.2792 7.5795 49.0294C4.90623 50.7456 2.75 53.2645 2.75 56.4541V60.9541V63.2041H5H15.5H17.75V60.9541V55.7041C17.75 53.1318 18.65 50.8416 20.0495 49.2286L23.9427 44.7416L22.404 44.9459C25.7127 44.2232 28.4614 42.0406 29.9756 39.1335L30.6413 37.8553L29.7586 36.7161C27.5274 33.8363 26.4393 30.152 26.8277 26.4488ZM52.2408 36.7168L51.359 37.8559L52.0244 39.1335C53.5386 42.0406 56.2873 44.2232 59.596 44.9459L58.0573 44.7416L61.9505 49.2286C63.35 50.8416 64.25 53.1318 64.25 55.7041V60.9541V63.2041H66.5H77H79.25V60.9541V56.4541C79.25 53.2645 77.0938 50.7456 74.4205 49.0294C71.6941 47.2792 68.0183 46.0645 63.9462 45.5237L61.4346 45.1901C61.6219 45.1994 61.8103 45.2041 62 45.2041C64.9837 45.2041 67.8452 44.0188 69.9549 41.9091C72.0647 39.7993 73.25 36.9378 73.25 33.9541C73.25 30.9704 72.0647 28.1089 69.955 25.9992C67.8452 23.8894 64.9837 22.7041 62 22.7041C59.9091 22.7041 57.9494 23.2665 56.2524 24.2847L55.0249 25.0212L55.1719 26.4451C55.551 30.1184 54.5014 33.7967 52.2408 36.7168ZM60.5 63.2041H62.75V60.9541V55.7041C62.75 51.454 59.7814 48.0336 55.9133 45.8015C51.9853 43.5349 46.7078 42.2041 41 42.2041C35.2922 42.2041 30.0147 43.5349 26.0867 45.8015C22.2186 48.0336 19.25 51.454 19.25 55.7041V60.9541V63.2041H21.5H60.5ZM50.0156 18.9385C47.6245 16.5474 44.3815 15.2041 41 15.2041C37.6185 15.2041 34.3755 16.5474 31.9844 18.9385C29.5933 21.3296 28.25 24.5726 28.25 27.9541C28.25 31.3356 29.5933 34.5786 31.9844 36.9697C34.3755 39.3608 37.6185 40.7041 41 40.7041C44.3815 40.7041 47.6245 39.3608 50.0156 36.9697C52.4067 34.5786 53.75 31.3356 53.75 27.9541C53.75 24.5726 52.4067 21.3296 50.0156 18.9385Z"
        stroke="#5BFCBB"
        stroke-width="4.5"
      />
    </svg>
  );
};
