import { redirect } from "react-router-dom";

import { LOGIN_ROUTE } from "~/shared/const";
import { instance } from "~/shared/lib";
import { getJwtInfo } from "~/shared/utils/jwt";

// Отправляет запрос подключен ли пользователь, если есть токен и совпадает кошелек с подключеным
// В случае чего редиректит на логин
export const loaderPrivate = (newUrl: string, login: any["login"]) => async () => {
  const jwt: any = getJwtInfo();
  const url = LOGIN_ROUTE + "?url=" + window.location.pathname;

  if (newUrl.includes("dashboard/social")) return null;
  if (!login || !jwt) return redirect(url);

  if (login && jwt && jwt?.address === login) {
    const meResponse = await instance.get("auth/me");
    const isAuthorized = meResponse.data.isAuthorized;

    return isAuthorized ? null : redirect(url);
  }

  return redirect(url);
};
