interface DiscordIconProps {
  style?: React.CSSProperties;
}

export const DiscordIcon = ({ style }: DiscordIconProps) => {
  return (
    <svg
      viewBox="0 0 28 21"
      preserveAspectRatio="xMidYMid meet"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6915 1.74523C21.9182 0.931691 19.9981 0.341221 17.998 6.03568e-05C17.9805 -0.00049154 17.9631 0.00274697 17.9469 0.00954824C17.9308 0.0163495 17.9164 0.0265484 17.9047 0.0394253C17.6647 0.472437 17.3847 1.03666 17.198 1.46968C15.0766 1.15476 12.9192 1.15476 10.7978 1.46968C10.6112 1.02354 10.3312 0.472437 10.0778 0.0394253C10.0645 0.0131822 10.0245 6.03568e-05 9.98449 6.03568e-05C7.98444 0.341221 6.07772 0.931691 4.291 1.74523C4.27767 1.74523 4.26434 1.75835 4.251 1.77147C0.624236 7.11195 -0.375792 12.3081 0.117555 17.4517C0.117555 17.478 0.130889 17.5042 0.157556 17.5174C2.55762 19.2494 4.86435 20.2991 7.14441 20.9946C7.18442 21.0077 7.22442 20.9946 7.23775 20.9683C7.7711 20.2466 8.25111 19.4856 8.66446 18.6852C8.69112 18.6327 8.66446 18.5802 8.61112 18.5671C7.8511 18.2784 7.13108 17.9372 6.4244 17.5436C6.37106 17.5174 6.37106 17.4386 6.41106 17.3993C6.55773 17.2943 6.7044 17.1762 6.85107 17.0712C6.87774 17.045 6.91774 17.045 6.94441 17.0581C11.5312 19.1182 16.478 19.1182 21.0115 17.0581C21.0381 17.045 21.0781 17.045 21.1048 17.0712C21.2515 17.1893 21.3981 17.2943 21.5448 17.4124C21.5981 17.4517 21.5981 17.5305 21.5315 17.5567C20.8381 17.9635 20.1048 18.2915 19.3447 18.5802C19.2914 18.5933 19.2781 18.6589 19.2914 18.6983C19.7181 19.4987 20.1981 20.2598 20.7181 20.9814C20.7581 20.9946 20.7981 21.0077 20.8381 20.9946C23.1315 20.2991 25.4382 19.2494 27.8383 17.5174C27.865 17.5042 27.8783 17.478 27.8783 17.4517C28.465 11.5077 26.905 6.3509 23.7449 1.77147C23.7315 1.75835 23.7182 1.74523 23.6915 1.74523ZM9.35781 14.3157C7.98444 14.3157 6.83774 13.0691 6.83774 11.5339C6.83774 9.99869 7.95777 8.75214 9.35781 8.75214C10.7712 8.75214 11.8912 10.0118 11.8779 11.5339C11.8779 13.0691 10.7578 14.3157 9.35781 14.3157ZM18.6514 14.3157C17.278 14.3157 16.1313 13.0691 16.1313 11.5339C16.1313 9.99869 17.2514 8.75214 18.6514 8.75214C20.0648 8.75214 21.1848 10.0118 21.1715 11.5339C21.1715 13.0691 20.0648 14.3157 18.6514 14.3157Z"
        fill="currentColor"
      />
    </svg>
  );
};
