import {
  Card,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAccount } from "wagmi";
import { AuthInfo } from "~/app/ubdn-app/hooks/useJwtAuth";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { TableHeadCustom, TablePaginationCustom, useTable } from "~/components/table";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";
import { getJwtInfo } from "~/shared/utils/jwt";
import { trimAddress } from "~/shared/utils/string";

const ROWS_PER_PAGE = 25;

interface LeaderboardUser {
  address: string;
  points: number;
  position: number;
  inviterAddress: string;
}

interface LeaderboardMe {
  position: number;
  inviterAddress: string;
  user: AuthInfo;
}

const getLeaders = async () => {
  const { data } = await instance.get<InstanceResponse<LeaderboardUser[]>>("/leaderboard/all");
  return data.details;
};

const getPositionLeaders = async () => {
  const { data } = await instance.get<InstanceResponse<LeaderboardMe>>("/leaderboard/me");
  return data.details;
};

const getTotalLeadersCount = async () => {
  const { data } = await instance.get<InstanceResponse<number>>("/leaderboard/total");
  return data.details;
};

export default function LeaderboardPage() {
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({ defaultRowsPerPage: ROWS_PER_PAGE });
  const isDesktop = useIsDesktop();
  const isAuthorized = !!getJwtInfo() && useWallet().isAuthorized;
  const { address } = useAccount();

  const theme = useTheme();
  const { data: dataLeaderList, isLoading: loadingLeaderList } = useQuery({
    queryKey: ["leaderList"],
    queryFn: getLeaders,
  });

  const { data: dataLeaderMe, isLoading: loadingPosition } = useQuery({
    queryKey: ["leaderPosition"],
    queryFn: getPositionLeaders,
    enabled: !!isAuthorized,
  });

  const { data: dataLeadersTotal } = useQuery({
    queryKey: ["dataLeadersTotal"],
    queryFn: getTotalLeadersCount,
    enabled: !!isAuthorized,
  });

  const getLeaderList = () => {
    var sliced = dataLeaderList!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const newData = sliced.filter(
      (leader) => leader.address.slice(0, 5).toLowerCase() !== address?.slice(0, 5).toLowerCase(),
    );
    console.log("newData", newData);
    return newData;
  };

  const resolveMyPoints = () => {
    if (dataLeaderMe && dataLeaderMe.user) return dataLeaderMe?.user?.points ? dataLeaderMe?.user?.points : 0;
    else return 0;
  };

  return (
    <Stack gap={2} pt={2}>
      <Stack
        flexDirection={isDesktop ? "row" : "column"}
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        pb={2}
      >
        <Typography variant="h4">LEADERBOARD</Typography>
        {isAuthorized && (
          <Stack flexDirection={isDesktop ? "row" : "column"} gap={2}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                py: 1.5,
                px: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Your total balance:
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>{Math.ceil(resolveMyPoints())}</span> POINTS
              </Typography>
            </Card>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                py: 1.5,
                px: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Your rank:
              </Typography>
              <Typography variant="body2">
                <span style={{ fontWeight: 700 }}>
                  #{(dataLeaderMe?.position || 0) + 1} / {dataLeadersTotal}
                </span>
              </Typography>
            </Card>
          </Stack>
        )}
      </Stack>

      <Card
        sx={{
          minWidth: "100%",
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {(loadingLeaderList || loadingPosition) && <CircularProgress />}
        {!loadingLeaderList && !loadingPosition && dataLeaderList!.length > 0 && (
          <Table>
            <TableContainer>
              <Table>
                <TableHeadCustom
                  headLabel={[
                    { id: "Rank", label: "Rank" },
                    { id: "Address", label: "Address" },
                    { id: "Invited by", label: "Invited by" },
                    { id: "Points", label: "Points", width: "20%" },
                  ]}
                />

                <TableBody>
                  {isAuthorized && dataLeaderMe && (
                    <TableRow
                      key={dataLeaderMe.position}
                      sx={{
                        background:
                          theme.palette.mode === "light" ? theme.palette.primary.lighter : theme.palette.primary.darker,
                        transition: "all 0.3s ease-in-out",
                        borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
                        "&:hover": {
                          background:
                            theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.primary.dark,
                        },
                      }}
                    >
                      <TableCell>#{dataLeaderMe.position + 1}</TableCell>
                      <TableCell>You</TableCell>

                      <TableCell>
                        {/* {meInLeaderBoard?.inviter?.address ? trimAddress(meInLeaderBoard?.inviter?.address) : "-"} */}
                        {trimAddress(dataLeaderMe.inviterAddress, true)}
                      </TableCell>
                      <TableCell>{Math.ceil(resolveMyPoints())}</TableCell>
                    </TableRow>
                  )}
                  {getLeaderList().map((leaderData, index: number) => (
                    <TableRow
                      key={leaderData.position}
                      sx={{
                        // ...(dataLeaderMe &&
                        //   index === dataLeaderMe.position && {
                        //     background:
                        //       theme.palette.mode === "light"
                        //         ? theme.palette.primary.lighter
                        //         : theme.palette.primary.darker,
                        //   }),
                        transition: "all 0.3s ease-in-out",
                        ...(index !==
                          dataLeaderList!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length - 1 && {
                          borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
                        }),
                        "&:hover": {
                          background: theme.palette.mode == "light" ? "#DEDEDE" : theme.palette.background.default,
                          // ...(dataLeaderMe &&
                          //   index === dataLeaderMe.position && {
                          //     background:
                          //       theme.palette.mode === "light"
                          //         ? theme.palette.primary.lighter
                          //         : theme.palette.primary.darker,
                          //   }),
                        },
                      }}
                    >
                      <TableCell>#{leaderData.position}</TableCell>
                      {/* {isAuthorized && dataLeaderMe && index === dataLeaderMe.position && <TableCell>You</TableCell>} */}
                      {isAuthorized && dataLeaderMe && <TableCell>{leaderData.address}</TableCell>}
                      {!isAuthorized && <TableCell>{leaderData.address}</TableCell>}
                      <TableCell>{leaderData?.inviterAddress}</TableCell>
                      <TableCell>{Number(leaderData?.points ?? 0).toFixed(0)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={dataLeaderList!.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Table>
        )}
      </Card>
    </Stack>
  );
}
