import autoAnimate from "@formkit/auto-animate";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Link as MuiLink,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReactElement, useEffect, useRef, useState } from "react";
import { formatUnits } from "viem";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { DiscordIcon } from "~/assets/DiscordIcon";
import { PointsIcon } from "~/assets/PointsIcon";
import { SpinsIcon } from "~/assets/SpinsIcon";
import { TelegramIcon } from "~/assets/TelegramIcon";
import { USDTIcon } from "~/assets/USDTIcon";
import { UbdIcon } from "~/assets/UbdIcon";
import { UbdnIcon } from "~/assets/UbdnIcon";
import { XIcon } from "~/assets/XIconProps";

interface DashboardCardProps {
  balance: string | ReactElement;
  currency: string;
  icon: React.ReactElement;
  buttonText: string;
  buttonLink: string;
  text: string | ReactElement;
}

const DashboardCard = ({ balance, currency, icon, buttonLink, buttonText, text }: DashboardCardProps) => {
  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <CardContent>
        <Stack gap={2}>
          <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
              {icon}
              <Typography variant="h6">{currency}</Typography>
            </Stack>

            <Typography variant="h6">{balance}</Typography>
          </Stack>
          <Divider />
          <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="body2" pr="32px">
              {text}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default function DashboardPage() {
  const [showHelper, setShowHelper] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const { balances, authInfo } = useWallet();
  const theme = useTheme();
  const { isAuthorized, ubdnLocked } = useWallet();

  useEffect(() => {
    if (boxRef.current) {
      autoAnimate(boxRef.current);
    }
  }, [boxRef]);

  let ubdnTotalBalance = ubdnLocked;
  if (balances && balances.ubdnBalance) ubdnTotalBalance += balances.ubdnBalance.value;

  //Number(((isAuthorized && balances?.ubdnBalance?.formatted) || "0") + ubdnLocked).toString()

  return (
    <Stack pt={2}>
      <Stack flexDirection={"row"} gap={2} alignItems={"center"} pb={2}>
        <Typography variant="h4" textTransform={"uppercase"}>
          Dashboard
        </Typography>
        <IconButton sx={{ padding: 0 }} onClick={() => setShowHelper((value) => !value)}>
          <InfoIcon color="info" />
        </IconButton>
      </Stack>
      <Stack>
        <Box ref={boxRef} pb={3}>
          {showHelper && (
            <Alert
              severity="info"
              onClose={() => setShowHelper(false)}
              icon={false}
              componentsProps={{
                closeButton: {
                  sx: {
                    scale: "0.8",
                  },
                },
              }}
              sx={{
                width: "fit-content",
              }}
            >
              <Typography variant="body2">
                Here you can manage your activities and get rewards. The tasks are simple:
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListItem sx={{ display: "list-item", p: 0, pl: "1rem" }}>
                  Connect your socials to collect all points available
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0, pl: "1rem" }}>
                  Swap UBDN to get points and spins with extra bonuses
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0, pl: "1rem" }}>
                  Invite your friends to earn USDT and points for their activities
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0, pl: "1rem" }}>
                  Hold UBDN to accumulate great income with #1 DeTrust Inheritance Ecosystem
                </ListItem>
              </List>
            </Alert>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <DashboardCard
              balance={Number(formatUnits(ubdnTotalBalance, 18)).toFixed(2)}
              currency={"UBDN"}
              icon={<UbdnIcon style={{ width: "2rem" }} />}
              buttonText={"Swap"}
              buttonLink={"/swap"}
              text={"Swap UBDN tokens to earn airdrop points, spins and rewards in UBD"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <DashboardCard
              balance={(isAuthorized && authInfo?.ubdBalance.toString()) || "0"}
              currency={"UBD"}
              icon={<UbdIcon style={{ width: "2rem" }} />}
              buttonText={"Claim"}
              buttonLink={"/claim"}
              text={`A native stablecoin earned by holding UBDN and winning spin rewards`}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <DashboardCard
              balance={(isAuthorized && authInfo?.usdtBalance.toString()) || "0"}
              currency={"USDT"}
              icon={<USDTIcon style={{ width: "2rem" }} />}
              buttonText={"Invite"}
              buttonLink={"/referral"}
              text={"Increase your wallet balance inviting new referrals and get up to 15% of their swaps"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <DashboardCard
              balance={(isAuthorized && authInfo?.points?.toString()) || "0"}
              currency={"Points"}
              icon={<PointsIcon style={{ width: "2rem" }} />}
              buttonText={"Connect"}
              buttonLink={"/dashboard"}
              text={"Connect your social accounts and get AirDrop points"}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <DashboardCard
              balance={
                (isAuthorized
                  ? (authInfo?.maxSpins || 0) > 0
                    ? `${authInfo?.spins}/${authInfo?.maxSpins}`
                    : authInfo?.spins.toString()
                  : "0") || "0"
              }
              currency={"Spins"}
              icon={<SpinsIcon style={{ width: "2rem" }} />}
              buttonText={"Spins"}
              buttonLink={"/spin"}
              text={`Use your spins and earn various rewards, including native stablecoin UBD`}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Stack gap={2} flex={1} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                  <Typography variant="body1">Stay tuned on our socials</Typography>
                  <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={3}>
                    <MuiLink
                      href="https://discord.com/invite/EAkXDyjXTb"
                      target="_blank"
                      sx={{
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    >
                      <DiscordIcon style={{ height: "16px", color: theme.palette.text.primary }} />
                    </MuiLink>

                    <MuiLink
                      href="https://t.me/ubdnet"
                      target="_blank"
                      sx={{
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    >
                      <TelegramIcon style={{ height: "16px", color: theme.palette.text.primary }} />
                    </MuiLink>

                    <MuiLink
                      href="https://twitter.com/ubdnet"
                      target="_blank"
                      sx={{
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    >
                      <XIcon style={{ height: "16px", color: theme.palette.text.primary }} />
                    </MuiLink>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
