export const Linear = () => {
  return (
    <svg
      style={{ margin: "24px 0", flex: "0 0 100%", width: "100%" }}
      width="598"
      height="1"
      viewBox="0 0 598 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="598" y1="0.5" y2="0.5" stroke="url(#paint0_linear_2566_5944)" />
      <defs>
        <linearGradient id="paint0_linear_2566_5944" x1="0" y1="-0.5" x2="598" y2="-0.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="0.5" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
