export const Ball = () => {
  return (
    <svg
      style={{ flex: "0 0 120px" }}
      width="120"
      height="120"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dddd_2568_6953)">
        <path
          d="M86.9175 67.1721C86.9175 75.8354 79.8945 82.8584 71.2312 82.8584C62.5679 82.8584 55.5449 75.8354 55.5449 67.1721C55.5449 58.5088 62.5679 51.4858 71.2312 51.4858C79.8945 51.4858 86.9175 58.5088 86.9175 67.1721Z"
          fill="#F1F1F3"
        />
        <path
          d="M86.5872 67.1721C86.5872 75.653 79.7121 82.5281 71.2312 82.5281C62.7503 82.5281 55.8752 75.653 55.8752 67.1721C55.8752 58.6912 62.7503 51.8161 71.2312 51.8161C79.7121 51.8161 86.5872 58.6912 86.5872 67.1721Z"
          stroke="#45D3D1"
          stroke-width="0.660597"
        />
      </g>
      <path
        opacity="0.35"
        d="M100.22 66.6979C100.22 82.7592 87.1996 95.7794 71.1383 95.7794C55.0771 95.7794 42.0569 82.7592 42.0569 66.6979C42.0569 50.6366 55.0771 37.6164 71.1383 37.6164C87.1996 37.6164 100.22 50.6366 100.22 66.6979Z"
        stroke="#45D3D1"
        stroke-width="0.660597"
      />
      <circle opacity="0.15" cx="71" cy="67" r="49.6697" stroke="#45D3D1" stroke-width="0.660597" />
      <defs>
        <filter
          id="filter0_dddd_2568_6953"
          x="0.369942"
          y="0.182788"
          width="141.723"
          height="141.723"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.29188" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.83991" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2568_6953" result="effect2_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10.1638" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2568_6953" result="effect3_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.87193" />
          <feGaussianBlur stdDeviation="27.5875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.25 0 0 0 0 1 0 0 0 0 0.64 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_2568_6953" result="effect4_dropShadow_2568_6953" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_2568_6953" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export const MobileBall = () => {
  return (
    <svg
      style={{ flex: "0 0 120px" }}
      width="120"
      height="120"
      viewBox="0 0 143 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dddd_2568_6953)">
        <path
          d="M86.9175 67.1721C86.9175 75.8354 79.8945 82.8584 71.2312 82.8584C62.5679 82.8584 55.5449 75.8354 55.5449 67.1721C55.5449 58.5088 62.5679 51.4858 71.2312 51.4858C79.8945 51.4858 86.9175 58.5088 86.9175 67.1721Z"
          fill="#F1F1F3"
        />
        <path
          d="M86.5872 67.1721C86.5872 75.653 79.7121 82.5281 71.2312 82.5281C62.7503 82.5281 55.8752 75.653 55.8752 67.1721C55.8752 58.6912 62.7503 51.8161 71.2312 51.8161C79.7121 51.8161 86.5872 58.6912 86.5872 67.1721Z"
          stroke="#45D3D1"
          stroke-width="0.660597"
        />
      </g>
      <path
        opacity="0.35"
        d="M100.22 66.6979C100.22 82.7592 87.1996 95.7794 71.1383 95.7794C55.0771 95.7794 42.0569 82.7592 42.0569 66.6979C42.0569 50.6366 55.0771 37.6164 71.1383 37.6164C87.1996 37.6164 100.22 50.6366 100.22 66.6979Z"
        stroke="#45D3D1"
        stroke-width="0.660597"
      />
      <circle opacity="0.15" cx="71" cy="67" r="49.6697" stroke="#45D3D1" stroke-width="0.660597" />
      <defs>
        <filter
          id="filter0_dddd_2568_6953"
          x="0.369942"
          y="0.182788"
          width="141.723"
          height="141.723"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.29188" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.83991" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2568_6953" result="effect2_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10.1638" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.721569 0 0 0 0 0.454902 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2568_6953" result="effect3_dropShadow_2568_6953" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.87193" />
          <feGaussianBlur stdDeviation="27.5875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.25 0 0 0 0 1 0 0 0 0 0.64 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_2568_6953" result="effect4_dropShadow_2568_6953" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_2568_6953" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
