import { defaultWagmiConfig } from "@web3modal/wagmi";
import { mainnet, sepolia } from "viem/chains";

const metadata = {
  name: "UBDN Referral",
  description: "UBD Network Referral App",
  url: "https://early.ubdn.com",
  icons: ["https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/fe085738-3178-4d34-0563-b5e832245600/sm"],
};

export const config = defaultWagmiConfig({
  chains: [mainnet, sepolia],
  projectId: import.meta.env.VITE_WC_PROJECT_ID,
  metadata,
});
