export const Menu = ({ open, onClick }: { open: boolean; onClick: (isOpen: boolean) => void }) => {
  return (
    <svg
      onClick={() => onClick(!open)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.30859 8.30774C4.30859 7.628 4.85963 7.07697 5.53936 7.07697L26.0727 7.07697C26.7524 7.07697 27.3035 7.628 27.3035 8.30773C27.3035 8.98747 26.7524 9.5385 26.0727 9.5385L5.53936 9.5385C4.85963 9.5385 4.30859 8.98747 4.30859 8.30774ZM4.30859 16.3077C4.30859 15.628 4.85963 15.077 5.53936 15.077L26.0727 15.077C26.7524 15.077 27.3035 15.628 27.3035 16.3077C27.3035 16.9875 26.7524 17.5385 26.0727 17.5385L5.53936 17.5385C4.85963 17.5385 4.30859 16.9875 4.30859 16.3077ZM5.53936 23.077C4.85963 23.077 4.30859 23.628 4.30859 24.3077C4.30859 24.9875 4.85963 25.5385 5.53936 25.5385L21.6727 25.5385C22.3524 25.5385 22.9035 24.9875 22.9035 24.3077C22.9035 23.628 22.3524 23.077 21.6727 23.077L5.53936 23.077Z"
        fill="#273852"
      />
    </svg>
  );
};
