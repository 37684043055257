import { Card, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { resolveDecimals } from "~/app/ubdn-app/web3-utils";
import { TableHeadCustom } from "~/components/table";
import { formatDate } from "~/shared/utils";
import { LockedSwap, SwapsResponse } from "./types";

const getSwaps = async (address: string): Promise<SwapsResponse> => {
  // console.log(process.env);
  if (!address)
    return {
      swaps: [],
      claimableSwaps: [],
      lockedSwaps: [],
    };

  const { data } = await axios.get(`${import.meta.env.VITE_APP_API}/ubdn-swaps?address=${address}&network=Ethereum`);

  return data;
};

const LockedTable = () => {
  const { address } = useAccount();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["swaps", address],
    queryFn: () => getSwaps(address ?? ""),
  });

  if (isLoading || isError || (data && !data?.lockedSwaps?.length))
    return (
      <Stack justifyContent="center" alignItems="center" width="100%">
        <CircularProgress />
      </Stack>
    );

  return (
    <Card
      sx={{
        minWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Table>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadCustom
              headLabel={[
                { id: "Date of swap", label: "Date of swap" },
                { id: "Date of unlock", label: "Date of unlock" },
                { id: "Amount", label: "Amount" },
                { id: "Status", label: "Status" },
              ]}
            />

            <TableBody>
              {data?.lockedSwaps
                .sort(
                  (a: LockedSwap, b: LockedSwap) => new Date(b.swappedAt).getTime() - new Date(a.swappedAt).getTime(),
                )
                .map((swap: LockedSwap) => (
                  <TableRow key={swap.swapTxHash}>
                    <TableCell>{formatDate(swap.swappedAt)}</TableCell>
                    <TableCell>{new Date(swap.lockedUntil).toLocaleString()}</TableCell>
                    <TableCell>
                      {Number(formatUnits(BigInt(swap.toAmount), resolveDecimals("UBDN"))).toFixed(2)} UBDN
                    </TableCell>
                    <TableCell>Locked</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Table>
    </Card>
  );
};

export default LockedTable;
