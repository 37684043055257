import { LoadingButton } from "@mui/lab";
import { Stack, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { ClaimStats } from "~/screens/ClaimStats/ClaimStats";
import ClaimTable from "~/screens/ClaimTable";
import LockedTable from "~/screens/LockedTable";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";
import { useCombine } from "./hooks/useCombine";

export interface IClaim {
  _id: string;
  userId: string;
  createdAt: Date;
  usdtAmount: string; //for internal claims
  ubdAmount: string; //for internal claims
  ubdnAmount?: string; //for claimed ubdn
  address: string;
  nonce?: number;
  signature?: string;
  isClaimed: boolean;
}

export const getClaims = async () => {
  console.log("getClaims");
  const { data } = await instance.get<InstanceResponse<IClaim[]>>("/claims/all");
  return data.details;
};

export default function ClaimPage() {
  const theme = useTheme();
  const { loading, combine } = useCombine();
  const {
    data: claimsData,
    isLoading,
    isError,
  } = useQuery<IClaim[]>({
    queryKey: ["withdrawals"],
    queryFn: getClaims,
  });

  return (
    <Stack>
      <Typography variant="h4" pb={5} pt={2}>
        CLAIM REWARDS
      </Typography>
      <ClaimStats />

      <Stack alignItems={"flex-start"} gap={3}>
        <Typography variant="h4">Swaps</Typography>
        <LockedTable />
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width="100%">
          <Typography variant="h4">Withdrawal requests</Typography>
          {(claimsData || []).filter((claim) => !claim.isClaimed).length > 1 && (
            <LoadingButton variant="contained" onClick={combine} loading={loading}>
              Combine
            </LoadingButton>
          )}
        </Stack>
        <ClaimTable data={claimsData ?? []} isLoading={isLoading} isError={isError} />
      </Stack>
    </Stack>
  );
}
