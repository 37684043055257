interface NextButtonProps {
  style?: React.CSSProperties;
}

export const NextButton = ({ style }: NextButtonProps) => {
  return (
    <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 70.9844 0.00585938)" fill="#F1F1F3" />
      <path
        d="M44.1169 36.6016C45.3447 35.8927 45.3446 34.1205 44.1169 33.4117L30.2998 25.4344C29.072 24.7255 27.5373 25.6116 27.5373 27.0294L27.5373 42.9839C27.5373 44.4016 29.072 45.2877 30.2998 44.5788L44.1169 36.6016Z"
        fill="#1B2030"
      />
    </svg>
  );
};
