import { Box, Button, Popover, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import SettingsDrawerButton from "~/components/SettingsDrawer/SettingsDrawerButton";
import Iconify from "~/components/iconify";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { trimString } from "~/utils/strings";
import { isRoot } from "~/utils/utils";
import "./style.css";

const Web3Profile = () => {
  const { address } = useAccount();
  const [hover, setHover] = useState<HTMLElement | null>(null);
  const boxRef = React.useRef<HTMLButtonElement>(null);
  const { disconnect } = useDisconnect({
    mutation: {
      onSuccess: () => navigate("/"),
    },
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const root = isRoot();
  const isDesktop = useIsDesktop();

  const handleHoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setHover(event.currentTarget);
  };
  const handleHoverClose = () => {
    setHover(null);
  };

  return (
    <>
      {!isDesktop && <SettingsDrawerButton />}
      <Popover
        open={Boolean(hover)}
        anchorEl={hover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleHoverClose}
        sx={{
          marginTop: "20px",
        }}
      >
        <Box>
          <Button
            onClick={() => {
              disconnect();
              localStorage.removeItem("jwt");
            }}
          >
            <div
              className="button-disconnect"
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "20 20 20 30",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify icon="material-symbols:logout" />
              </div>
              <div>
                <Typography style={{ fontSize: "0.875rem" }}>Disconnect</Typography>
              </div>
            </div>
          </Button>
        </Box>
      </Popover>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        <Button
          variant="text"
          ref={boxRef}
          onClick={handleHoverOpen}
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              ...(root && { color: isDesktop ? theme.palette.secondary.main : "#F1F1F3" }),
            }}
          >
            {trimString(address || "")}
          </Typography>
          <Iconify
            icon="fluent:copy-24-filled"
            ml={0.5}
            color={root ? (isDesktop ? theme.palette.secondary.main : "#F1F1F3") : theme.palette.action.active}
            onClick={(event) => {
              navigator.clipboard.writeText(address || "");
              event.stopPropagation();
            }}
            sx={{
              cursor: "pointer",
              "&:hover": {
                opacity: 0.6,
              },
            }}
          />
        </Button>
      </Stack>
    </>
  );
};

export default Web3Profile;
