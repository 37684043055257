import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Checked } from "../icons/Checked";

export const FeatureHighlights = () => {
  const ipad = useMediaQuery("(max-width:750px)");
  const desktop = useMediaQuery("(max-width:1209px)");
  const largeIpad = useMediaQuery("(max-width:1129px)");

  return (
    <Stack
      mt={3}
      flexDirection="row"
      sx={{
        "@media(max-width:1120px)": {
          flexDirection: "column-reverse",
        },
        "@media(max-width:440px)": {
          width: "100%",
        },
      }}
      alignItems="center"
    >
      <Box
        alignSelf="normal"
        sx={{
          "@media(max-width:1120px)": {
            mr: "auto",
            mt: 3,
          },

          "@media(max-width:750px)": {
            maxWidth: " 380px",
            width: "100%",
            margin: "0 auto",
          },
          "@media(max-width:440px)": {
            width: "100%",
          },
        }}
      >
        <Typography mt={ipad ? 2 : 0} mb={2} color="rgba(39, 56, 82, 1)" fontSize={"24px"} fontWeight="700">
          Innovations
        </Typography>
        <Stack
          sx={{
            height: "70%",
            borderRadius: "12px",
            position: "relative",
            backgroundColor: " rgba(93, 84, 189, 0.5)",
            "::before": {
              content: '""',
              position: "absolute",
              top: -1,
              left: -1,
              right: -1,
              bottom: -1,
              zIndex: -1,
              borderRadius: "12px",
              padding: "1px",
              background: "linear-gradient(rgba(186, 196, 255, 1), rgba(79, 64, 141, 1))",
              WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "destination-out",
              maskComposite: "exclude",
            },
          }}
          p={1.3}
        >
          <Stack flexDirection="row" alignItems="center" mb={1} mr={0.7}>
            <Checked />
            <Box
              sx={{
                "@media(max-width:1120px)": {
                  fontSize: "16px",
                },
                "@media(max-width:880px)": {
                  fontSize: "14px",
                },
                "@media(max-width:440px)": {
                  whiteSpace: "wrap",
                },
              }}
              fontSize={desktop ? "14px" : "16px"}
              whiteSpace={ipad ? "nowrap" : "wrap"}
              color="#fff"
            >
              Useful usecases as inheritance and backup
            </Box>
          </Stack>
          <Stack flexDirection="row" alignItems="center" mb={1} mr={0.7}>
            <Checked />
            <Box
              sx={{
                "@media(max-width:1120px)": {
                  fontSize: "16px",
                },
                "@media(max-width:880px)": {
                  fontSize: "14px",
                },
                "@media(max-width:440px)": {
                  whiteSpace: "wrap",
                },
              }}
              fontSize={desktop ? "14px" : "16px"}
              whiteSpace={ipad ? "nowrap" : "wrap"}
              color="#fff"
            >
              Delayed transfer of crypto assets
            </Box>
          </Stack>
          <Stack
            sx={{
              "@media(max-width:1120px)": {
                fontSize: "16px",
              },
              "@media(max-width:880px)": {
                fontSize: "14px",
              },
              "@media(max-width:440px)": {
                whiteSpace: "wrap",
              },
            }}
            flexDirection="row"
            alignItems="center"
            mr={0.7}
            fontSize={desktop ? "14px" : "16px"}
            whiteSpace={ipad ? "nowrap" : "wrap"}
            color="#fff"
          >
            <Checked />
            <Box fontSize={ipad ? "14px" : "16px"}>Supports 16 popular EVM</Box>
          </Stack>
        </Stack>
      </Box>
      <Box
        alignSelf="normal"
        ml={largeIpad ? 1.2 : 3}
        sx={{
          "@media(max-width:1120px)": {
            ml: 0,
            mr: "auto",
          },
          "@media(max-width:750px)": {
            maxWidth: " 380px",
            width: "100%",
            margin: "0 auto",
          },
          "@media(max-width:440px)": {
            width: "100%",
          },
        }}
      >
        <Typography mb={2} color="rgba(39, 56, 82, 1)" fontSize="24px" fontWeight="700">
          Reasons to join
        </Typography>
        <Stack
          sx={{
            height: "70%",
            borderRadius: "12px",
            position: "relative",
            backgroundColor: " rgba(93, 84, 189, 0.5)",
            "::before": {
              content: '""',
              position: "absolute",
              top: -1,
              left: -1,
              right: -1,
              bottom: -1,
              zIndex: -1,
              borderRadius: "12px",
              padding: "1px",
              background: "linear-gradient(rgba(186, 196, 255, 1), rgba(79, 64, 141, 1))",
              WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "destination-out",
              maskComposite: "exclude",
            },
            "@media(max-width:749px)": {
              width: "100%",
            },
          }}
          p={1.3}
          alignSelf="normal"
        >
          <Stack flexDirection="row" alignItems="center" mb={1}>
            <Checked />
            <Box
              sx={{
                "@media(max-width:1120px)": {
                  fontSize: "16px",
                },
                "@media(max-width:880px)": {
                  fontSize: "14px",
                },
                "@media(max-width:440px)": {
                  whiteSpace: "wrap",
                },
              }}
              fontSize={desktop ? "14px" : "16px"}
              whiteSpace={ipad ? "nowrap" : "wrap"}
              color="#fff"
            >
              Massive airdrop for leaderboard promotion
            </Box>
          </Stack>
          <Stack flexDirection="row" alignItems="center" mb={1}>
            <Checked />
            <Box
              sx={{
                "@media(max-width:1120px)": {
                  fontSize: "16px",
                },
                "@media(max-width:880px)": {
                  fontSize: "14px",
                },
                "@media(max-width:440px)": {
                  whiteSpace: "wrap",
                },
              }}
              fontSize={desktop ? "14px" : "16px"}
              whiteSpace={ipad ? "nowrap" : "wrap"}
              color="#fff"
            >
              {" "}
              Instant rewards in USDT and native stable
            </Box>
          </Stack>
          <Stack
            flexDirection="row"
            sx={{ "@media(max-width:1255px)": { pb: "20px" }, "@media(max-width:1209px)": { pb: "9px" } }}
            alignItems="center"
          >
            <Checked />
            <Box
              sx={{
                "@media(max-width:1120px)": {
                  fontSize: "16px",
                },
                "@media(max-width:880px)": {
                  fontSize: "14px",
                },
                "@media(max-width:440px)": {
                  whiteSpace: "wrap",
                },
              }}
              fontSize={desktop ? "14px" : "16px"}
              whiteSpace={ipad ? "nowrap" : "wrap"}
              color="#fff"
            >
              {" "}
              Constantly increasing demand for the token
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
