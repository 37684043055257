import { Box, Card, CardContent, Stack, Typography, useMediaQuery } from "@mui/material";
import { CoinIcon } from "~/components/Swapper/tokenIconMap";
import { Linear } from "../icons/Linear";

export const BuyStock = () => {
  const mobile = useMediaQuery("(max-width:450px)");
  const largeTablet = useMediaQuery("(max-width:1206px)");

  return (
    <Box>
      <Typography
        width="min(862px, 100%)"
        mb="40px"
        mt="70px"
        fontWeight="700"
        fontSize={largeTablet ? "24px" : "32px"}
        color="#fff"
      >
        What can I do with my rewards other than buy a lambo, a yacht and a mansion on the islands?
      </Typography>
      <Stack
        mb={mobile ? "50px" : "104px"}
        gap="24px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          "@media(max-width:1206px)": {
            flexWrap: "wrap",
            mb: "72px",
          },
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "285px",
            backgroundColor: "#292E41",
            "@media(max-width:1206px)": {
              height: "100%",
            },
          }}
        >
          <CardContent>
            <Stack flexDirection="row" alignItems="center">
              <CoinIcon style={{ width: "48px", height: "48px" }} symbol="UBDN" />
              <Box fontSize="24px" fontWeight="700" color="#fff" ml="20px" component="span">
                UBDN
              </Box>
            </Stack>
            <Stack mt="24px" flexDirection={mobile ? "column" : "row"} justifyContent="space-between">
              <Box mr="20px" color="#989BA9" fontSize="16px" component="p" mb={mobile ? "10px" : "0"}>
                <Box component="span" color="#fff">
                  Become UBDN Hodler
                </Box>{" "}
                and grow with UBD Network more and more as the roadmap is implemented
              </Box>
            </Stack>
            <Linear />
            <Stack mt="24px" flexDirection={mobile ? "column" : "row"} justifyContent="space-between">
              <Box mr="20px" color="#989BA9" fontSize="16px" component="p" mb="20px">
                <Box component="span" color="#fff">
                  Become UBDN Keeper
                </Box>{" "}
                and receive 50% of maintenance fees paid by deTrust users and 50% of all commissions paid when
                converting UBD coin
              </Box>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#292E41",
          }}
        >
          <CardContent>
            <Stack flexDirection="row" alignItems="center">
              <CoinIcon style={{ width: "48px", height: "48px" }} symbol="UBD" />
              <Box fontWeight="700" fontSize="24px" color="#fff" ml="20px" component="span">
                UBD
              </Box>
            </Stack>
            <Stack mt="24px" flexDirection={mobile ? "column" : "row"} justifyContent="space-between">
              <Box mr="20px" color="#989BA9" fontSize="16px" component="p">
                <Box component="span" color="#fff">
                  Create personal DeTrust Multi-sig wallet
                </Box>{" "}
                - Protect your funds from loss, configure inheritance scenarios and much more
              </Box>
            </Stack>
            <Linear />
            <Stack flexDirection={mobile ? "column" : "row"} justifyContent="space-between">
              <Box mr="20px" color="#989BA9" fontSize="16px" component="p" pb="20px">
                <Box component="span" color="#fff">
                  Stake up to 10,5% APY
                </Box>{" "}
                and earn yield for your DeTrust in LIDO, AAVE, Compund and so on.
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};
