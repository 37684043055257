import { formatUnits } from "viem";

import { SetClaim } from "~/features";
// import { Table } from "~/modules";

import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { TableHeadCustom } from "~/components/table";
import { IClaim } from "~/pages/ClaimPage/ClaimPage";
import { formatDate } from "~/shared/utils";

const ClaimTable = ({ data, isLoading, isError }: { data: IClaim[]; isLoading: boolean; isError: boolean }) => {
  if (isLoading || !data) return <CircularProgress />;
  if (isError || (data && !data.length)) return <Typography>You didn't create any claim requests yet</Typography>;

  return (
    <Card
      sx={{
        minWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Table>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadCustom
              headLabel={[
                { id: "Date of request", label: "Date of request" },
                { id: "Amount", label: "Amount" },
                { id: "Status", label: "Status" },
              ]}
            />

            <TableBody>
              {data.map((claim: IClaim) => (
                <TableRow key={claim._id}>
                  <TableCell>{formatDate(claim.createdAt)}</TableCell>
                  <TableCell>
                    {formatUnits(BigInt(claim.usdtAmount ?? "0"), 6)} USDT,{" "}
                    {formatUnits(BigInt(claim.ubdAmount ?? "0"), 18)} UBD
                  </TableCell>
                  <TableCell>{claim.isClaimed ? <span>Withdrawn</span> : <SetClaim item={claim} />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Table>
    </Card>
  );
};

export default ClaimTable;
