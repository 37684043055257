import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useSettingsContext } from "../settings/SettingsContext";

const SettingsDrawerButton = () => {
  const { toggleShowSettingsDrawer } = useSettingsContext();

  return (
    <IconButton
      onClick={toggleShowSettingsDrawer}
      sx={{
        width: "48px",
        height: "48px",
        marginRight: "auto",
      }}
    >
      <Icon icon="solar:settings-linear" />
    </IconButton>
  );
};

export default SettingsDrawerButton;
