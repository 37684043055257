import { Box, Stack, useTheme } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { MobileBottomNavigation } from "~/components/MobileBottomNavigation/MobileBottomNavigation";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { Footer } from "~/modules/Footer/Footer";
import HeaderAuth from "~/modules/HeaderAuth/HeaderAuth";
import Menu from "~/modules/HeaderAuth/menuDrawer/Menu";
import { MenuDrawerProvider } from "~/modules/HeaderAuth/menuDrawer/MenuDrawerContext";
import navConfig from "~/modules/HeaderAuth/nav/config-navigation";
import HeaderNoAuth from "~/modules/HeaderNoAuth/HeaderNoAuth";
import { Loading } from "~/shared/ui";
import { getJwtInfo } from "~/shared/utils/jwt";
import palette from "~/theme/palette";

export const MainLayout = () => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const wallet = useWallet();

  const root =
    !navConfig[0].items.some((path) => path.path === location.pathname) || location.pathname.includes("login");

  const isAbout = location.pathname === "/about";
  const isRootPage = location.pathname === "/";
  const isLoginPage = location.pathname === "/login";
  const isAuthorized = !!getJwtInfo() && wallet.isAuthorized;

  return (
    <Suspense fallback={<Loading />}>
      <MenuDrawerProvider>
        <Menu>
          <Stack
            alignItems={"center"}
            // px={2}
            minHeight={"100vh"}
            // justifyContent="space-between"
            bgcolor={root ? palette("dark").background.default : theme.palette.background.default}
            sx={{
              overflowX: "hidden",
              ...(!isAuthorized &&
                isAbout && {
                  bgcolor: palette("dark").background.default,
                }),
            }}
          >
            {isAuthorized ? <HeaderAuth /> : <HeaderNoAuth />}
            <Stack
              width={"min(1400px, 100%)"}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                px: 2,
                minHeight: isLoginPage ? "calc(100vh - 64px - 51px)" : '',
              }}
            >
              <Outlet />
            </Stack>
            <Footer />
          </Stack>
        </Menu>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            display: `${isDesktop ? "none" : ""}`,
          }}
        >
          {isAuthorized && <MobileBottomNavigation />}
        </Box>
      </MenuDrawerProvider>
    </Suspense>
  );
};
