import { useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Container } from "~/modules/Container/Container";
import { formatDistributedAmount, getStats, initGradient } from "../HomePage";
import { About } from "./About/About";
import { BuyStock } from "./BuyStock/BuyStock";
import { Ecosystem } from "./Ecosystem/Ecosystem";

import { MultiSig } from "./MultiSig/MultiSig";
import { MultiSigMobile } from "./MultiSig/MultiSigMobile/MultiSigMobile";
import { Partners } from "./Partners/Partners";
import { Platform } from "./Platform/Platform";
import { SmartContract } from "./SmartContract/SmartContract";
import { WalletsFlex } from "./Wallets/WalletsFlex/WalletsFlex";
import { WalletsGrid } from "./Wallets/WalletsGrid/WalletsGrid";

export const InfoHome = () => {
  const largeIpad = useMediaQuery("(max-width:900px)");
  const ipad = useMediaQuery("(max-width:800px)");
  const [promoInfo, setPromoInfo] = useState({
    tokenMaked: 5482829,
    tokenTotal: 6000000,
    tokenPrice: 0.1,
    maxAmount: 6000000,
    tokenNextPrice: 3,
  });

  const { data: stats } = useQuery<any>({
    queryKey: ["generalStats"],
    queryFn: getStats,
  });

  useEffect(() => {
    initGradient();
    if (stats) {
      let tokenTotal = 0;
      let limit = 20;
      while (tokenTotal < formatDistributedAmount(stats?.distributedAmount) && limit) {
        tokenTotal += 1000000;
        limit -= 1;
      }
      setPromoInfo({
        tokenMaked: formatDistributedAmount(stats?.distributedAmount.toString()),
        tokenTotal: tokenTotal,
        tokenPrice: tokenTotal / 1000000 - 4,
        maxAmount: tokenTotal,
        tokenNextPrice: tokenTotal / 1000000 - 3,
      });
    }
  }, [stats]);

  return (
    <Container>
      {ipad ? <MultiSigMobile /> : <MultiSig />}
      {largeIpad ? <WalletsFlex /> : <WalletsGrid />}
      <Ecosystem />
      <Platform />
      <BuyStock />
      <SmartContract />
      <Partners />
      <About />
    </Container>
  );
};
