import { Box, Button, Card, Link, Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CSSProperties, ReactElement, useState } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { DetrustIcon } from "~/assets/DetrustIcon";
import { LinkedInIcon } from "~/assets/LinkedInIcon";
import { UbdIcon } from "~/assets/UbdIcon";
import { UbdnIcon } from "~/assets/UbdnIcon";
import mapImageDark from "~/assets/about-dark-map.png";
import flowerImageDark from "~/assets/about-flower-dark.png";
import flowerImageLight from "~/assets/about-flower-light.png";
import mapMobileImageDark from "~/assets/about-map-dark-mobile.png";
import mapMobileImageLight from "~/assets/about-map-light-mobile.png";
import mapImageLight from "~/assets/about-map-light.png";
import roadmapMobileImageDark from "~/assets/about-roadmap-dark-mobile.png";
import roadmapImageDark from "~/assets/about-roadmap-dark.png";
import roadmapMobileImageLight from "~/assets/about-roadmap-light-mobile.png";
import roadmapImageLight from "~/assets/about-roadmap-light.png";
import shieldImageDark from "~/assets/about-shield-dark.png";
import shieldImageLight from "~/assets/about-shield-light.png";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { Carousel } from "~/modules";
import palette from "~/theme/palette";
import { About } from "../Home/InfoBlock/About/About";

interface TeamMemberProps {
  name: string;
  role: string;
  photo: string;
  linkedIn?: string;
  palette: ReturnType<typeof palette>;
}

const TeamMember = ({ name, role, photo, linkedIn, palette }: TeamMemberProps) => {
  const isDesktop = useIsDesktop();
  return (
    <Stack alignItems={"center"} justifyContent={"center"} gap={0.5} p={0}>
      <Box position={"relative"} pb={1}>
        <Box
          component="img"
          alt="UBDN roadmap"
          src={photo}
          sx={
            {
              // ...(!isDesktop && { maxWidth: "100px" }),
            }
          }
        />
        {linkedIn && (
          <Link href={linkedIn} target="_blank">
            <LinkedInIcon style={{ position: "absolute", right: "5%", bottom: "5%" }} />
          </Link>
        )}
      </Box>

      <Typography variant="body2" color={palette.text.primary}>
        {role}
      </Typography>
      <Typography variant="body2" fontWeight={800} color={palette.text.primary}>
        {name}
      </Typography>
    </Stack>
  );
};

interface CardPartnerProps {
  src: string;
  alt: string;
  palette?: ReturnType<typeof palette>;
}

const CardPartner = ({ src, alt, palette }: CardPartnerProps) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        bgcolor: theme.palette.background.neutral,
        height: "100px",
        borderRadius: "15px",
        padding: "20px",
      }}
    >
      <Box component="img" alt={alt} src={src} />
    </Stack>
  );
};

interface CardIconProps {
  title: string;
  body: string;
  icon?: ReactElement;
  palette?: ReturnType<typeof palette>;
}

const CardIcon = ({ title, body, icon, palette }: CardIconProps) => {
  const theme = useTheme();
  return (
    <Card sx={{ p: 5, bgcolor: theme.palette.background.paper, boxShadow: "none" }}>
      <Stack gap={2.5}>
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          {icon}
          <Typography variant="h3" color={theme.palette.text.primary}>
            {title}
          </Typography>
        </Stack>

        <Typography variant="body1" color={theme.palette.text.secondary}>
          {body}
        </Typography>
      </Stack>
    </Card>
  );
};

interface CardBulletsProps {
  title: string;
  values: string[];
  palette?: ReturnType<typeof palette>;
}

const CardBullets = ({ title, values, palette }: CardBulletsProps) => {
  const theme = useTheme();
  return (
    <Card sx={{ p: 5, bgcolor: theme.palette.background.paper, boxShadow: "none" }}>
      <Stack gap={2.5}>
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {title}
          </Typography>
        </Stack>
        <Box pl={1.5}>
          <ul
            style={{
              listStyle: "disc",
              color: theme.palette.text.secondary,
            }}
          >
            {values.map((value) => (
              <li key={value}>
                <Typography variant="body1" color={theme.palette.text.secondary}>
                  {value}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Stack>
    </Card>
  );
};

const selectorListValues = {
  Keepers: [`Provide Liquidity in UBDN`, "Validate the Ecosystem Maintenance", "Receive rewards in UBD Stablecoins"],
  Stakers: ["Open Trusts with UBD", "Setup the Asset Management Rules", "Get rewards"],
  Holders: ["Participate in DAO", "Keep the protocol in balance", "Trade on the second market"],
};

interface SelectorProps {
  active: boolean;
  value: string;
  clickHandler: () => void;
  palette?: ReturnType<typeof palette>;
}

const Selector = ({ active, value, clickHandler, palette }: SelectorProps) => {
  const theme = useTheme();
  const whiteThemeSelected: CSSProperties = {
    border: "none",
    backgroundColor: "#292F41",
    color: "white",
  };
  const whiteThemeUnselected: CSSProperties = {
    border: "1px solid #292F41",
    color: "#292F41",
    backgroundColor: "transparent",
  };

  let selectedStyles: SxProps<Theme> = {
    "&:hover": {
      backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : theme.palette.text.primary,
      color: theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.background.default,
      border: "none",
    },
    boxShadow: "none !important",
  };

  selectedStyles = { ...selectedStyles, ...(active ? whiteThemeSelected : whiteThemeUnselected) };
  if (theme.palette.mode == "dark") selectedStyles = { ...selectedStyles, color: "white" };

  return (
    <Button
      variant={active ? "contained" : "outlined"}
      onClick={clickHandler}
      disableRipple
      sx={{
        fontWeight: 400,
        px: 5,
        border: 1,
        ...selectedStyles,
        // bgcolor: active ? (theme.palette.mode === "dark" ? theme.palette.background.paper : theme.palette.text.primary) : "transparent",
        // color:
        //   palette.mode === "dark" ? palette.text.primary : active ? palette.background.default : palette.text.primary,
        // borderColor: palette.mode === "dark" ? palette.background.paper : palette.text.primary,
        // "&:hover": {
        //   boxShadow: "none",
        //   bgcolor: active ? (palette.mode === "dark" ? palette.background.paper : palette.text.primary) : "transparent",
        // },
      }}
    >
      {value}
    </Button>
  );
};

export default function AboutPage() {
  const { isAuthorized } = useWallet();
  // const [palette, setPalette] = useState(() => themePalette("dark"));

  const theme = useTheme();

  // useLayoutEffect(() => {
  //   if (!isAuthorized) {
  //     setPalette(themePalette("dark"));
  //     return;
  //   } else if (theme.palette.mode === "light") {
  //     setPalette(themePalette("light"));
  //   } else if (theme.palette.mode === "dark") {
  //     setPalette(themePalette("dark"));
  //   }
  // }, [isAuthorized, theme]);

  const [selectorValue, setSelectorValue] = useState<"Keepers" | "Stakers" | "Holders">("Keepers");
  const isDesktop = useIsDesktop();

  // useEffect(() => {
  //   document.body.style.overflow = "unset";
  // });

  return (
    <>
      <Stack width={"min(1250px,100%)"} pb={10}>
        <Typography variant="h2" color={theme.palette.text.primary} pt={5} pb={10}>
          UBD Network Ecosystem
        </Typography>
        <Grid container spacing={4}>
          <Grid xs={12}>
            <CardIcon
              title="DeTrust multi-sig platform"
              body="A multi-signature platform and self-managed crypto asset wallet that helps you store and defer the transfer of crypto assets."
              icon={
                <DetrustIcon
                  style={{
                    color: theme.palette.text.primary,
                  }}
                />
              }
            />
          </Grid>

          <Grid xs={12} md={6}>
            <CardIcon
              title="UBDN"
              body="Utility token, which allows its owners to support the ecosystem, become Keepers and participate in Epochs to receive monthly rewards in UBD."
              icon={
                <UbdnIcon
                  style={{
                    color: theme.palette.text.primary,
                  }}
                />
              }
            />
          </Grid>
          <Grid xs={12} md={6}>
            <CardIcon
              title="UBD"
              body="United Blockchain Dollar, an algorithmic stablecoin pegged to the US dollar and also backed by BTC, ETH, USDT, DAI. Can be staked to increase rewards."
              icon={
                <UbdIcon
                  style={{
                    color: theme.palette.text.primary,
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        {isDesktop && (
          <Typography variant="h2" color={theme.palette.text.primary} py={10} textAlign={"center"}>
            How it works
          </Typography>
        )}
        {isDesktop ? (
          <Box component="img" alt="UBDN roadmap" src={theme.palette.mode === "light" ? mapImageLight : mapImageDark} />
        ) : (
          <Box
            component="img"
            alt="UBDN roadmap"
            src={theme.palette.mode === "light" ? mapMobileImageLight : mapMobileImageDark}
            pt={5}
          />
        )}

        <Grid container spacing={4} pt={30}>
          <Grid xs={12} md={6}></Grid>
          {isDesktop && (
            <Grid xs={12} md={6} display={"flex"} gap={3}>
              <Selector
                active={selectorValue === "Keepers"}
                value="Keepers"
                clickHandler={() => setSelectorValue("Keepers")}
              />
              <Selector
                active={selectorValue === "Stakers"}
                value="Stakers"
                clickHandler={() => setSelectorValue("Stakers")}
              />
              <Selector
                active={selectorValue === "Holders"}
                value="Holders"
                clickHandler={() => setSelectorValue("Holders")}
              />
            </Grid>
          )}

          <Grid xs={12} md={6} position={"relative"}>
            <Box
              component="img"
              alt="UBDN roadmap"
              src={theme.palette.mode === "light" ? flowerImageLight : flowerImageDark}
              sx={{
                width: isDesktop ? "200px" : "140px",
                position: "absolute",
                zIndex: 500,
                right: "60px",
                top: 0,
                transform: "translateY(-60%)",
              }}
            />
            <CardBullets title={selectorValue} values={selectorListValues[selectorValue]} />
          </Grid>
          {!isDesktop && (
            <Grid xs={12} md={6} display={"flex"} gap={3}>
              <Selector
                active={selectorValue === "Keepers"}
                value="Keepers"
                clickHandler={() => setSelectorValue("Keepers")}
              />
              <Selector
                active={selectorValue === "Stakers"}
                value="Stakers"
                clickHandler={() => setSelectorValue("Stakers")}
              />
              <Selector
                active={selectorValue === "Holders"}
                value="Holders"
                clickHandler={() => setSelectorValue("Holders")}
              />
            </Grid>
          )}

          <Grid xs={12} md={6}>
            <Typography variant="h3" color={theme.palette.text.primary} pb={4}>
              United Blockchain Dollar Network
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
              Provides users to create digital trusts for managing or inheriting their crypto assets, including NFTs,
              with completely different scenarios that they can create or modify themselves.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
            <Typography variant="h3" color={theme.palette.text.primary} pb={4}>
              Why UBD Network?
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary} pb={2}>
              UBDN is a tool that generates rewards from the operation of the UBD Network{" "}
              <span style={{ color: theme.palette.text.primary, fontWeight: "700" }}>ecosystem.</span>
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.text.secondary}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              50% of UBD Network Rewards that comes from Trust maintenance and UBD transaction fees to be shared between
              epoch participants.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box
              component="img"
              alt="UBDN roadmap"
              src={theme.palette.mode === "light" ? shieldImageLight : shieldImageDark}
              width={"330px"}
            />
          </Grid>

          <Grid xs={12}>
            <Typography variant="h2" color={theme.palette.text.primary} py={10}>
              Road map
            </Typography>
            {isDesktop && (
              <Box
                component="img"
                alt="UBDN roadmap"
                src={theme.palette.mode === "light" ? roadmapImageLight : roadmapImageDark}
              />
            )}
            {!isDesktop && (
              <Box
                component="img"
                alt="UBDN roadmap"
                src={theme.palette.mode === "light" ? roadmapMobileImageLight : roadmapMobileImageDark}
              />
            )}
          </Grid>

          <Grid xs={12}>
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="h2" color={theme.palette.text.primary} py={10}>
                Partners and Integrations
              </Typography>
              <Link href="https://ubdn.com/partner" target="_blank">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#FFF",
                    opacity: 0.8,
                    fontWeight: "400",
                    color: theme.palette.background.default,
                    backgroundColor: theme.palette.text.primary,
                    paddingX: 4,
                    paddingY: 2,
                    "&:hover": {
                      opacity: 1,
                      backgroundColor: theme.palette.text.primary,
                      boxShadow: 0,
                    },
                  }}
                >
                  Become a partner
                </Button>
              </Link>
            </Stack>
          </Grid>

          <Grid xs={6} md={3}>
            <CardPartner src="/partners/certik.svg" alt="certik" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/iber.svg" alt="iber" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/brGroup.png" alt="br-Group" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/decubate.png" alt="decubate" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/beeCom.png" alt="beeCom" />
          </Grid>

          <Grid xs={6} md={3}>
            <CardPartner src="/partners/plexus.png" alt="Plexus" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/uniswap.png" alt="uniswap" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/image28.png" alt="" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/humanode.png" alt="humanode" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/folks.png" alt="folks" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/layer.svg" alt="layer" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/galxe.png" alt="galxe" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/mar3.png" alt="mar3" />
          </Grid>
          <Grid xs={6} md={3}>
            <CardPartner src="/partners/rabby.png" alt="rabby" />
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Typography variant="h2" color={theme.palette.text.primary} py={10} textAlign={"center"}>
            Media about us
          </Typography>
          <Carousel />
        </Grid>
        <About />
        {/* <Typography variant="h2" color={palette.text.primary} py={10} textAlign={"center"}>
        UBD Network Team
      </Typography> */}

        {/* <Grid
        container
        spacing={isDesktop ? 16 : 8}
        maxWidth={"100vw"}
        sx={{
          ...(!isDesktop && { m: 0 }),
        }}
      >
        <Grid xs={6} md={3}>
          <TeamMember
            role="CEO, Founder"
            name="Ruslan Tugushev"
            photo="Ruslan Tugushev.png"
            linkedIn="https://www.linkedin.com/in/tugush"
            palette={palette}
          />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember
            role="COO"
            name="Evgenii Popov"
            photo="Evgenii Popov.png"
            linkedIn="https://www.linkedin.com/in/evgenii-popov/"
            palette={palette}
          />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="CPO" name="Paul Marchev" photo="Paul Marchev.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember
            role="CTO"
            name="Dmitriy Mezhinsky"
            photo="Dmitriy Mezhinsky.png"
            linkedIn="https://www.linkedin.com/in/dmitry-mezhinsky-52291984"
            palette={palette}
          />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="CMO" name="Ivan Golenko" photo="Ivan Golenko.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="BDO" name="Nazeem Dollie" photo="Nazeem Dollie.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember
            role="Fullstack developer"
            name="Paul Mikhaylovin"
            photo="Paul Mikhaylovin.png"
            linkedIn="https://www.linkedin.com/in/pqhaz"
            palette={palette}
          />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="Fullstack developer" name="Mikhail Zhuk" photo="Mikhail Zhuk.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="BDO" name="Sophie Semyonova" photo="Sophie Semyonova.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="Product Designer" name="Nick Proskurin" photo="Nick Proskurin.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="Community Manager" name="Paul Smirnov" photo="Paul Smirnov.png" palette={palette} />
        </Grid>
        <Grid xs={6} md={3}>
          <TeamMember role="Fullstack developer" name="Michel Shepel" photo="Michel Shepel.png" palette={palette} />
        </Grid>
      </Grid> */}

        {/* <Stack alignItems="center" py={10}>
        <Link href="https://ubdn.com/" target="_blank">
          <Button
            variant="contained"
            sx={{
              color: palette.background.default,
              bgcolor: palette.text.primary,
              opacity: 0.8,
              fontWeight: "400",
              paddingX: 4,
              paddingY: 2,
              "&:hover": {
                // opacity: 1,
                bgcolor: palette.text.secondary,
                color: palette.background.paper,
                boxShadow: "none",
              },
            }}
          >
            Join our team
          </Button>
        </Link>
      </Stack> */}
      </Stack>
    </>
  );
}
