import { IconProps } from "./types";

export const SuccessIcon = ({ style }: IconProps) => {
  return (
    <svg width="218" height="218" viewBox="0 0 218 218" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_1683_5367)">
        <path
          d="M117.391 101.407L102.978 115.316L95.0543 107.669L91.0109 103.767C88.864 101.695 85.4621 101.695 83.3152 103.767C81.2304 105.779 81.2304 109.119 83.3152 111.131L91.2391 118.778L102.978 130.107L121.239 112.484L135.652 98.5745C137.755 96.5454 137.755 93.1769 135.652 91.1477C133.523 89.0934 130.15 89.0934 128.022 91.1477L117.391 101.407Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_dd_1683_5367)">
        <path
          d="M109 43.375C96.0206 43.375 83.3327 47.2238 72.5407 54.4348C61.7488 61.6458 53.3374 71.895 48.3704 83.8864C43.4034 95.8778 42.1038 109.073 44.636 121.803C47.1682 134.533 53.4183 146.226 62.5961 155.404C71.774 164.582 83.4672 170.832 96.1972 173.364C108.927 175.896 122.122 174.597 134.114 169.63C146.105 164.663 156.354 156.251 163.565 145.459C170.776 134.667 174.625 121.979 174.625 109C174.625 91.5952 167.711 74.9032 155.404 62.5961C143.097 50.289 126.405 43.375 109 43.375ZM109 165.25C97.8748 165.25 86.9995 161.951 77.7492 155.77C68.4989 149.589 61.2892 140.804 57.0318 130.526C52.7744 120.248 51.6604 108.938 53.8309 98.0262C56.0013 87.1147 61.3586 77.0919 69.2253 69.2252C77.092 61.3585 87.1148 56.0012 98.0262 53.8308C108.938 51.6604 120.248 52.7743 130.526 57.0318C140.804 61.2892 149.589 68.4989 155.77 77.7492C161.951 86.9994 165.25 97.8748 165.25 109C165.25 123.918 159.324 138.226 148.775 148.775C138.226 159.324 123.918 165.25 109 165.25Z"
          fill="white"
        />
      </g>
      <circle cx="109" cy="109" r="75.5" stroke="#87E2FC" />
      <circle opacity="0.4" cx="109" cy="109" r="89.5" stroke="#87E2FC" />
      <circle opacity="0.2" cx="109" cy="109" r="105.5" stroke="#87E2FC" />
      <defs>
        <filter
          id="filter0_dd_1683_5367"
          x="38.55"
          y="46.4069"
          width="141.88"
          height="126.9"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="20.3926" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.686275 0 0 0 0 0.988235 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1683_5367" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="21.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.474375 0 0 0 0 0.77625 0 0 0 0 0.8625 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1683_5367" result="effect2_dropShadow_1683_5367" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1683_5367" result="shape" />
        </filter>
        <filter
          id="filter1_dd_1683_5367"
          x="0.174999"
          y="0.174999"
          width="217.65"
          height="217.65"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="7.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.686275 0 0 0 0 0.988235 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1683_5367" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="21.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.474375 0 0 0 0 0.77625 0 0 0 0 0.8625 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1683_5367" result="effect2_dropShadow_1683_5367" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1683_5367" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
