import { Box, Stack, useMediaQuery } from "@mui/material";
import { Certic } from "../icons/Certic";
import { Formal } from "../icons/Formal";
import { Gold } from "../icons/Gold";
import { Score } from "../icons/Score";
import { Top } from "../icons/Top";
import { Watched } from "../icons/Watched";

import s from "../HomePage.module.css";

export const CerticInfo = ({ color, fill }: { color?: string; fill?: string }) => {
  const ipad = useMediaQuery("(max-width:750px)");
  const mobile = useMediaQuery("(max-width:420px)");

  return (
    <Stack mt={3}>
      <Stack flexDirection="row" justifyContent={"space-between"} alignItems="center">
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box fontWeight="500" sx={{ color: color, mr: 1 }} fontSize="12px" lineHeight="14.52px" component="div">
            Audit by
          </Box>
          <a style={{ marginRight: "4px" }} href="https://skynet.certik.com/projects/ubd-network">
            <Certic width={mobile ? "70px" : "98px"} height="" fill={fill} />
          </a>
        </Stack>
        <Stack gap={1} flexDirection="row" alignItems="center">
          <Gold className={s.icon} />
          <Formal className={s.icon} />
          <Watched className={s.icon} />
          <Top className={s.icon} />
          <Score className={s.icon} />
        </Stack>
      </Stack>
      <Stack
        mt={2}
        mb={2}
        flexDirection="row"
        gap={19}
        sx={{
          "@media(max-width:400px)": {
            gap: 0,
          },
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box fontWeight="500" color={color} fontSize="12px" component="div">
          Rank #11
        </Box>
        <Box fontSize="12px" fontWeight="500" ml={ipad ? 1 : 0} color={color} component="div" sx={{ display: "flex" }}>
          Security score:
          <Box ml={"2px"} fontSize="12px" fontWeight="600" color={color}>
            86A
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
