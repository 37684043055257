export const Hugei = () => {
  return (
    <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2569_8435)">
        <path
          d="M47.9387 26.801C52.4428 26.801 56.0942 25.6775 56.0942 24.2916C56.0942 22.9057 52.4428 21.7822 47.9387 21.7822C43.4345 21.7822 39.7832 22.9057 39.7832 24.2916C39.7832 25.6775 43.4345 26.801 47.9387 26.801Z"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.0942 29.939C56.0942 31.3254 52.443 32.4483 47.9387 32.4483C43.4344 32.4483 39.7832 31.3254 39.7832 29.939"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.0942 24.292V35.3333C56.0942 36.8577 52.443 38.0936 47.9387 38.0936C43.4344 38.0936 39.7832 36.8577 39.7832 35.3333V24.292"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.1555 18.0188C43.6596 18.0188 47.311 16.8953 47.311 15.5094C47.311 14.1235 43.6596 13 39.1555 13C34.6513 13 31 14.1235 31 15.5094C31 16.8953 34.6513 18.0188 39.1555 18.0188Z"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.0188 24.2916C33.6449 24.003 31.4642 23.2565 31 21.7822M36.0188 30.5651C33.6449 30.2765 31.4642 29.5299 31 28.0557"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.0188 36.839C33.6449 36.5504 31.4642 35.8026 31 34.3296V15.5093M47.311 18.0187V15.5093"
          stroke="#5BFCBB"
          stroke-width="1.88203"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.002 41.9995H19.187C20.069 41.9995 20.939 42.1975 21.728 42.5815L27.854 45.5455C28.643 45.9265 29.513 46.1245 30.398 46.1245H33.524C36.548 46.1245 39.002 48.4975 39.002 51.4255C39.002 51.5455 38.921 51.6475 38.804 51.6805L31.181 53.7895C29.8131 54.1674 28.3546 54.0352 27.077 53.4175L20.528 50.2495"
          stroke="#5BFCBB"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.002 49.4994L52.781 45.2664C53.9805 44.8983 55.2657 44.9185 56.4531 45.3242C57.6405 45.7299 58.6694 46.5002 59.393 47.5254C60.5 49.0554 60.05 51.2514 58.436 52.1814L35.891 65.1924C35.186 65.6003 34.4054 65.8603 33.5967 65.9568C32.7879 66.0532 31.9681 65.984 31.187 65.7534L12.002 60.0594"
          stroke="#5BFCBB"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2569_8435">
          <rect width="72" height="72" fill="white" transform="translate(0 0.453125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
