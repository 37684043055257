import { CSSProperties, createElement } from "react";

import { BaseButtonComponent, BaseButtonProps, IButton } from "./interface";

import s from "./button.module.css";

export default function BaseButton<C extends BaseButtonComponent = "button">({
  component = "button",
  children,
  ...props
}: BaseButtonProps<C> & { children: any }) {
  return createElement(component, props, children);
}

export function Button<C extends BaseButtonComponent = "button">({
  onClick,
  className,
  radius,
  variant = "gray",
  isActive = false,
  boxSize = 1.5,
  left = false,
  icon,
  isLoading = false,
  children,
  ...props
}: IButton<C> & { children: any; onClick?: Function }) {
  return (
    <BaseButton<C>
      onClick={onClick}
      style={{ borderRadius: radius }}
      className={`body1 ${className} ${s.btn} ${s[variant]} ${isActive ? s.active : ""}`}
      {...(props as BaseButtonProps<C>)}
    >
      <span
        style={{
          flexDirection: left ? "row-reverse" : "row",
          justifyContent: left ? "flex-end" : "",
        }}
        className={icon ? s.icons : ""}
      >
        {icon && <img src={icon} style={{ width: `${boxSize}rem` }} />}
        {isLoading ? (
          <div className={s.main}>
            <div className={s.loader} />
          </div>
        ) : (
          children
        )}
      </span>
    </BaseButton>
  );
}

export function IconButton<C extends BaseButtonComponent = "button">({
  icon,
  radius,
  className = "",
  boxSize = 1,
  ...props
}: IButton<C>) {
  const style = { "--icon-size": boxSize } as CSSProperties;

  return (
    <BaseButton<C>
      style={{ borderRadius: radius, ...style }}
      className={`body1 ${className} ${s.btn_icon} ${s.gray}`}
      {...(props as BaseButtonProps<C>)}
    >
      <img src={icon} style={{ width: `${boxSize}rem` }} />
    </BaseButton>
  );
}
