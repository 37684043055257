import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import React from "react";

interface SwapperButtonProps {
  disabled: boolean;
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading: boolean;
}

export const StyledLoadingButton = styled(LoadingButton)`
  & .MuiLoadingButton-loadingIndicator {
    left: auto;
    padding-right: 8px;
  }
`;

const SwapperButton: React.FC<SwapperButtonProps> = ({ disabled, text, onClick, loading }) => (
  <StyledLoadingButton
    disabled={disabled}
    variant="contained"
    size="large"
    sx={{ mt: 4 }}
    fullWidth
    loading={loading}
    loadingPosition="start"
    type="submit"
    onClick={onClick}
  >
    {text}
  </StyledLoadingButton>
);

export default SwapperButton;
