import SvgColor from "../../../components/svg-color";

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  detrust: icon("ic_detrust"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  ubdn: icon("ic_ubdn"),
  ubd: icon("ic_ubd"),
  staking: icon("ic_staking"),
};

const navConfig = [
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
      },
      {
        title: "Referral",
        path: "/referral",
      },
      {
        title: "Leaderboard",
        path: "/leaderboard",
      },
      {
        title: "Claim",
        path: "/claim",
      },
    ],
  },
];

const publicNavConfig = [
  {
    subheader: "general",
    items: [
      {
        title: "Leaderboard",
        path: "/leaderboard",
      },
    ],
  },
];

export default navConfig;
export { publicNavConfig };
