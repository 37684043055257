export const ArrowLine = () => {
  return (
    <svg width="250" height="24" viewBox="0 0 250 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 10.5C0.671573 10.5 0 11.1716 0 12C0 12.8284 0.671573 13.5 1.5 13.5L1.5 10.5ZM249.561 13.0607C250.146 12.4749 250.146 11.5251 249.561 10.9393L240.015 1.3934C239.429 0.807611 238.479 0.807611 237.893 1.3934C237.308 1.97919 237.308 2.92893 237.893 3.51472L246.379 12L237.893 20.4853C237.308 21.0711 237.308 22.0208 237.893 22.6066C238.479 23.1924 239.429 23.1924 240.015 22.6066L249.561 13.0607ZM1.5 13.5L248.5 13.5V10.5L1.5 10.5L1.5 13.5Z"
        fill="url(#paint0_linear_2568_7001)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2568_7001"
          x1="48.913"
          y1="13.86"
          x2="52.5858"
          y2="43.7263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#45D3D1" />
          <stop offset="1" stop-color="#23D98F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ArrowLineMobile = () => {
  return (
    <svg width="23" height="42" viewBox="0 0 23 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1893 40.5743C10.7751 41.1601 11.7249 41.1601 12.3107 40.5743L21.8566 31.0284C22.4424 30.4426 22.4424 29.4929 21.8566 28.9071C21.2708 28.3213 20.3211 28.3213 19.7353 28.9071L11.25 37.3924L2.76472 28.9071C2.17893 28.3213 1.22919 28.3213 0.643398 28.9071C0.0576115 29.4929 0.0576115 30.4426 0.643398 31.0284L10.1893 40.5743ZM12.75 1.51367C12.75 0.685246 12.0784 0.0136719 11.25 0.0136719C10.4216 0.0136719 9.75 0.685246 9.75 1.51367H12.75ZM12.75 39.5137L12.75 1.51367H9.75L9.75 39.5137H12.75Z"
        fill="url(#paint0_linear_2814_3583)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2814_3583"
          x1="13.11"
          y1="34.1198"
          x2="33.6938"
          y2="19.9647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#45D3D1" />
          <stop offset="1" stop-color="#23D98F" />
        </linearGradient>
      </defs>
    </svg>
  );
};
