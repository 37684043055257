import { SxProps, Theme } from "@mui/material";
import { useHeader } from "~/app/ubdn-app/providers/HeaderProvider";
import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/iconify";
import useResponsive from "../../../hooks/useResponsive";
import { useMenuContext } from "./MenuDrawerContext";

export function BurgerButton({ sx }: { sx?: SxProps<Theme> }) {
  const isDesktop = useResponsive("up", "lg");
  const theme = useHeader();

  const { open, setOpen } = useMenuContext();

  return (
    <IconButtonAnimate
      onClick={() => {
        setOpen(true);
      }}
      sx={{
        display: `${!isDesktop ? "visible" : "none"}`,
        width: 45,
        color: theme.pallette.logo,
        height: 45,
        ...(open && {
          bgcolor: "action.selected",
        }),
        ...sx,
      }}
    >
      <Iconify width="50px" height="50px" icon="pepicons-pop:menu" />
    </IconButtonAnimate>
  );
}
