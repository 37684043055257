import { CircularProgress, Step, StepConnector, StepLabel, Stepper, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "~/components/iconify/Iconify";

interface SwapperStepperProps {
  overrideValues: boolean;
  stages: SwapperStepperStage[];
}

const StepCircularProgress = styled(CircularProgress)`
  width: 32px !important;
  height: 32px !important;
`;

const StyledStepLabel = styled(StepLabel)``;

const StyledIcon = styled(Iconify)`
  width: 32px;
  height: 32px;
`;

const LabelContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
  color: unset;
`;

const StyledStepConnector = styled(StepConnector)`
  &.MuiStepConnector-root {
    margin-left: 16px;
  }
`;

export type SwapperStepperStatus = "loading" | "idle" | "success";

export interface SwapperStepperStage {
  name: string;
  title: string;
  status: SwapperStepperStatus;
  icon: string;
  timestamp: number;
  hidden?: boolean;
}

const StepperCounter = ({ timestamp }: { timestamp: number }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime() / 1000;
      const diff = now - timestamp / 1000;
      setSeconds(Math.floor(diff));
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTime = () => {
    // format: MM:SS
    const minutes = Math.floor(seconds / 60);
    const secs = seconds - minutes * 60;

    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secsStr = secs < 10 ? `0${secs}` : secs;

    return `${minutesStr}:${secsStr}`;
  };

  return <Typography variant="body2">{formatTime()}</Typography>;
};

const StepCircularProgressWrapper: React.FC<any> = ({ active, completed, error, ...rest }) => {
  return <StepCircularProgress {...rest} />;
};

const StyledIconWrapper: React.FC<any> = ({ active, completed, error, ...rest }) => {
  return <StyledIcon icon={rest.icon} style={{ color: rest.style?.color }} />;
};

const SwapperStepper: React.FC<SwapperStepperProps> = ({ overrideValues, stages }: SwapperStepperProps) => {
  const renderStage = (stage: SwapperStepperStage, index: number) => {
    if (overrideValues) stage.status = "idle";

    const stepIconComponent = stage.status === "loading" ? StepCircularProgressWrapper : StyledIconWrapper;
    const stepIconProps =
      stage.status === "loading"
        ? {}
        : {
            icon: stage.icon,
            style: stage.status === "success" ? { color: "#00AB55" } : {},
          };

    if (stage.hidden) return null;

    return (
      <Step key={index}>
        <StyledStepLabel StepIconComponent={stepIconComponent} StepIconProps={stepIconProps}>
          <LabelContainer style={stage.status === "success" ? { color: "#00AB55" } : {}}>
            {stage.title}
            {stage.status === "success" ? (
              <Iconify icon="ph:check-bold" color="#00AB55" width="24px" height="24px" />
            ) : (
              <Typography variant="body2" color="text.primary">
                {stage.status === "loading" ? <StepperCounter timestamp={stage.timestamp} /> : ""}
              </Typography>
            )}
          </LabelContainer>
        </StyledStepLabel>
      </Step>
    );
  };

  return (
    <Stepper orientation="vertical" sx={{ mt: "26px" }} connector={<StyledStepConnector />}>
      {stages.map((stage, index) => renderStage(stage, index))}

      {/* <Step>
        <StyledStepLabel StepIconComponent={StepCircularProgress}>
          <LabelContainer>
            Step 1
            <Iconify
              icon="ph:check-bold"
              color="#00AB55"
              width="24px"
              height="24px"
            />
          </LabelContainer>
        </StyledStepLabel>
      </Step>
      <Step>
        <StyledStepLabel
          StepIconComponent={StyledIcon}
          StepIconProps={{
            icon: "cryptocurrency-color:usdt",
          }}
        >
          <LabelContainer>
            Step 1
            <Typography variant="body2" color="text.primary">
              00:01
            </Typography>
          </LabelContainer>
        </StyledStepLabel>
      </Step> */}
    </Stepper>
  );
};

export default SwapperStepper;
