import { CustomToken, getTokens } from "./web3-constants";

// TODO: Review
export const resolveDecimals = (select: string | undefined): number => {
  switch (select) {
    case "UBD":
    case "UBDN":
    case "DAI":
      return 18;
    case "USDT":
    case "USDC":
      return 6;
    default:
      return 1;
  }
};

export const resolveDecimalsWithCustom = (chainId: number, select: string | undefined): number => {
  const customToken = resolveCustomToken(chainId, select);
  if (customToken) return customToken.decimals;
  return resolveDecimals(select);
};

export const resolveToken = (chainId: number, tokenAddr: string) => {
  const tokens = getTokens(chainId); // {name: addr}
  const token = Object.entries(tokens).find(([_, addr]) => addr.toLowerCase() === tokenAddr.toLowerCase());
  if (token) return token[0];
  return undefined;
};

export const resolveAnyToken = (chainId: number, tokenAddr: string) => {
  const defaultToken = resolveToken(chainId, tokenAddr);
  if (defaultToken) return defaultToken;
  const customToken = resolveCustomToken(chainId, undefined, tokenAddr);
  if (customToken) return customToken.symbol;
  return undefined;
};

export const resolveCustomToken = (chainId: number, symbol?: string, address?: string) => {
  if (typeof window === "undefined") return undefined;
  const tokens: CustomToken[] = JSON.parse(localStorage.getItem("customTokens") || "[]");
  const token = tokens.find(
    (t) => (t.symbol === symbol || t.address.toLowerCase() === address?.toLowerCase()) && t.chainId === chainId,
  );
  if (token) return token;
  return undefined;
};

export const prettifyBalance = (balance: string | undefined) => {
  if (balance && balance.includes(".") === false) return balance;
  const splittedBalance = balance?.split(".");
  if (!splittedBalance || splittedBalance.length !== 2) return "0";
  return `${splittedBalance[0]}.${splittedBalance[1].slice(0, 2)}`;
};
