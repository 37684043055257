import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "cookies-js";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "~/modules/Container/Container";
import { Gradient } from "../gradientJs/Gradient";
import gradientStyle from "../gradientJs/gradient.module.css";
import s from "./HomePage.module.css";

import { InfoHome } from "./InfoBlock/InfoHome";
import { TimerComponent } from "./TimerComponent/TimerComponent";

import cn from "classnames";
import { FeatureHighlights } from "./FeatureHighlights/FeatureHighlights";
import { MassiveListings } from "./MassiveListings/MassiveListings";

export const initGradient = () => {
  const gradient = new Gradient();
  gradient.initGradient("#gradient-canvas");
};

export const getStats = async () => {
  const { data } = await axios.get(`${import.meta.env.VITE_APP_API}/stats`);
  return data;
};

export const formatDistributedAmount = (rawDistributedAmount: string) => {
  const num = (Number(rawDistributedAmount) / 10 ** 18 || 0).toFixed(0);
  if (num.length < 3) return 5701494;
  const finalNum = Number(num) + 5_000_000;
  if (isNaN(finalNum)) return 5701494;
  return finalNum;
};

export default function HomePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const media = useMediaQuery("(max-width:1075px)");
  const ipad = useMediaQuery("(max-width:750px)");

  useLayoutEffect(() => {
    if (id?.length !== 6) return;
    if (id) {
      Cookies.set("inviter_id", id);
    }
  }, [id]);

  const [promoInfo, setPromoInfo] = useState({
    tokenMaked: 5701494,
    tokenTotal: 7000000,
    tokenPrice: 0.1,
    maxAmount: 7000000,
    tokenNextPrice: 4,
  });

  const { data: stats } = useQuery<any>({
    queryKey: ["generalStats"],
    queryFn: getStats,
  });

  useEffect(() => {
    initGradient();
    if (stats) {
      let tokenTotal = 0;
      let limit = 20;
      while (tokenTotal < formatDistributedAmount(stats?.distributedAmount) && limit) {
        tokenTotal += 1000000;
        limit -= 1;
      }
      setPromoInfo({
        tokenMaked: formatDistributedAmount(stats?.distributedAmount.toString()),
        tokenTotal: tokenTotal,
        tokenPrice: tokenTotal / 1000000 - 4,
        maxAmount: tokenTotal,
        tokenNextPrice: tokenTotal / 1000000 - 3,
      });
    }
  }, [stats]);

  return (
    <>
      <Box>
        <Stack>
          <Box
            sx={{
              top: 0,
              left: 0,
              zIndex: 0,
              width: "100%",
            }}
          >
            <canvas
              id="gradient-canvas"
              data-transition-in
              style={{
                width: "100%",
                height: "720px",
                position: "absolute",
                zIndex: -1,
              }}
              className={cn(gradientStyle.gradient, s.gradientCanv)}
            />
            <Container>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                mb={8}
                sx={{
                  "@media(max-width:750px)": {
                    flexDirection: "column-reverse",
                    justifyContent: "center",
                  },
                }}
              >
                <Box
                  sx={{
                    mr: 3,
                    "@media(max-width:1075px)": {
                      mt: ipad ? "0" : "20px",
                    },
                    "@media(max-width:750px)": {
                      mr: 0,
                    },
                  }}
                  mt={"100px"}
                >
                  <Typography
                    sx={{
                      display: ipad ? "none" : "block",
                      fontSize: "46px",
                      color: "rgba(39, 56, 82, 1)",
                      fontWeight: "700",
                      "@media(max-width:1287px)": {
                        fontSize: "40px",
                        lineHeight: "45px",
                      },

                      "@media(max-width:1075px)": {
                        fontSize: "30px",
                      },

                      "@media(max-width:880px)": {
                        fontSize: "24px",
                      },
                    }}
                  >
                    {media ? "DeTrust Multisig Wallet" : "DeTrust - Professional Multisig wallet"}
                  </Typography>
                  <Box
                    sx={{
                      display: ipad ? "none" : "block",
                      maxWidth: "912px",
                      "@media(max-width:1287px)": {
                        maxWidth: "812px",
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: 500,
                      },
                      "@media(max-width:880px)": {
                        fontSize: "16px",
                      },
                    }}
                    mt={1.5}
                    color="rgba(39, 56, 82, 1)"
                    fontSize="24px"
                    lineHeight="36px"
                    component="p"
                  >
                    The nextgen multisig platform with delayed transfer of crypto assets.
                    Over&nbsp;1,240,000&nbsp;users&nbsp;in $160,000,000,000 market leading the Web3.0
                  </Box>
                  <FeatureHighlights />
                  <MassiveListings />
                </Box>
                <Box sx={{ marginLeft: ipad ? "" : "auto", margin: ipad ? "0 auto" : "" }}>
                  <Typography
                    sx={{
                      display: ipad ? "block" : "none",
                      fontSize: ipad ? "30px" : "46px",
                      mt: ipad ? "40px" : 0,
                      color: "rgba(39, 56, 82, 1)",
                      fontWeight: "700",
                      "@media(max-width:1287px)": {
                        fontSize: "40px",
                        lineHeight: "45px",
                      },

                      "@media(max-width:1075px)": {
                        fontSize: "30px",
                      },

                      "@media(max-width:880px)": {
                        fontSize: "24px",
                      },
                      "@media(max-width:750px)": {
                        fontSize: "30px",
                        fontWeight: 700,
                      },
                    }}
                  >
                    {media ? "DeTrust Multisig Wallet" : "DeTrust - Professional Multisig wallet"}
                  </Typography>
                  <Box
                    sx={{
                      display: ipad ? "block" : "none",
                      maxWidth: "912px",
                      "@media(max-width:1287px)": {
                        maxWidth: "812px",
                        fontSize: "20px",
                        lineHeight: "30px",
                        fontWeight: 500,
                      },
                      "@media(max-width:880px)": {
                        fontSize: "16px",
                      },
                    }}
                    mt={1.5}
                    color="rgba(39, 56, 82, 1)"
                    fontSize="24px"
                    lineHeight="36px"
                    component="p"
                  >
                    The nextgen multisig platform with delayed transfer of crypto assets.
                    Over&nbsp;1,240,000&nbsp;users&nbsp;in $160,000,000,000 market leading the Web3.0
                  </Box>
                  <Box sx={{ width: "min(100%, 395px)", margin: ipad ? "0 auto" : "", maxWidth: "395px" }}>
                    <TimerComponent
                      tokenPrice={promoInfo.tokenPrice}
                      fill="#273852"
                      color="rgba(39, 56, 82, 1)"
                      navigate={navigate}
                    />
                    {/* <TokenPriceInfo {...promoInfo} /> */}
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Box>
        </Stack>
      </Box>
      <InfoHome />
    </>
  );
}
