import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { useDataLayer } from "~/hooks/useDataLayer";
import { SOCIAL_ROUTE } from "~/shared/const";
import { instance } from "~/shared/lib";

export const useSignRegister = () => {
  const { signMessageAsync, error } = useSignMessage();
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { refetchAuth } = useWallet();
  const { disconnect } = useDisconnect({});

  const errorFallback = (message: string = "", info: any = {}) => {
    enqueueSnackbar(message, info);
    disconnect();
  };

  const signRegister = async () => {
    setLoading(true);
    const signData = await signMessageAsync({ message: 'LOGIN_REQUEST' });
    if (!signData) return errorFallback("Failed to sign message", { variant: "error" });
    const { setDataLayer } = useDataLayer();
    const response = await instance.post("/auth/register", {
      address: address!.toLowerCase(),
      signature: signData,
      inviteCode: localStorage.getItem("code"),
      utmTags: localStorage.getItem("utmTags") || "",
      httpReferrer: localStorage.getItem("httpReferrer") || "",
    });
    if (response.data.ok == true) {
      localStorage.setItem(
        "jwt",
        JSON.stringify({
          address: address!.toLowerCase(),
          token: response.data.details,
        }),
      );
      setDataLayer("signUp");
    } else errorFallback(response.data.details, { variant: "error" });

    refetchAuth();
    setLoading(false);

    if (response.data.ok) {
      window.location.replace(SOCIAL_ROUTE);
    }
  };

  useEffect(() => {
    if (error) {
      errorFallback(error.message, { variant: "error" });
      setLoading(false);
    }
  }, [error]);

  return { signRegister, loading };
};
