import axios, { AxiosInstance } from "axios";

import { BACKEND_URL } from "~/utils/utils";
import { getJwtInfo } from "../utils/jwt";
import { sepolia } from "viem/chains";

export const instance: AxiosInstance = axios.create({
  baseURL: "REPLACE", // BACKEND_URL, //import.meta.env.VITE_BD_URL;
});

instance.interceptors.request.use((config) => {
  let selectedBackend = BACKEND_URL;
  if (window.chainId == sepolia.id) selectedBackend = import.meta.env.VITE_API_URL_SEPOLIA;

  const jwt = getJwtInfo();
  config.headers["Authorization"] = `Bearer ${jwt?.token}`;

  config.baseURL = selectedBackend;
  config.url = config.url?.replace("REPLACE", selectedBackend);

  return config;
});

export interface InstanceResponse<T> {
  ok: boolean;
  details: T;
}
