import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { CopyIcon } from "~/assets/CopyIcon";
import { TableHeadCustom, TablePaginationCustom, useTable } from "~/components/table";
import { CreateReferral } from "~/features";
import { EditReferral } from "~/features/EditReferral";
import { useIsDesktop } from "~/hooks/useIsDesktop";
import { useStats } from "~/screens/ClaimStats/hooks/useStats";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";
import { getJwtInfo } from "~/shared/utils/jwt";
import { trimAddress } from "~/shared/utils/string";
import { UserEarn, UserInviteLink } from "./types";

const getInviteLinks = async () => {
  const { data } = await instance.get<InstanceResponse<UserInviteLink[]>>("/links/all");
  return data.details;
};

const getUserEarns = async () => {
  const { data } = await instance.get<InstanceResponse<UserEarn[]>>("/earns/all");
  const prettyDate = data.details.map((v) => {
    let date = v?.date;
    try {
      date = v?.date.slice(0, 19).replace("T", " ");
    } catch (e) {}
    return {
      ...v,
      date,
    };
  });
  const sorted = prettyDate.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
  return sorted;
};

export default function ReferralPage() {
  const { authInfo } = useWallet();
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({ defaultRowsPerPage: 10 });

  const isAuthorized = !!getJwtInfo();

  const stats = useStats();

  const { data: inviteLinksData, isLoading: isLoadingInviteLinks } = useQuery({
    queryKey: ["inviteLinks"],
    queryFn: getInviteLinks,
    enabled: isAuthorized,
  });

  const { data: earnsData, isLoading: isLoadingEarns } = useQuery({
    queryKey: ["earns"],
    queryFn: getUserEarns,
    enabled: isAuthorized,
  });

  const getEarnsData = () => {
    return earnsData!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  if (!isAuthorized) {
    return (
      <Stack gap={10} pt={2}>
        <Stack gap={1}>
          <Stack
            flexDirection={isDesktop ? "row" : "column"}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            pb={2}
          >
            <Typography variant="h4">INVITES</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"} gap={2}>
            <Stack>
              <Typography variant="body2">
                Invite your friends via referral link to earn instant rewards in USDT.
              </Typography>
              <Typography variant="body2">
                Get 10% of each UBDN swap made by your referrals, and additional 5% on 2nd level token purchases.
              </Typography>
            </Stack>
            <Link to={"/referral-faq"}>
              <Button variant="soft">Referral F.A.Q.</Button>
            </Link>
          </Stack>
          <Stack flex={1} flexDirection={"row"} justifyContent={"center"} py={10}>
            <Link to="/dashboard">
              <Button
                variant="contained"
                sx={{
                  px: 10,
                }}
              >
                Connect
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={10}>
      <Stack gap={2}>
        <Card
          sx={{
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoadingInviteLinks && <CircularProgress />}
          {!isLoadingInviteLinks && (inviteLinksData?.length ?? 0) > 0 && (
            <TableContainer>
              <Table>
                <TableHeadCustom
                  headLabel={[
                    { id: "Name", label: "Name" },
                    { id: "Link address", label: "Link address" },
                    { id: "Referral lvl 1", label: "Referral lvl 1" },
                    { id: "Referral lvl 2", label: "Referrals lvl 2" },
                  ]}
                />

                <TableBody>
                  {inviteLinksData &&
                    inviteLinksData.length &&
                    inviteLinksData.map((invite, index: number) => (
                      <TableRow
                        key={invite._id}
                        sx={{
                          transition: "all 0.3s ease-in-out",
                          ...(index !== inviteLinksData?.length - 1 && {
                            borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
                          }),
                          "&:hover": {
                            background: theme.palette.background.default,
                          },
                        }}
                      >
                        <TableCell>
                          <EditReferral referral={invite} />
                        </TableCell>
                        <TableCell>
                          <Stack flexDirection={"row"} alignItems={"center"}>
                            <IconButton
                              onClick={() => navigator.clipboard.writeText(`${window.location.origin}/${invite.code}`)}
                            >
                              <CopyIcon style={{ color: theme.palette.text.secondary }} />
                            </IconButton>
                            {window.location.origin}/{invite.code}
                          </Stack>
                        </TableCell>
                        <TableCell>{invite.usersFirstLevel}</TableCell>
                        <TableCell>{invite.usersSecondLevel}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
        <CreateReferral />
      </Stack>
      <Stack gap={2}>
        <Stack flexDirection={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"} pb={2}>
          <Stack
            width="100%"
            flexDirection={"row"}
            sx={{
              "@media(max-width:790px)": {
                flexDirection: "column",
              },
            }}
            gap={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">YOUR REFERRAL EARNINGS</Typography>
            <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 1.5,
                  px: 3,
                  justifyContent: "center",
                  alignItems: "center",
                  "@media(max-width:525px)": {
                    py: 1,
                    px: 2,
                  },
                }}
              >
                <Typography
                  sx={{ "@media(max-width:525px)": { fontSize: "12px" } }}
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  earned for you by referrals
                </Typography>
                <Typography sx={{ "@media(max-width:525px)": { fontSize: "12px" } }} variant="body2">
                  <span style={{ fontWeight: 700 }}>{authInfo?.usdtBalance || "0"}</span> USDT
                </Typography>
              </Card>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  py: 1.5,
                  px: 3,
                  justifyContent: "center",
                  alignItems: "center",
                  "@media(max-width:525px)": {
                    py: 1,
                    px: 2,
                  },
                }}
              >
                <Typography
                  sx={{ "@media(max-width:525px)": { fontSize: "12px" } }}
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  earned for you by referrals
                </Typography>
                <Typography sx={{ "@media(max-width:525px)": { fontSize: "12px" } }} variant="body2">
                  <span style={{ fontWeight: 700 }}>{authInfo?.referralPoints || "0"}</span> POINTS
                </Typography>
              </Card>
            </Stack>
          </Stack>
          {(Number(stats.UBD) > 0 || Number(stats.USDT) > 0 || Number(stats.UBDN) > 0) && (
            <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                You have a reward to claim!
              </Typography>
              <Link to={"/claim"}>
                <Button variant="contained">Claim</Button>
              </Link>
            </Stack>
          )}
        </Stack>
        {!isLoadingEarns && !earnsData?.length && (
          <Typography variant="body1" textAlign={"center"}>
            Invite your friends to get more referral earnings
          </Typography>
        )}
        {!isLoadingEarns && !!earnsData?.length && (
          <Card>
            <TableContainer>
              <Table>
                <TableHeadCustom
                  headLabel={[
                    { id: "Date", label: "Date" },
                    { id: "Address", label: "Address" },
                    { id: "USDT reward", label: "USDT reward" },
                    { id: "Points reward", label: "Points reward" },
                    { id: "Description", label: "Description" },
                  ]}
                />

                <TableBody>
                  {earnsData &&
                    earnsData.length &&
                    getEarnsData().map((earning, index: number) => (
                      <TableRow
                        key={earning._id}
                        sx={{
                          ...(index !== earnsData?.length - 1 && {
                            borderBottom: `0.5px solid ${theme.palette.text.secondary}`,
                          }),
                        }}
                      >
                        <TableCell sx={{ width: "25%" }}>{earning?.date}</TableCell>
                        <TableCell>
                          {isDesktop
                            ? earning?.address ?? "UBD Network"
                            : trimAddress(earning?.address || "UBD Network")}
                        </TableCell>
                        <TableCell>{earning?.amount || "-"}</TableCell>
                        <TableCell>{earning?.points}</TableCell>
                        <TableCell>{earning?.description ?? "-"}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePaginationCustom
                count={earnsData!.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </TableContainer>
          </Card>
        )}
      </Stack>
    </Stack>
  );
}
