import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { Ball } from "../../icons/Ball";
import { Check } from "../../icons/Check";
import { Line } from "../../icons/Line";

import s from "../../InfoHome.module.css";
import { ArrowLine } from "../../icons/ArrowLine";

export const WalletsGrid = () => {
  const ipad = useMediaQuery("(max-width:800px)");
  return (
    <Box>
      <Typography textAlign={ipad ? "center" : "left"} color="#F1F1F3" variant="h3">
        Crypto wallets evolution
      </Typography>
      <Grid container spacing={1} rowGap={5} justifyContent="center">
        <Grid item xs={3.5}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check className={s.firstIcon} />
              <Line />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              Bitcoin core and first “cold” wallets
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check />
              <Line />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
              ml="-50px"
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              Paper wallets and “brain” wallets
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check />
              <Line />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
              ml="-50px"
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              Exchanges and custodial services
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1.8}>
          <Stack flexDirection="row" justifyContent="center" alignItems="center">
            <Check className={s.checkIcon} />
          </Stack>
          <Box
            sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
            ml="0"
            textAlign="center"
            width="min(100%, 208px)"
            fontSize="20px"
            color="#F1F1F3"
          >
            Web wallets <br /> & plug-ins
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check className={s.firstIcon} />
              <Line />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              Mobile wallets
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check />
              <Line />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px", width: "min(100%, 180px)" } }}
              ml="-50px"
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              Hardware wallets
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Stack flexDirection="row" alignItems="center">
              <Check />
              <ArrowLine />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px" } }}
              ml="-50px"
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              DeFi & NFT integrated wallets
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1.8}>
          <Box>
            <Box fontWeight="600" color="#fff" textAlign="center">
              We are here now!
            </Box>
            <Stack flexDirection="row" justifyContent="center" alignItems="center">
              <Ball />
            </Stack>
            <Box
              sx={{ "@media(max-width:1020px)": { fontSize: "16px" } }}
              ml="0px"
              textAlign="center"
              width="min(100%, 208px)"
              fontSize="20px"
              color="#F1F1F3"
            >
              DeTrust Multi-sig revolution!
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

