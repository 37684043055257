import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { InstanceResponse, instance } from "~/shared/lib/axios";

export const useCombine = () => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const combine = async () => {
    setLoading(true);
    try {
      const { data } = await instance.get<InstanceResponse<null>>("/claims/request/combine");
      if (data.ok) {
        queryClient.invalidateQueries({
          queryKey: ["withdrawals"],
        });
        enqueueSnackbar("Claims combined successfully", {
          variant: "success",
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, combine };
};
