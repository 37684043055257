import { useEffect } from "react";
import { useAccount, useReadContract } from "wagmi";
import UBDNLockerDistributor from "~/app/ubdn-app/abi/UBDNLockerDistributor.json";
import { getContracts } from "../web3-constants";

export const useLockedBalance = () => {
  const { address, chainId } = useAccount();
  const { data, error, refetch } = useReadContract({
    functionName: "getUserAvailableAmount",
    args: [address],
    address: getContracts(chainId).LOCKER,
    abi: UBDNLockerDistributor,
  });

  useEffect(() => {
    refetch();
  }, [address]);

  console.log(data, error);

  return data ? ((data as any)[0] as bigint) : 0n;
};
