import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { instance } from "~/shared/lib";
import { InstanceResponse } from "~/shared/lib/axios";

export const useRequest = (ubd: number, usdt: number) => {
  const createClaim = async () => {
    const response = (await instance.get<InstanceResponse<null>>("/claims/request")).data;
    if (response.ok) {
      (window as any).dataLayer.push({
        event: "claimubdSuccess",
        amount: ubd,
      });
      (window as any).dataLayer.push({
        event: "claimusdtSuccess",
        amount: usdt,
      });
    }

    return response;
  };

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { refetchAuth } = useWallet();

  const { mutate, isPending } = useMutation({
    mutationFn: () => createClaim(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["withdrawals"],
      });
      refetchAuth();
      enqueueSnackbar("Claim request created successfully", {
        variant: "success",
      });
    },
    onError: (err) => {
      enqueueSnackbar(err.message.replace(/^(.*?)\sRequest/, ""), {
        variant: "error",
      });
    },
  });

  return {
    mutate,
    loading: isPending,
  };
};
