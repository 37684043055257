import { Box, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import Iconify from "~/components/iconify";
import Scrollbar from "~/components/scrollbar";
import { bgBlur } from "~/utils/cssStyles";
import { useSettingsContext } from "../SettingsContext";
import { defaultSettings } from "../config-setting";
import BadgeDot from "./BadgeDot";
import Block from "./Block";
import ColorPresetsOptions from "./ColorPresetsOptions";
import ModeOptions from "./ModeOptions";

const SPACING = 2.5;

export default function SettingsDrawer() {
  const {
    themeMode,
    themeLayout,
    themeStretch,
    themeContrast,
    themeDirection,
    themeColorPresets,
    onResetSetting,
    showSettingsDrawer,
    toggleShowSettingsDrawer,
  } = useSettingsContext();
  const theme = useTheme();

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch ||
    themeContrast !== defaultSettings.themeContrast ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets;

  const [network, setNetwork] = useState("sepolia");

  return (
    <Drawer
      anchor="bottom"
      open={showSettingsDrawer}
      onClose={toggleShowSettingsDrawer}
      BackdropProps={{ invisible: true }}
      PaperProps={{
        sx: {
          ...bgBlur({
            color: theme.palette.background.default,
            opacity: 0.9,
          }),
          width: "100%",
          height: "400px",
          boxShadow: `-24px 12px 40px 0 ${alpha(
            theme.palette.mode === "light" ? theme.palette.grey[500] : theme.palette.common.black,
            0.16,
          )}`,
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: SPACING }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          Settings
        </Typography>

        <Tooltip title="Reset">
          <Box sx={{ position: "relative" }}>
            {notDefault && <BadgeDot />}
            <IconButton onClick={onResetSetting}>
              <Iconify icon="ic:round-refresh" />
            </IconButton>
          </Box>
        </Tooltip>

        <IconButton onClick={toggleShowSettingsDrawer}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Scrollbar sx={{ p: SPACING, pb: 0 }}>
        <Block title="Mode">
          <ModeOptions />
        </Block>

        {/* <Block title="Contrast">
            <ContrastOptions />
          </Block> */}

        {/* <Block title="Direction">
            <DirectionOptions />
          </Block>

          <Block title="Layout">
            <LayoutOptions />
          </Block>

          <Block
            title="Stretch"
            tooltip="Only available at large resolutions > 1600px (xl)"
          >
            <StretchOptions />
          </Block> */}

        <Block title="Presets">
          <ColorPresetsOptions />
        </Block>
      </Scrollbar>
      {/* <Box sx={{ p: SPACING, pt: 0 }}>
          <FullScreenOptions />
        </Box> */}
    </Drawer>
  );
}
