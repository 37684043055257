export const Bitget = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="91"
      height="23"
      viewBox="0 0 74 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2155_8003)">
        <path
          d="M72.2724 8.95696C72.3437 8.95696 72.4253 8.97734 72.4762 9.03848C72.5272 9.09962 72.5577 9.17094 72.5475 9.25246V11.0356C72.5475 11.1171 72.5272 11.1885 72.4762 11.2496C72.4253 11.3005 72.3437 11.3311 72.2724 11.3311H70.7847V18.423C70.7847 18.5045 70.7644 18.5759 70.7134 18.637C70.6625 18.6879 70.581 18.7185 70.5096 18.7185H68.2781C68.2068 18.7185 68.1253 18.6981 68.0743 18.637C68.0234 18.5759 67.9928 18.5045 68.003 18.423V11.3311H66.5153C66.444 11.3311 66.3625 11.3107 66.3116 11.2496C66.2606 11.1885 66.23 11.1171 66.2402 11.0356V9.25246C66.2402 9.17094 66.2606 9.09962 66.3116 9.03848C66.3625 8.98753 66.444 8.95696 66.5153 8.95696H68.003V6.46053C68.003 6.37902 68.0234 6.30769 68.0743 6.24656C68.1253 6.19561 68.2068 6.16504 68.2781 6.16504H70.5096C70.581 6.16504 70.6625 6.18542 70.7134 6.24656C70.7644 6.30769 70.7949 6.37902 70.7847 6.46053V8.95696H72.2724Z"
          fill="#273852"
        />
        <path
          d="M65.8523 13.6643C65.8523 14.0923 65.8116 14.4082 65.7199 14.6018C65.6281 14.7954 65.4549 14.8769 65.1798 14.8769H59.6571C59.7692 15.3558 60.0545 15.7838 60.4519 16.0691C60.8595 16.334 61.3689 16.4563 62.0007 16.4665C62.2962 16.4665 62.5917 16.4257 62.877 16.3442C63.1929 16.2321 63.4985 16.0894 63.7737 15.8958C63.8756 15.7939 64.0488 15.7939 64.1507 15.8958C64.1609 15.906 64.171 15.9264 64.1812 15.9366L65.1492 16.9148C65.2002 16.9657 65.2206 17.0371 65.2206 17.1084C65.2104 17.2001 65.1798 17.2816 65.1085 17.3428C64.6703 17.8115 64.1507 18.1783 63.5597 18.4432C62.9789 18.698 62.3471 18.8202 61.7154 18.81C60.8289 18.8202 59.9628 18.5961 59.1986 18.1477C58.4751 17.7096 57.8841 17.088 57.4867 16.3442C57.0792 15.5698 56.8652 14.7139 56.8754 13.8376C56.8652 12.9613 57.069 12.1053 57.4664 11.3309C57.8536 10.5973 58.4344 9.97574 59.1374 9.54778C59.8405 9.09944 60.6659 8.87527 61.5014 8.88546C62.3064 8.87527 63.091 9.09944 63.7737 9.53759C64.436 9.96555 64.9658 10.5769 65.3021 11.28C65.6791 12.0238 65.8625 12.839 65.8523 13.6643ZM61.532 11.3717C61.0734 11.3615 60.6353 11.5144 60.2888 11.7997C59.9424 12.0748 59.7182 12.4722 59.6163 13.0122H63.3763C63.2947 12.4824 63.0808 12.0748 62.7445 11.7997C62.4083 11.5144 61.9803 11.3615 61.532 11.3717Z"
          fill="#273852"
        />
        <path
          d="M55.6639 9.09944C55.7352 9.09944 55.8168 9.11982 55.8677 9.18096C55.9187 9.2421 55.9492 9.31342 55.939 9.39494V18.6266C55.939 19.5743 55.6945 20.3487 55.1952 20.9396C54.7163 21.5306 54.0744 21.979 53.3509 22.2133C52.6173 22.4579 51.853 22.5903 51.0786 22.5802C50.3144 22.5802 49.5502 22.4681 48.8268 22.2235C48.1746 22.0299 47.5633 21.7242 47.0232 21.3167C46.9519 21.2657 46.9111 21.1842 46.9111 21.1027C46.9111 21.0415 46.9213 20.9804 46.9519 20.9295L47.6753 19.5539C47.7467 19.452 47.8282 19.401 47.8995 19.401C47.9505 19.401 48.0014 19.4112 48.0524 19.4418C48.4599 19.6965 48.8981 19.8901 49.3566 20.0124C49.8457 20.1347 50.3552 20.1958 50.8647 20.1958C51.4658 20.206 52.067 20.0532 52.5969 19.7679C53.1165 19.4927 53.3815 19.024 53.3815 18.3719V17.465C52.658 18.3311 51.7715 18.7591 50.7016 18.7591C49.8967 18.7693 49.1121 18.5349 48.4497 18.0968C47.7772 17.6484 47.227 17.0269 46.8704 16.2932C46.1265 14.7342 46.1265 12.9205 46.8704 11.3513C47.227 10.6177 47.7772 9.99612 48.4497 9.54778C49.1121 9.09944 49.8967 8.87527 50.7016 8.88546C51.7206 8.88546 52.5867 9.31342 53.3101 10.1592L53.3611 9.39494C53.3815 9.20134 53.4834 9.09944 53.6362 9.09944H55.6639ZM51.0583 16.0793C51.6187 16.0793 52.1587 15.8245 52.5052 15.3864C52.8924 14.9482 53.1064 14.3878 53.1064 13.807C53.1165 13.2262 52.9026 12.6658 52.5154 12.2378C52.1587 11.7997 51.6289 11.5449 51.0685 11.5551C50.508 11.5449 49.968 11.7895 49.6012 12.2174C49.2038 12.6454 48.9898 13.216 49.0102 13.807C48.9898 14.4082 49.1936 14.989 49.591 15.4373C49.9476 15.8551 50.4877 16.0894 51.0583 16.0793Z"
          fill="#273852"
        />
        <path
          d="M45.372 8.95696C45.4434 8.95696 45.5249 8.97734 45.5758 9.03848C45.6268 9.09962 45.6573 9.17094 45.6471 9.25246V11.0356C45.6471 11.1171 45.6268 11.1885 45.5758 11.2496C45.5249 11.3005 45.4434 11.3311 45.372 11.3311H43.8844V18.423C43.8844 18.5045 43.864 18.5759 43.813 18.637C43.7621 18.6879 43.6806 18.7185 43.6092 18.7185H41.3777C41.3064 18.7185 41.2249 18.6981 41.174 18.637C41.123 18.5759 41.0924 18.5045 41.1026 18.423V11.3311H39.615C39.5436 11.3311 39.4621 11.3107 39.4112 11.2496C39.3602 11.1885 39.3297 11.1171 39.3398 11.0356V9.25246C39.3398 9.17094 39.3602 9.09962 39.4112 9.03848C39.4621 8.98753 39.5436 8.95696 39.615 8.95696H41.1026V6.46053C41.1026 6.37902 41.123 6.30769 41.174 6.24656C41.2249 6.19561 41.3064 6.16504 41.3777 6.16504H43.6092C43.6806 6.16504 43.7621 6.18542 43.813 6.24656C43.864 6.30769 43.8946 6.37902 43.8844 6.46053V8.95696H45.372Z"
          fill="#273852"
        />
        <path
          d="M36.9042 8.15175C36.5068 8.16194 36.1196 7.99891 35.8445 7.70341C35.5591 7.39773 35.4063 7.00034 35.4165 6.58257C35.4063 6.1648 35.5591 5.76741 35.8445 5.46172C36.3743 4.88092 37.271 4.82997 37.862 5.35983C37.9027 5.3904 37.9333 5.43115 37.9639 5.46172C38.2492 5.76741 38.402 6.1648 38.3918 6.58257C38.402 7.00034 38.2492 7.39773 37.9639 7.70341C37.6888 7.99891 37.3016 8.16194 36.9042 8.15175ZM35.5082 9.24203C35.5082 9.16051 35.5286 9.08919 35.5795 9.02805C35.6305 8.9771 35.712 8.94653 35.7833 8.94653H38.025C38.0963 8.94653 38.1779 8.96691 38.2288 9.02805C38.2797 9.08919 38.3103 9.16051 38.3001 9.24203V18.4126C38.3001 18.4941 38.2797 18.5654 38.2288 18.6266C38.1779 18.6775 38.0963 18.7081 38.025 18.7081H35.7833C35.712 18.7081 35.6305 18.6877 35.5795 18.6266C35.5286 18.5654 35.498 18.4941 35.5082 18.4126V9.24203Z"
          fill="#273852"
        />
        <path
          d="M31.9328 11.3913C32.6358 11.5951 33.2574 12.0129 33.7159 12.5835C34.2254 13.1949 34.5005 13.9693 34.4801 14.764C34.4801 16.0887 34.0725 17.0771 33.2676 17.7394C32.4728 18.3915 31.2602 18.7176 29.6197 18.7176H25.0446C24.9733 18.7176 24.8918 18.6972 24.8409 18.6361C24.7899 18.5749 24.7593 18.5036 24.7695 18.4221V5.2878C24.7695 5.20629 24.7899 5.13496 24.8409 5.07383C24.8918 5.02288 24.9733 4.99231 25.0446 4.99231H29.5994C30.4145 4.99231 31.1176 5.14515 31.6984 5.46103C32.2588 5.74633 32.7275 6.18448 33.0332 6.73471C33.3491 7.28495 33.5121 7.90651 33.5019 8.53826C33.5121 9.15982 33.3593 9.78137 33.0332 10.3214C32.7479 10.8411 32.3811 11.1875 31.9328 11.3913ZM30.8119 8.93565C30.8119 8.10011 30.3228 7.68234 29.3446 7.68234H27.6532V10.3112H29.3446C29.8031 10.3112 30.1598 10.1991 30.4247 9.97498C30.6794 9.74062 30.8119 9.39417 30.8119 8.93565ZM29.7318 16.0275C30.3024 16.0275 30.7508 15.8951 31.087 15.62C31.4233 15.3448 31.5863 14.9373 31.5863 14.387C31.5863 13.847 31.4233 13.4598 31.087 13.2152C30.7508 12.9503 30.3024 12.8178 29.7318 12.8178H27.6532V16.0275H29.7318Z"
          fill="#273852"
        />
        <path
          d="M8.81043 3.76988C9.04479 3.50496 9.38104 3.3623 9.72748 3.3623H11.9692C12.1526 3.37249 12.3054 3.52534 12.2952 3.70875C12.2952 3.79026 12.2647 3.86159 12.2035 3.92273L7.76091 8.85445H10.2064L12.7028 11.6056H7.76091L12.7028 17.0978H9.71729C9.37085 17.0978 9.0346 16.9551 8.80024 16.6902L3.79719 11.1063C3.34885 10.607 3.34885 9.84283 3.79719 9.34354L8.81043 3.76988Z"
          fill="#273852"
        />
        <path
          d="M15.7131 22.1728C15.4788 22.4377 15.1425 22.5804 14.7961 22.5804H12.5544C12.371 22.5702 12.2181 22.4173 12.2283 22.2339C12.2283 22.1524 12.2589 22.0811 12.32 22.0199L16.7525 17.0882H14.307L11.8105 14.3472H16.7525L11.8105 8.8551H14.7961C15.1425 8.8551 15.4788 9.00795 15.7131 9.26268L20.7264 14.8363C21.1747 15.3356 21.1747 16.0998 20.7264 16.5991L15.7131 22.1728Z"
          fill="#273852"
        />
      </g>
      <defs>
        <clipPath id="clip0_2155_8003">
          <rect width="72.9513" height="21.8854" fill="white" transform="translate(0.929688 0.701904)" />
        </clipPath>
      </defs>
    </svg>
  );
};
