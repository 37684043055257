// import { instance } from "~/shared/lib";

// export const loaderTwitter = async () => {
//   const searchParams = new URLSearchParams(window.location.search);
//   const code = searchParams.get("code");
//   const state = searchParams.get("state");

//   try {
//     if (!code || !state) throw new Error("");
//     const res: any = await instance.post(`/twitter/callback`, {
//       state: state,
//       code: code,
//     });

//     return res.data.success
//       ? { message: "Authentication was successful" }
//       : { message: "There was an error, you may have already registered" };
//   } catch (error) {
//     return { message: "Something went wrong, try again later" };
//   }
// };

export const loaderTwitter = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  return { code, state };
};
