import { LoaderFunctionArgs } from "react-router-dom";

import { instance } from "~/shared/lib";

export const loaderEmail = async ({ params }: LoaderFunctionArgs) => {
  try {
    const res: any = await instance.post(`/socials/email/verify`, { code: params.id });

    return res.data.ok
      ? {
          message: "We have credited you with 200 bonus airdrop points, and they are already in your dashboard.",
          statusCode: res.data.ok,
        }
      : { message: "There was an error, you may have already registered" };
  } catch (error) {
    return { message: "Something went wrong, try again later" };
  }
};
