export const Ubd = () => {
  return (
    <svg
      width="320"
      height="148"
      style={{ width:'min(100%, 320px)', flex: "0 0 148px" }}
      viewBox="0 0 148 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74 138.75C56.8272 138.75 40.3578 131.928 28.2148 119.785C16.0719 107.642 9.25 91.1728 9.25 74C9.25 56.8272 16.0719 40.3578 28.2148 28.2148C40.3578 16.0719 56.8272 9.25 74 9.25C91.1728 9.25 107.642 16.0719 119.785 28.2148C131.928 40.3578 138.75 56.8272 138.75 74C138.75 91.1728 131.928 107.642 119.785 119.785C107.642 131.928 91.1728 138.75 74 138.75ZM74 148C93.626 148 112.448 140.204 126.326 126.326C140.204 112.448 148 93.626 148 74C148 54.374 140.204 35.5518 126.326 21.6741C112.448 7.79641 93.626 0 74 0C54.374 0 35.5518 7.79641 21.6741 21.6741C7.79641 35.5518 0 54.374 0 74C0 93.626 7.79641 112.448 21.6741 126.326C35.5518 140.204 54.374 148 74 148Z"
        fill="#D9D9D9"
      />
      <path
        d="M73.5879 124.464C60.095 124.464 47.1547 119.104 37.6138 109.563C28.0729 100.022 22.7129 87.0818 22.7129 73.5889C22.7129 60.096 28.0729 47.1557 37.6138 37.6148C47.1547 28.0739 60.095 22.7139 73.5879 22.7139C87.0808 22.7139 100.021 28.0739 109.562 37.6148C119.103 47.1557 124.463 60.096 124.463 73.5889C124.463 87.0818 119.103 100.022 109.562 109.563C100.021 119.104 87.0808 124.464 73.5879 124.464ZM73.5879 129.089C80.8763 129.089 88.0933 127.653 94.8268 124.864C101.56 122.075 107.679 117.987 112.832 112.833C117.986 107.68 122.074 101.561 124.863 94.8278C127.652 88.0942 129.088 80.8772 129.088 73.5889C129.088 66.3005 127.652 59.0835 124.863 52.3499C122.074 45.6164 117.986 39.4981 112.832 34.3444C107.679 29.1908 101.56 25.1027 94.8268 22.3136C88.0933 19.5244 80.8763 18.0889 73.5879 18.0889C58.8684 18.0889 44.7517 23.9362 34.3435 34.3444C23.9352 44.7527 18.0879 58.8694 18.0879 73.5889C18.0879 88.3084 23.9352 102.425 34.3435 112.833C44.7517 123.242 58.8684 129.089 73.5879 129.089Z"
        fill="#D9D9D9"
      />
      <path
        d="M70.1092 35.6036C70.1092 35.9818 69.8026 36.2885 69.4243 36.2885H60.9133C60.4439 36.2885 59.9952 36.4811 59.6719 36.8214L49.8049 47.2078C49.5025 47.526 49.334 47.9482 49.334 48.3871V71.0516C49.334 71.4892 49.5015 71.9102 49.8022 72.2281L50.9083 73.3977L57.8952 65.9686V51.8116C57.8952 51.374 58.0627 50.953 58.3634 50.6351L63.3279 45.3855C63.6514 45.0435 64.1013 44.8497 64.572 44.8497H84.3475C84.8182 44.8497 85.2682 45.0435 85.5916 45.3855L90.4163 50.4873C90.717 50.8052 90.8845 51.2262 90.8845 51.6638V53.6392C90.8845 54.0175 91.1912 54.3241 91.5694 54.3241H94.5714C94.956 54.3241 95.3294 54.1947 95.6314 53.9566L99.1849 51.1554C99.3496 51.0255 99.4458 50.8273 99.4458 50.6175V48.2567C99.4458 47.8192 99.2783 47.3982 98.9776 47.0803L89.2785 36.8242C88.9551 36.4822 88.5052 36.2885 88.0345 36.2885H79.3554C78.9771 36.2885 78.6705 35.9818 78.6705 35.6036V20.6473C78.6705 19.7017 77.9039 18.9351 76.9582 18.9351H71.8215C70.8758 18.9351 70.1092 19.7017 70.1092 20.6473V35.6036Z"
        fill="#D9D9D9"
      />
      <path
        d="M57.4171 81.2648C57.4171 80.9822 57.4717 80.7051 57.5752 80.4475C57.6768 80.1949 57.8256 79.9613 58.0161 79.7599L62.7673 74.7358C62.9802 74.5107 63.2359 74.3356 63.5161 74.2189C63.7804 74.1087 64.0664 74.0503 64.3587 74.0503H84.4872C84.8304 74.0503 85.1582 73.9074 85.3917 73.6558L89.9859 68.8825C90.2055 68.6628 90.3064 68.5223 90.2423 68.3014C90.1782 68.0805 88.7613 66.7249 88.7613 66.7249C88.5938 66.5466 88.36 66.4454 88.1153 66.4454H60.8201C60.4839 66.4454 60.1624 66.5825 59.9296 66.8251L50.1558 77.0106C49.9353 77.2404 49.8121 77.5465 49.8121 77.865V81.5734C49.8121 81.6313 49.8364 81.6866 49.8791 81.7257L53.7414 85.2689C53.7795 85.3039 53.8294 85.3233 53.8812 85.3233H57.2103C57.3245 85.3233 57.4171 85.2307 57.4171 85.1166V81.2648Z"
        fill="#D9D9D9"
      />
      <path
        d="M58.7665 81.755L59.5223 82.5541C59.8457 82.8961 60.2957 83.0899 60.7664 83.0899H87.9145C88.3809 83.0899 88.8271 82.8997 89.15 82.5632L98.9238 72.3777C99.2298 72.0588 99.4006 71.634 99.4006 71.1921V69.3579C99.4006 68.8318 99.1588 68.335 98.7449 68.0104L95.3061 65.3143C95.1855 65.2197 95.0367 65.1684 94.8835 65.1684H92.4224C91.9719 65.2605 91.633 65.6591 91.633 66.1368V68.5031C91.633 69.1 91.4016 69.6736 90.9874 70.1035L86.0472 75.2302C85.6952 75.5954 85.2099 75.8017 84.7027 75.8017H64.4119L58.7665 81.755Z"
        fill="#D9D9D9"
      />
      <path
        d="M90.8846 83.6816V97.2093C90.8846 97.6469 90.717 98.0679 90.4164 98.3858L85.4518 103.635C85.1284 103.977 84.6785 104.171 84.2078 104.171H64.4322C63.9615 104.171 63.5116 103.977 63.1882 103.635L58.0978 98.5869C57.9682 98.4583 57.8952 98.2832 57.8952 98.1006V95.3817C57.8952 95.0034 57.5886 94.6967 57.2103 94.6967H53.9152C53.7651 94.6967 53.6191 94.7461 53.4998 94.8372L49.6034 97.8102C49.4336 97.9398 49.334 98.1411 49.334 98.3547V100.764C49.334 101.202 49.5015 101.623 49.8022 101.941L59.5012 112.197C59.8247 112.539 60.2746 112.732 60.7453 112.732H69.4244C69.8026 112.732 70.1093 113.039 70.1093 113.417V127.488C70.1093 127.867 70.4159 128.173 70.7942 128.173H77.9856C78.3639 128.173 78.6705 127.867 78.6705 127.488V113.417C78.6705 113.039 78.9772 112.732 79.3554 112.732H87.8665C88.3358 112.732 88.7846 112.54 89.1079 112.199L98.9749 101.813C99.2772 101.495 99.4458 101.073 99.4458 100.634V77.9693C99.4458 77.5317 99.2783 77.1107 98.9776 76.7928L98.148 76.1387L90.8846 83.6816Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
