import { IconProps } from "./types";

export const FailureIcon = ({ style }: IconProps) => {
  return (
    <svg width="333" height="332" viewBox="0 0 333 332" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_1824_9981)">
        <path
          d="M166.5 100.375C153.521 100.375 140.833 104.224 130.041 111.435C119.249 118.646 110.837 128.895 105.87 140.886C100.903 152.878 99.6038 166.073 102.136 178.803C104.668 191.533 110.918 203.226 120.096 212.404C129.274 221.582 140.967 227.832 153.697 230.364C166.427 232.896 179.622 231.597 191.614 226.63C203.605 221.663 213.854 213.251 221.065 202.459C228.276 191.667 232.125 178.979 232.125 166C232.125 148.595 225.211 131.903 212.904 119.596C200.597 107.289 183.905 100.375 166.5 100.375ZM166.5 222.25C155.375 222.25 144.499 218.951 135.249 212.77C125.999 206.589 118.789 197.804 114.532 187.526C110.274 177.248 109.16 165.938 111.331 155.026C113.501 144.115 118.859 134.092 126.725 126.225C134.592 118.359 144.615 113.001 155.526 110.831C166.438 108.66 177.748 109.774 188.026 114.032C198.304 118.289 207.089 125.499 213.27 134.749C219.451 143.999 222.75 154.875 222.75 166C222.75 180.918 216.824 195.226 206.275 205.775C195.726 216.324 181.418 222.25 166.5 222.25Z"
          fill="white"
        />
      </g>
      <path
        d="M147.587 140.313L166.675 159.401L185.664 140.412C186.084 139.966 186.589 139.609 187.15 139.362C187.711 139.116 188.316 138.985 188.928 138.978C190.24 138.978 191.498 139.499 192.425 140.427C193.352 141.354 193.873 142.612 193.873 143.923C193.885 144.53 193.772 145.132 193.543 145.693C193.313 146.254 192.971 146.763 192.538 147.187L173.302 166.176L192.538 185.412C193.353 186.21 193.831 187.29 193.873 188.429C193.873 189.74 193.352 190.998 192.425 191.926C191.498 192.853 190.24 193.374 188.928 193.374C188.298 193.4 187.669 193.295 187.082 193.065C186.494 192.835 185.961 192.486 185.516 192.039L166.675 172.951L147.637 191.989C147.219 192.421 146.72 192.766 146.168 193.003C145.616 193.241 145.023 193.367 144.422 193.374C143.111 193.374 141.853 192.853 140.926 191.926C139.998 190.998 139.477 189.74 139.477 188.429C139.466 187.823 139.578 187.22 139.808 186.659C140.038 186.098 140.379 185.59 140.813 185.165L160.049 166.176L140.813 146.94C139.998 146.142 139.52 145.063 139.477 143.923C139.477 142.612 139.998 141.354 140.926 140.427C141.853 139.499 143.111 138.978 144.422 138.978C145.609 138.993 146.747 139.473 147.587 140.313Z"
        fill="white"
      />
      <circle opacity="0.8" cx="167" cy="166" r="75.5" stroke="#F24E1E" />
      <circle opacity="0.3" cx="167" cy="166" r="89.5" stroke="#F24E1E" />
      <circle opacity="0.2" cx="167" cy="166" r="105.5" stroke="#F24E1E" />
      <defs>
        <filter
          id="filter0_dd_1824_9981"
          x="0.737068"
          y="0.237068"
          width="331.526"
          height="331.526"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="16.6897" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 0 0.117647 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1824_9981" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="50.069" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 0 0.117647 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1824_9981" result="effect2_dropShadow_1824_9981" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1824_9981" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
