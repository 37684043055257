import { IconProps } from "./types";

export const BenzingaIcon = ({ style }: IconProps) => {
  return (
    <svg viewBox="0 0 600 82" fill="#F1F1F3" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M86.4 81.4H144V66.2H103.8V47.6H137.1V32.4H103.8V15.2H144.2V0H86.4V81.4ZM213.3 51.2L175.6 0H149.6L157.7 10.3V81.6H175.1V28.2L214.1 81.4H230.7V0H213.3V51.2ZM60.1 39.5C67.7 38 74.1 30.9 74.1 20.9C74 10.1 66.2 0 50.5 0H0L7.8 10V81.4H52.2C67.9 81.4 76 71.6 76 59.3C75.8 49.3 68.9 40.7 60.1 39.5ZM25.3 15.3H47.1C53 15.3 56.7 18.7 56.7 23.9C56.7 29 53 32.5 47.1 32.5H25.3V15.3ZM47.9 66.3H25.4V47.6H47.9C54.5 47.6 58.2 51.8 58.2 56.9C58.2 62.8 54.3 66.2 47.9 66.3ZM485.4 51.2H494.5V60.8C489.8 64.7 483.9 66.8 477.8 66.9C463.3 66.9 453 55.9 453 40.9C453 26 463.3 14.9 477.8 14.9C485.4 15 492.4 18.8 496.4 25.2L510.1 17.6C504.2 8.30001 493.9 0 477.5 0C454.5 0 435.3 15.9 435.3 40.7C435.3 65.5 454.2 81.4 477.5 81.4C490.2 81.6 502.4 76.2 510.8 66.7V36.6H473.7L485.4 51.2ZM407 51.2L369.2 0H343.2L351.3 10.3V81.6H368.7V28.2L407.7 81.4H424.4V0H407V51.2ZM568.5 0H534.9L543 10.3L541.5 14.2L515.5 81.6H535.1L540.2 67.9H575.3L580.4 81.6H600L568.5 0ZM544.8 52.5L557.5 17.4L570.2 52.5H544.8ZM315.8 0H333.2V81.6H315.8V0ZM301.8 14V0H240.5V15.2H279L240.5 67.4V81.4H302.5V66.2H263L301.8 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
