import { useLocation } from "react-router-dom";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { getJwtInfo } from "~/shared/utils/jwt";
import NavSectionVertical from "../../../components/nav-section/vertical";
import navConfig, { publicNavConfig } from "./config-navigation";

export default function NavVertical() {
  const isAuthorized = !!getJwtInfo() && useWallet().isAuthorized;

  const location = useLocation();
  const isRoot =
    !navConfig[0].items.some((path) => path.path === location.pathname) || location.pathname.includes("login");
  return <NavSectionVertical data={isAuthorized ? navConfig : publicNavConfig} />;
}
