import { mainnet, sepolia } from "viem/chains";
import { GetTokenReturnType } from "wagmi/actions";

export type CustomToken = Omit<GetTokenReturnType, "totalSupply"> & {
  chainId: number;
};
interface AddressesMap {
  [key: number]: { [key: string]: `0x${string}` | undefined } | undefined;
}

export const TOKENS: AddressesMap = {
  [sepolia.id]: {
    USDT: "0xE3cfED0fbCDB7AaE09816718f0f52F10140Fc61F",
    UBDN: "0x7ce7abb7f8794dce67fb2dc4d8ebf2f033472730",
    USDC: "0xBF8528699868B1a5279084C92B1d31D9C0160504",
    DAI: "0xE52E3383740e713631864Af328717966F5Fa4e22",
    WBTC: "0xa2535BFbe7c0b0EB7B494D70cf7f47e037e19b02",
    WETH: "0x947b627800c349854ea3665cc7C3A139662D3e49",
    UBD: "0x3E4673D787dD89C96075Bf6a2bE0DFaB75cC6Be8",
  },
  0: {
    USDT: "0x0000000000000000000000000000000000000000",
    UBDN: "0x0000000000000000000000000000000000000000",
    USDC: "0x0000000000000000000000000000000000000000",
    DAI: "0x0000000000000000000000000000000000000000",
    UBD: "0x0000000000000000000000000000000000000000",
  },
  [mainnet.id]: {
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    UBDN: "0xD624E5C89466A15812c1D45Ce1533be1F16C1702",
    USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    UBD: "0x08A0356dF4F4052accE757FaacCEc35b621bBDD2",
  },
};

export interface NetworkAddressesMap {
  [key: string]: `0x${string}`;
}

export const getTokens = (chainId: number | undefined): NetworkAddressesMap => {
  if (!chainId) return TOKENS[0] as NetworkAddressesMap;
  if (TOKENS[chainId]) return TOKENS[chainId] as NetworkAddressesMap;
  return TOKENS[0] as NetworkAddressesMap;
};

export const CONTRACTS: AddressesMap = {
  [sepolia.id]: {
    LOCKER: "0xCCF7028D83D0b6eD8e68124Efe07E5FaD1C4E17F",
    STAKING_MANAGER: "0x0623e94d23EA9B98405F82219A45443AAf02AEed",
    SANDBOX_USDT: "0x7Fe48EE4FBAE0aAf9540688951208D0D5609b860",
    MARKET_REGISTRY: "0x00eCF037DA7616772cc10f1a98C0819350900DBF",
    DETRUST_FACTORY: "0x0e332Ee59191CD43a035fB705e82e53934cd2014",
    DETRUST_MODEL: "0x3A2E0c04c5007E9fcD637935E7B5Ee6d9eA906C0",
    DETRUSTS_REGISTRY: "0xde03361b17c0cCa0A8E3a9864283CF9f46dA3f40",
  },
  0: {
    LOCKER: "0x0000000000000000000000000000000000000000",
  },
  [mainnet.id]: {
    LOCKER: "0x6D8b29c195b9478D678cD9eA7aD870ECfb0A869F",
    MARKET_REGISTRY: "0x1B4074355Bb5b3983dF638CB769bdC12d34Ac882",
    SANDBOX_USDT: "0x5eE5c97A594cb4a922B9c0d259bEe7fe0622afAa",
    STAKING_MANAGER: "0x62620c7D66DB5fB17619a25e3f8DBE189479C54F",
  },
};

export const getContracts = (chainId: number | undefined): NetworkAddressesMap => {
  if (!chainId) return CONTRACTS[0] as NetworkAddressesMap;
  if (CONTRACTS[chainId]) return CONTRACTS[chainId] as NetworkAddressesMap;
  return CONTRACTS[0] as NetworkAddressesMap;
};
