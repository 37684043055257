import { Box, Card, CardContent, Stack, Typography, useMediaQuery } from "@mui/material";
import { DeTrust } from "../icons/DeTrust";
import { GraphArrow } from "../icons/GraphArrow";
import { Group } from "../icons/Group";
import { Hugei } from "../icons/Hugei";
import { Ubd } from "../icons/Ubd";
import { Ubdn } from "../icons/Ubdn";
import { Wallet } from "../icons/Wallet";

export const Ecosystem = () => {
  const ipad = useMediaQuery("(max-width:800px)");
  const lap = useMediaQuery("(max-width:1170px)");
  const tablet = useMediaQuery("(max-width:600px)");
  const mobile = useMediaQuery("(max-width:450px)");
  const media = useMediaQuery("(max-width:900px)");

  return (
    <>
      <Box>
        <Typography
          textAlign={ipad ? "center" : "left"}
          mt={ipad ? "0" : "70px"}
          mb="50px"
          color="#F1F1F3"
          variant="h3"
        >
          UBD Network Ecosystem
        </Typography>
        <Box>
          <Card sx={{ backgroundColor: "#292E41", mb: "24px" }}>
            <CardContent sx={{ padding: mobile ? "24px 0" : "" }}>
              <Stack flexDirection={tablet ? "column" : "row"} alignItems={tablet ? "center" : ""}>
                <DeTrust />
                <Box ml={mobile ? "12px" : "25px"} mr={"12px"} mt="15px">
                  <Typography
                    fontSize={mobile ? "18px" : "32px"}
                    mb={mobile ? "12px" : "20px"}
                    color="#F1F1F3"
                    fontWeight="700"
                  >
                    DeTrust multi-sig platform
                  </Typography>
                  <Box fontSize={mobile ? "14px" : "20px"} color="#989BA9" component="p">
                    A multi-signature platform and self-managed crypto asset wallet that helps you store and defer the
                    transfer of crypto assets.
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap={lap ? "wrap" : "nowrap"}
            gap="24px"
            mb="24px"
          >
            <Card sx={{ backgroundColor: "#292E41", alignSelf: "normal" }}>
              <CardContent sx={{ padding: mobile ? "24px 0" : "" }}>
                <Stack flexDirection={tablet ? "column" : "row"} alignItems="center">
                  <Ubdn />
                  <Box mt={tablet ? "24px" : 0} ml={mobile ? "12px" : "25px"} mr={"12px"}>
                    <Typography
                      fontSize={mobile ? "18px" : "32px"}
                      mb={mobile ? "12px" : "20px"}
                      color="#F1F1F3"
                      fontWeight="700"
                    >
                      UBDN
                    </Typography>
                    <Box fontSize={mobile ? "14px" : "20px"} color="#989BA9" component="p">
                      Utility token, which allows its owners to support the ecosystem, become Keepers and participate in
                      Epochs to receive monthly rewards in UBD.
                    </Box>
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            <Card sx={{ backgroundColor: "#292E41", alignSelf: "normal" }}>
              <CardContent sx={{ padding: mobile ? "24px 0" : "" }}>
                <Stack flexDirection={tablet ? "column" : "row"} alignItems="center">
                  <Ubd />
                  <Box mt={tablet ? "24px" : 0} ml={mobile ? "12px" : "25px"} mr={"12px"}>
                    <Typography
                      fontSize={mobile ? "18px" : "32px"}
                      mb={mobile ? "12px" : "20px"}
                      color="#F1F1F3"
                      fontWeight="700"
                    >
                      UBD
                    </Typography>
                    <Box fontSize={mobile ? "14px" : "20px"} color="#989BA9" component="p">
                      United Blockchain Dollar, an algorithmic stablecoin pegged to the US dollar and also backed by
                      BTC, ETH, USDT, DAI. Can be staked to increase rewards.
                    </Box>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      </Box>
      <Box>
        <Typography mt="50px" width="min(100%, 760px)" mb="10px" color="#F1F1F3" fontSize="20px">
          DeTrust platform engages smart contracts to deliver a fully transparent and decentralized tools for digital
          asset management:
        </Typography>
        {media ? (
          <EcosystemMobile />
        ) : (
          <Stack
            flexWrap="wrap"
            flexDirection="row"
            alignItems="center"
            mt="27px"
            justifyContent={mobile ? "center" : "space-around"}
          >
            <Box mt="20px">
              <Box mb="30px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Wallet />
                <Box textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
                  Multi-sig asset management center & wallet
                </Box>
              </Box>
              <Box
                display="flex"
                mt="60px"
                mb="30px"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Hugei />
                <Box textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
                  Inheritance of crypto assets
                </Box>
              </Box>
            </Box>
            <Box>
              <Box mb="30px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Group />
                <Box
                  width="min(100%, 305px)"
                  textAlign="center"
                  color="#989BA9"
                  fontSize="18px"
                  mt="20px"
                  component="div"
                >
                  DeTrust Funds for family,{" "}
                  <Box whiteSpace={ipad ? "wrap" : "nowrap"}>corporate, charitable and other needs</Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <GraphArrow />
                <Box
                  width="min(100%, 385px)"
                  textAlign="center"
                  color="#989BA9"
                  fontSize="18px"
                  mt="20px"
                  component="div"
                >
                  Capital growth with native staking &{" "}
                  <Box whiteSpace={ipad ? "wrap" : "nowrap"}>external yield platforms (AAVE, LIDO, Compound etc.)</Box>
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
};

export const EcosystemMobile = () => {
  return (
    <Stack alignItems="center" mt="27px" justifyContent={"center"}>
      <Box mt="20px">
        <Box mb="30px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Wallet />
          <Box textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
            Multi-sig asset management center & wallet
          </Box>
        </Box>
        <Box mb="30px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Group />
          <Box width="min(100%, 305px)" textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
            DeTrust Funds for family, <Box>corporate, charitable and other needs</Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" mt="30px" mb="30px" justifyContent="center" alignItems="center" flexDirection="column">
          <Hugei />
          <Box textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
            Inheritance of crypto assets
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <GraphArrow />
          <Box width="min(100%, 385px)" textAlign="center" color="#989BA9" fontSize="18px" mt="20px" component="div">
            Capital growth with native staking & <Box>external yield platforms (AAVE, LIDO, Compound etc.)</Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
