import { Box, List, Stack, useMediaQuery } from "@mui/material";

import { Link } from "react-router-dom";
import s from "../InfoHome.module.css";

export const MultiSig = () => {
  const ipad = useMediaQuery("(max-width:800px)");
  const mobile = useMediaQuery("(max-width:450px)");

  return (
    <Stack mb={ipad ? "0" : "100px"} mt={ipad ? "0" : "100px"} flexDirection={ipad ? "column" : "row"}>
      <img className={s.image} src="/images/Frame.png" alt="" />
      <Box ml={ipad ? "0" : "40px"}>
        <Box
          sx={{
            width: "min(908px, 100%)",
            position: "relative",
            borderRadius: "24px",
            backdropFilter: "blur(40px)",
            backgroundImage: "linear-gradient(90deg,  rgba(91,252,187,0.34) 100%, #2afc97 59%)",
            display: "block",
            "&:before": {
              content: "''",
              position: "absolute",
              inset: 0,
              borderRadius: "20px",
              border: "6px solid transparent",
              background: "-webkit-linear-gradient(90deg, #5bfcbb, transparent) border-box",
              mask: "linear-gradient(#5BFCBB 0 0) padding-box, linear-gradient(#36966F 0 0)",
              WebkitMaskComposite: "destination-out",
              maskComposite: "exclude",
            },
          }}
        >
          <List sx={{ pt: 2, pl: 3, pb: 5 }}>
            {[
              "Vitalik Buterin uses a multi-sig wallet for 90% of his crypto to enhance security.",
              "He recommended a multi-sig solution to minimize the personal risks of hardware wallets.",
              "In a multi-sig solution, users hold some keys but are insufficient to recover the funds independently.",
            ].map((list) => (
              <li className={s.infoListItem} key={list}>
                <span>{list}</span>
              </li>
            ))}
          </List>
          <Link
            style={{ cursor: "pointer", zIndex: 100, position: "absolute", bottom: -5, right: 0 }}
            target="_blank"
            rel="noopener"
            to="https://ng.investing.com/news/cryptocurrency-news/eth-founder-vitalik-reveals-90-of-his-crypto-held-in-multisig-service-1329465"
          >
            <Box pb={2} textAlign="right" pr="16px" color="#D3D5E0">
              Source: investing.com
            </Box>
          </Link>
        </Box>
        <Stack flexDirection="row" alignItems="flex-end" justifyContent="space-between">
          <Box
            mt="40px"
            color="#F1F1F3"
            mb="40px"
            fontSize="14px"
            fontWeight="500"
            fontStyle="italic"
            component="div"
            sx={{
              position: "relative",
              borderLeft: "3.5px solid #46C391",
              pl: "15px",
            }}
          >
            “Multisig wallets{" "}
            <Box color="#989BA9" component="span">
              are an
            </Box>{" "}
            easy and safe way to store funds,{" "}
            <Box color="#989BA9" component="span">
              and can give you most of the key benefits of self-custody - namely
            </Box>
            , your funds not being subject to disappearing because a centralized entity{" "}
            <Box color="#989BA9" component="span">
              that seemed trustworthy turns out not to be at all - without the risks of having to be personally
              responsible for your entire security setup.
            </Box>{" "}
            <br />
            <Stack flexDirection={ipad ? "column" : "row"} alignItems="center" justifyContent="space-between">
              <Box mr={ipad ? "auto" : ""}>
                I use a multisig wallet personally to store the bulk of my funds, as does the Ethereum Foundation.”
              </Box>
              <Box
                mr={ipad ? "auto" : ""}
                whiteSpace="nowrap"
                fontStyle="initial"
                color="#989BA9"
                sx={{ ml: "auto", mr: "0" }}
              >
                Source:{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to="https://old.reddit.com/r/ethereum/comments/11tijiv/how_i_think_about_choosing_guardians_for_multisig/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  reddit.com
                </Link>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box
          color="#F1F1F3"
          mb="40px"
          fontSize="14px"
          fontWeight="500"
          fontStyle="italic"
          component="div"
          sx={{
            position: "relative",
            borderLeft: "3.5px solid #46C391",
            pl: "15px",
          }}
        >
          “The above is why I use a multisig{" "}
          <Box component="span" color="#fff">
            for &gt;90% of my personal funds
          </Box>
           <br />
          <Box color="#989BA9" display="block" component="span" mt="20px" mb="0">
            M-of-N, some keys held by you (but not enough to block recovery), the rest held by other people you trust.
            Don't reveal who those other people are, even to each other.
          </Box>
          <br />
          <Stack flexDirection={ipad ? "column" : "row"} alignItems="center" justifyContent="space-between">
            <Box mr={ipad ? "auto" : ""} component="span" color="#fff">
              Decentralize your own security.”
            </Box>
            <Box
              mr={ipad ? "auto" : ""}
              whiteSpace="nowrap"
              fontStyle="initial"
              color="#989BA9"
              sx={{ ml: "auto", mr: "0" }}
            >
              Source:{" "}
              <Link
                style={{ textDecoration: "underline" }}
                to="https://x.com/VitalikButerin/status/1785562273433387320"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter.com
              </Link>
            </Box>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          color="#989BA9"
          mb="40px"
          fontSize="14px"
          fontWeight="500"
          fontStyle="italic"
          component="div"
          sx={{
            position: "relative",
            borderLeft: "3.5px solid #46C391",
            pl: "15px",
          }}
        >
          <Box>
            “Self-custody is important. And{" "}
            <Box mr={"3px"} component="span" color="#fff">
              social recovery and multisig
            </Box>
            is a great way to do it.”
          </Box>
          <Stack flexDirection={ipad ? "column" : "row"} alignItems="center" justifyContent="space-between">
            <Box
              ml={mobile ? "auto" : ""}
              mr={ipad ? "auto" : ""}
              whiteSpace="nowrap"
              fontStyle="initial"
              color="#989BA9"
              sx={{ ml: "auto", mr: "0" }}
            >
              Source:{" "}
              <Link
                style={{ textDecoration: "underline" }}
                to="https://x.com/VitalikButerin/status/1636608740513263617"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter.com
              </Link>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
