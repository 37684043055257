import { createContext, useContext, useState } from "react";

const initialState: any = {
  open: false,
  setOpen: () => {},
};

export const MenuContext = createContext(initialState);

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) throw new Error("useMenuContext must be use inside MenuDrawerProvider");
  return context;
};

type SettingsProviderProps = {
  children: React.ReactNode;
};

export function MenuDrawerProvider({ children }: SettingsProviderProps) {
  const [open, setOpen] = useState(false);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MenuContext.Provider value={{ open, setOpen }}>{children}</MenuContext.Provider>
  );
}
