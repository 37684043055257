import { IconProps } from "./types";

export const CopyIcon = ({ style }: IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M20.1348 3H8.13477C7.93585 3 7.74509 3.07902 7.60444 3.21967C7.46378 3.36032 7.38477 3.55109 7.38477 3.75V7.5H3.63477C3.43585 7.5 3.24509 7.57902 3.10444 7.71967C2.96378 7.86032 2.88477 8.05109 2.88477 8.25V20.25C2.88477 20.4489 2.96378 20.6397 3.10444 20.7803C3.24509 20.921 3.43585 21 3.63477 21H15.6348C15.8337 21 16.0244 20.921 16.1651 20.7803C16.3057 20.6397 16.3848 20.4489 16.3848 20.25V16.5H20.1348C20.3337 16.5 20.5244 16.421 20.6651 16.2803C20.8057 16.1397 20.8848 15.9489 20.8848 15.75V3.75C20.8848 3.55109 20.8057 3.36032 20.6651 3.21967C20.5244 3.07902 20.3337 3 20.1348 3ZM14.8848 19.5H4.38477V9H14.8848V19.5ZM19.3848 15H16.3848V8.25C16.3848 8.05109 16.3057 7.86032 16.1651 7.71967C16.0244 7.57902 15.8337 7.5 15.6348 7.5H8.88477V4.5H19.3848V15Z"
      fill="currentColor"
    />
  </svg>
);
