import { Link, useLoaderData } from "react-router-dom";
import { SOCIAL_ROUTE } from "~/shared/const";
import * as Path from "~/shared/const/route";
import { Button } from "~/shared/ui";
import { FailureIcon } from "../../assets/failureIcon";
import { SuccessIcon } from "../../assets/successIcon";

const EmailConfirmPage = () => {
  const data = useLoaderData() as any;

  return (
    <main className="main">
      <div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {data?.statusCode ? (
          <div>
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "31.2px",
                }}
              >
                Your email address has been successfully confirmed.
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", margin: "32px" }}>
              <SuccessIcon />
            </div>

            <div className="subtitle-margin">
              <p
                className="body1 subtitle-margin"
                style={{
                  textAlign: "center",
                }}
              >
                {data.message}
              </p>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "64px" }}>
                <Button component="a" variant="containe_light" href={Path.DASHBOARD_ROUTE}>
                  Dashboard
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "31.2px",
                }}
              >
                {data.message}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FailureIcon />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to={SOCIAL_ROUTE}>
                <Button variant="containe_light">Back</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default EmailConfirmPage;
