import { createContext, useContext, useEffect } from "react";
import { mainnet, sepolia } from "viem/chains";
import { useAccount } from "wagmi";
import { useBalances } from "../hooks/useBalances";
import { AuthInfo, useJwtAuth } from "../hooks/useJwtAuth";
import { useLockedBalance } from "../hooks/useLockedBalance";

export interface FetchBalanceResult {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
}

declare global {
  interface Window {
    chainId: number | undefined;
  }
}

interface WalletContextType {
  balances: {
    usdtBalance: FetchBalanceResult | undefined;
    ubdnBalance: FetchBalanceResult | undefined;
    usdcBalance: FetchBalanceResult | undefined;
    daiBalance: FetchBalanceResult | undefined;
    ubdBalance: FetchBalanceResult | undefined;
  };
  balancesMap: {
    [key: string]: string | undefined;
  };
  isAuthorized: boolean;
  refetchAuth: () => Promise<void>;
  authInfo: AuthInfo | undefined;
  ubdnLocked: bigint;
  isAuthInit: boolean | undefined;
}

const WalletContext = createContext<WalletContextType>({} as WalletContextType);

export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const balances = useBalances();
  const { address, chain } = useAccount();
  const { authInfo, fetchInfo, isAuthInit } = useJwtAuth();
  const ubdnLocked = useLockedBalance();

  const isCorrectNetwork = chain?.id == mainnet.id || chain?.id == sepolia.id;

  useEffect(() => {
    window.chainId = chain?.id;
  }, [chain]);

  return (
    <WalletContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        balances: {
          usdtBalance: balances.usdtBalance.data,
          ubdnBalance: balances.ubdnBalance.data,
          usdcBalance: balances.usdcBalance.data,
          daiBalance: balances.daiBalance.data,
          ubdBalance: balances.ubdBalance.data,
        },
        balancesMap: {
          USDT: balances.usdtBalance.data?.formatted ?? "0",
          UBDN: balances.ubdnBalance.data?.formatted ?? "0",
          USDC: balances.usdcBalance.data?.formatted ?? "0",
          DAI: balances.daiBalance.data?.formatted ?? "0",
          UBD: balances.ubdBalance.data?.formatted ?? "0",
        },
        isAuthorized: !!address && !!chain && isCorrectNetwork,
        refetchAuth: fetchInfo,
        authInfo,
        ubdnLocked,
        isAuthInit,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
