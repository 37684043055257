export const LineGradient = () => {
  return (
    <svg
      width="414"
      style={{ maxWidth: "99%" }}
      height="2"
      viewBox="0 0 414 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H413" stroke="url(#paint0_linear_2566_3578)" stroke-width="2" stroke-linecap="round" />
      <defs>
        <linearGradient id="paint0_linear_2566_3578" x1="1" y1="2.5" x2="413" y2="2.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#45D3D1" stop-opacity="0.22" />
          <stop offset="0.76" stop-color="#23D890" />
          <stop offset="1" stop-color="#D1ABFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MobileLineGradient = () => {
  return (
    <svg
      style={{ width: "min(100%, 622px)" }}
      width="622"
      height="2"
      viewBox="0 0 622 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H621" stroke="url(#paint0_linear_2678_4025)" stroke-width="2" stroke-linecap="round" />
      <defs>
        <linearGradient id="paint0_linear_2678_4025" x1="1" y1="2.5" x2="621" y2="2.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#45D3D1" stop-opacity="0.22" />
          <stop offset="0.76" stop-color="#23D890" />
          <stop offset="1" stop-color="#D1ABFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
