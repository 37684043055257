import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Buffer } from "buffer";

import * as Sentry from "@sentry/react";
import * as ReactDOM from "react-dom";
import App from "./app";
import { config } from "./app/wagmi";

Sentry.init({
  dsn: "https://8029c2617d4d726ff99685375a58d6ec@o4506541176848384.ingest.us.sentry.io/4507324260352000",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/ubdn/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

globalThis.Buffer = Buffer;

createWeb3Modal({
  wagmiConfig: config,
  projectId: import.meta.env.VITE_WC_PROJECT_ID,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>"An error has occurred"</p>}>
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById("root")!,
);
