import { useDates } from "../hooks";

// сокращает адрес
export const formatAddress = (addr: any) => {
  if (addr.address || !addr) return addr.address?.slice(0, 10) + "..." + addr.address?.slice(-10);

  return addr ? addr?.slice(0, 10) + "..." + addr?.slice(-10) : addr;
};

// добавляет нули к времени, чтобы оно было формата 02:09
export function formatTime(value: any) {
  return value.toString().padStart(2, "0");
}

const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// преобразует дату в формат 03 Mar 2000, 02:09
// Используется в Claimtable & EarnsTable
export const formatDate = (date: any) => {
  if (!date || typeof date === "object") {
    const newDate = new Date();

    return `${newDate.getDay()} ${month[newDate.getMonth()]} ${newDate.getFullYear()}`;
  }
  const newDate = new Date(date);

  const dateStr = `${newDate.getUTCDate()} ${month[newDate.getUTCMonth()]} ${newDate.getUTCFullYear()}, ${formatTime(newDate.getUTCHours())}:${formatTime(newDate.getUTCMinutes())}`;

  return date ? dateStr : "";
};

// если элемент создан менее трех минут назад, к tr будет добавлен класс leader с градиентом
export const getClass = (date: string) => {
  const { dateItem, nowDate } = useDates(date);

  const className = Math.floor((nowDate - dateItem) / 60000) <= 3 ? "leader" : "";

  return date ? className : "";
};
