export const GraphArrow = () => {
  return (
    <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2569_8445)">
        <path
          d="M48.8613 18.9536H69.4328V39.525"
          stroke="#5BFCBB"
          stroke-width="5.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M69.4294 18.9536L40.3723 48.0108C39.8916 48.4819 39.2453 48.7458 38.5723 48.7458C37.8992 48.7458 37.2529 48.4819 36.7723 48.0108L24.9437 36.1822C24.463 35.711 23.8168 35.4471 23.1437 35.4471C22.4706 35.4471 21.8244 35.711 21.3437 36.1822L2.57227 54.9536"
          stroke="#5BFCBB"
          stroke-width="5.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2569_8445">
          <rect width="72" height="72" fill="white" transform="translate(0 0.953125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
