import { Toolbar } from "@mui/material";
import { memo } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { getJwtInfo } from "~/shared/utils/jwt";
import { NavSectionHorizontal } from "../../../components/nav-section";
import navConfig, { publicNavConfig } from "./config-navigation";

function NavHorizontal({ sx, ...other }: any) {
  const wallet = useWallet();
  const isAuthorized = !!getJwtInfo() && wallet.isAuthorized;

  return (
    <Toolbar sx={sx}>
      <NavSectionHorizontal data={isAuthorized ? navConfig : publicNavConfig} />
    </Toolbar>
  );
}

export default memo(NavHorizontal);
