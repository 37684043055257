import { Link } from "react-router-dom";
import { HOME_ROUTE } from "~/shared/const";
import { Button } from "~/shared/ui";

const NotFound = () => (
  <main className="Main">
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>404: page not found</p>
        <Link to={HOME_ROUTE}>
          <Button variant="containe_light">Home</Button>
        </Link>
      </div>
    </div>
  </main>
);

export default NotFound;
