import { Link, useLoaderData, useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useWallet } from "~/app/ubdn-app/providers/WalletProvider";
import { SOCIAL_ROUTE } from "~/shared/const";
import { instance } from "~/shared/lib";
import { Button } from "~/shared/ui";

const DiscordCallback = () => {
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const data = useLoaderData() as any;
  const { enqueueSnackbar } = useSnackbar();
  const { refetchAuth } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    const code = data.code;
    if (!code) return;
    const fetchDiscordCallback = async () => {
      try {
        const response = await instance.get(`/socials/discord/callback/${code}`);
        if (response.data.ok) {
          setMessage("Authentication was successful");
          refetchAuth();
          navigate(SOCIAL_ROUTE);
          return;
        }
        setMessage("You are not connected to our discord server");
        setLink("https://discord.gg/EAkXDyjXTb");
        enqueueSnackbar(
          "Authentication failed, you are not connected to our discord server (https://discord.gg/EAkXDyjXTb)",
          { variant: "error" },
        );
      } catch (error) {
        setMessage("Something went wrong, try again later");
      }
    };
    fetchDiscordCallback();
  }, []);

  return (
    <main className="main">
      <div className="container">
        <div className="subtitle-margin">
          <p className="body1 subtitle-margin">{message}</p>
          {!!link && (
            <Button component="a" variant="containe_light" href={link}>
              Click the button and join us
            </Button>
          )}
        </div>
        <Link to={SOCIAL_ROUTE}>
          <Button variant="outline">Back</Button>
        </Link>
      </div>
    </main>
  );
};

export default DiscordCallback;
