import { IconProps } from "@mui/material";

export const USDTIcon = ({ style }: IconProps) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M21 39.375C16.1266 39.375 11.4529 37.4391 8.00691 33.9931C4.56093 30.5471 2.625 25.8734 2.625 21C2.625 16.1266 4.56093 11.4529 8.00691 8.00691C11.4529 4.56093 16.1266 2.625 21 2.625C25.8734 2.625 30.5471 4.56093 33.9931 8.00691C37.4391 11.4529 39.375 16.1266 39.375 21C39.375 25.8734 37.4391 30.5471 33.9931 33.9931C30.5471 37.4391 25.8734 39.375 21 39.375ZM21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42Z"
        fill="currentColor"
      />
      <path
        d="M20.8828 35.3208C17.0537 35.3208 13.3815 33.7997 10.674 31.0922C7.9664 28.3846 6.44531 24.7124 6.44531 20.8833C6.44531 17.0542 7.9664 13.382 10.674 10.6744C13.3815 7.96689 17.0537 6.4458 20.8828 6.4458C24.7119 6.4458 28.3841 7.96689 31.0917 10.6744C33.7992 13.382 35.3203 17.0542 35.3203 20.8833C35.3203 24.7124 33.7992 28.3846 31.0917 31.0922C28.3841 33.7997 24.7119 35.3208 20.8828 35.3208ZM20.8828 36.6333C22.9511 36.6333 24.9992 36.2259 26.9101 35.4344C28.821 34.6429 30.5572 33.4828 32.0197 32.0202C33.4823 30.5577 34.6424 28.8214 35.4339 26.9106C36.2254 24.9997 36.6328 22.9516 36.6328 20.8833C36.6328 18.815 36.2254 16.7669 35.4339 14.856C34.6424 12.9452 33.4823 11.2089 32.0197 9.74637C30.5572 8.28385 28.821 7.12371 26.9101 6.3322C24.9992 5.54069 22.9511 5.1333 20.8828 5.1333C16.7057 5.1333 12.6996 6.79267 9.74588 9.74637C6.79218 12.7001 5.13281 16.7061 5.13281 20.8833C5.13281 25.0605 6.79218 29.0665 9.74588 32.0202C12.6996 34.9739 16.7057 36.6333 20.8828 36.6333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2369 18.7158V16.056H29.4032V12H12.6139V16.056H18.7802V18.7146C13.7688 18.9417 10 19.922 10 21.0956C10 22.2692 13.7688 23.2484 18.7802 23.4766V32H23.2369V23.4743C28.2403 23.2473 32 22.2681 32 21.0956C32 19.9231 28.2403 18.944 23.2369 18.7158ZM23.2369 22.7515V22.7493C23.1116 22.7582 22.4658 22.7965 21.0251 22.7965C19.8747 22.7965 19.0649 22.7627 18.7802 22.7493V22.7526C14.3519 22.5604 11.0467 21.7993 11.0467 20.8888C11.0467 19.9793 14.3519 19.2183 18.7802 19.0227V21.9949C19.0695 22.0152 19.8986 22.0635 21.0444 22.0635C22.4191 22.0635 23.1082 22.0073 23.2369 21.9961V19.0249C27.656 19.2194 30.9533 19.9804 30.9533 20.8888C30.9533 21.7993 27.656 22.5581 23.2369 22.7515Z"
        fill="currentColor"
      />
    </svg>
  );
};
