export const Ubdn = () => {
  return (
    <svg
      width="320"
      style={{ width: "min(100%, 320px)", flex: "0 0 148px" }}
      height="148"
      viewBox="0 0 148 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74 138.75C56.8272 138.75 40.3578 131.928 28.2148 119.785C16.0719 107.642 9.25 91.1728 9.25 74C9.25 56.8272 16.0719 40.3578 28.2148 28.2148C40.3578 16.0719 56.8272 9.25 74 9.25C91.1728 9.25 107.642 16.0719 119.785 28.2148C131.928 40.3578 138.75 56.8272 138.75 74C138.75 91.1728 131.928 107.642 119.785 119.785C107.642 131.928 91.1728 138.75 74 138.75ZM74 148C93.626 148 112.448 140.204 126.326 126.326C140.204 112.448 148 93.626 148 74C148 54.374 140.204 35.5518 126.326 21.6741C112.448 7.79641 93.626 0 74 0C54.374 0 35.5518 7.79641 21.6741 21.6741C7.79641 35.5518 0 54.374 0 74C0 93.626 7.79641 112.448 21.6741 126.326C35.5518 140.204 54.374 148 74 148Z"
        fill="#D9D9D9"
      />
      <path
        d="M73.5879 124.464C60.095 124.464 47.1547 119.104 37.6138 109.563C28.0729 100.022 22.7129 87.0818 22.7129 73.5889C22.7129 60.096 28.0729 47.1557 37.6138 37.6148C47.1547 28.0739 60.095 22.7139 73.5879 22.7139C87.0808 22.7139 100.021 28.0739 109.562 37.6148C119.103 47.1557 124.463 60.096 124.463 73.5889C124.463 87.0818 119.103 100.022 109.562 109.563C100.021 119.104 87.0808 124.464 73.5879 124.464ZM73.5879 129.089C80.8763 129.089 88.0933 127.653 94.8268 124.864C101.56 122.075 107.679 117.987 112.832 112.833C117.986 107.68 122.074 101.561 124.863 94.8278C127.652 88.0942 129.088 80.8772 129.088 73.5889C129.088 66.3005 127.652 59.0835 124.863 52.3499C122.074 45.6164 117.986 39.4981 112.832 34.3444C107.679 29.1908 101.56 25.1027 94.8268 22.3136C88.0933 19.5244 80.8763 18.0889 73.5879 18.0889C58.8684 18.0889 44.7517 23.9362 34.3435 34.3444C23.9352 44.7527 18.0879 58.8694 18.0879 73.5889C18.0879 88.3084 23.9352 102.425 34.3435 112.833C44.7517 123.242 58.8684 129.089 73.5879 129.089Z"
        fill="#D9D9D9"
      />
      <path d="M57.823 105.885L72.3424 119.809L61.4424 90.3545L43.4766 75.8933L57.823 105.885Z" fill="#D9D9D9" />
      <path d="M92.0775 105.885L106.424 75.8933L88.6448 90.2568L77.5582 119.809L92.0775 105.885Z" fill="#D9D9D9" />
      <path d="M45.1168 58.1593L74.9585 75.8933L74.9421 28.1904L45.1168 58.1593Z" fill="#D9D9D9" />
      <path
        d="M104.784 58.1593L74.9421 28.1904M104.784 58.1593L74.9585 75.8933M104.784 58.1593V64.9251L99.8553 68.6921M74.9421 28.1904L45.1168 58.1593M74.9421 28.1904L74.9585 75.8933M74.9585 75.8933L45.1168 58.1593M45.1168 58.1593V65.0228L50.0453 68.6921M72.3424 119.809L57.823 105.885L43.4766 75.8933M72.3424 119.809L68.0611 82.6097L50.0453 68.6921M72.3424 119.809L61.4424 90.3545L43.4766 75.8933M72.3424 119.809H77.5582M43.4766 75.8933L50.0453 68.6921M77.5582 119.809L81.8394 82.6097L99.8553 68.6921M77.5582 119.809L92.0775 105.885L106.424 75.8933M77.5582 119.809L88.6448 90.2568L106.424 75.8933M99.8553 68.6921L106.424 75.8933"
        stroke="#D9D9D9"
        stroke-width="3.04457"
      />
    </svg>
  );
};
