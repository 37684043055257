import { Stack, Typography, useMediaQuery } from "@mui/material";
import { Bing } from "../icons/Bing";
import { Bitget } from "../icons/Bitget";
import { ByBit } from "../icons/ByBit";
import { Indodax } from "../icons/Indodax";
import { Kucoin } from "../icons/Kucoin";
import { Okx } from "../icons/Okx";

import s from "../HomePage.module.css";

export const MassiveListings = () => {
  const ipad = useMediaQuery("(max-width:750px)");
  return (
    <>
      <Typography
        sx={{
          "@media(max-width:1095px)": {
            mt: 5,
          },
        }}
        mt={4}
        mb={4}
        color="rgba(39, 56, 82, 1)"
        fontSize="24px"
        fontWeight="700"
        textAlign={ipad ? "center" : "left"}
      >
        Massive listings are on the way
      </Typography>
      <Stack
        sx={{
          "@media(max-width:1160px)": {
            rowGap: 1,
            columnGap: 6,
          },
          "@media(max-width:1075px)": {
            columnGap: 4,
            justifyContent: "flex-start",
            rowGap: "10px",
          },
          "@media(max-width:817px)": {
            maxWidth: "300px",
            justifyContent: "flex-start",
          },
          "@media(max-width:750px)": {
            maxWidth: "480px",
            margin: "0 auto",
            rowGap: 2,
            justifyContent: "center",
            flexWrap: "wrap",
          },
        }}
        flexDirection="row"
        flexWrap="wrap"
        alignItems="center"
        rowGap={3}
        columnGap={6}
      >
        <ByBit className={s.homeIcon} />
        <Okx className={s.homeIcon} />
        <Bing className={s.homeIcon} />
        <Bitget className={s.homeIcon} />
        <Kucoin className={s.homeIcon} />
        <Indodax className={s.homeIcon} />
        <img style={{ marginTop: "-20px" }} width="90px" height="90px" src="/icon/open_sesame_night.png" alt="" />
      </Stack>
    </>
  );
};
