import { Typography } from "@mui/material";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import React from "react";
import { useAccount } from "wagmi";
import Iconify from "~/components/iconify";
import { StyledCard } from "~/components/settings/styles";
import { useValidNetwork } from "~/hooks/useValidNetwork";

const SwitchNetwork: React.FC = () => {
  const isValidNetwork = useValidNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  if (isValidNetwork || !address) return <></>;

  return (
    <StyledCard
      isWarning
      sx={{ height: 48, px: 2, width: "max-content", color: "unset" }}
      // ref={boxRef}
      onClick={() => open({ view: "Networks" })}
    >
      <Iconify icon="typcn:warning-outline" sx={{ mr: 1 }} />
      <Typography variant="body2">Switch network</Typography>
    </StyledCard>
  );
};

export default SwitchNetwork;
