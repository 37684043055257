import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { ReactElement, ReactNode, Suspense } from "react";
import { WagmiProvider } from "wagmi";
import { SettingsProvider, ThemeSettings } from "~/components/settings";
import { Loading } from "~/shared/ui";
import ThemeProvider from "~/theme";
import { HeaderProvider } from "./ubdn-app/providers/HeaderProvider";
import { WalletProvider } from "./ubdn-app/providers/WalletProvider";

import { config } from "./wagmi";

const queryClient = new QueryClient();

export default function Provider({ children }: { children: ReactElement | ReactNode }) {
  return (
    <Suspense fallback={<Loading />}>
      <SettingsProvider>
        <ThemeProvider>
          <ThemeSettings>
            <SnackbarProvider
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              autoHideDuration={3000}
              variant="success"
            >
              <WagmiProvider config={config}>
                <HeaderProvider>
                  <QueryClientProvider client={queryClient}>
                    <WalletProvider>{children}</WalletProvider>
                  </QueryClientProvider>
                </HeaderProvider>
              </WagmiProvider>
            </SnackbarProvider>
          </ThemeSettings>
        </ThemeProvider>
      </SettingsProvider>
    </Suspense>
  );
}
