interface TelegramIconProps {
  style?: React.CSSProperties;
}

export const TelegramIcon = ({ style }: TelegramIconProps) => {
  return (
    <svg
      viewBox="0 0 25 22"
      preserveAspectRatio="xMidYMid meet"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6485 0.643667C21.9454 0.516458 22.2704 0.472584 22.5896 0.516612C22.9089 0.56064 23.2107 0.690961 23.4637 0.894011C23.7166 1.09706 23.9115 1.36542 24.028 1.67114C24.1445 1.97687 24.1784 2.3088 24.1261 2.63237L21.4009 19.4581C21.1366 21.0811 19.3871 22.0118 17.9248 21.2034C16.7016 20.527 14.8849 19.485 13.2507 18.3977C12.4337 17.8534 9.9308 16.1106 10.2384 14.8704C10.5027 13.81 14.7082 9.82522 17.1114 7.45614C18.0546 6.52539 17.6244 5.98846 16.5106 6.84461C13.7446 8.9703 9.30359 12.2029 7.83527 13.1128C6.53999 13.9152 5.86471 14.0521 5.05725 13.9152C3.58413 13.6656 2.21796 13.2792 1.1029 12.8083C-0.403861 12.1723 -0.330565 10.0637 1.1017 9.44974L21.6485 0.643667Z"
        fill="currentColor"
      />
    </svg>
  );
};
