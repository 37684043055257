import { styled } from "@mui/material";
import React from "react";
import Iconify from "~/components/iconify/Iconify";

interface SwapperSwapButtonProps {
  onClick?: () => void;
  style?: React.CSSProperties;
}

const CenterIcon = styled("div")`
  border-radius: 24px;
  background: #fff;
  box-shadow:
    0px 12px 24px -4px rgba(0, 0, 0, 0.12),
    0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  z-index: 2;
`;

const SwapperSwapButton: React.FC<SwapperSwapButtonProps> = ({ onClick, style }: SwapperSwapButtonProps) => (
  <CenterIcon onClick={onClick} style={style}>
    <Iconify
      icon={onClick ? "lucide:arrow-up-down" : "formkit:arrowdown"}
      width="18px"
      height="18px"
      sx={{ color: "#00AB55" }}
    />
  </CenterIcon>
);

export default SwapperSwapButton;
