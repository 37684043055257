import TagManager from "react-gtm-module";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const useDataLayer = () => {
  const setDataLayer = (event: string) => {
    console.log(event);
    TagManager.dataLayer({ dataLayer: { event: event } });
  };
  return { setDataLayer };
};

