import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

const images = [
  "/imagesPartners/Partner.png",
  "/imagesPartners/Partner-1.png",
  "/imagesPartners/Partner-2.png",
  "/imagesPartners/Partner-13.png",
  "/imagesPartners/Partner-4.png",
  "/imagesPartners/Partner-5.png",
  "/imagesPartners/Partner-6.png",
  "/imagesPartners/Partner-7.png",
  "/imagesPartners/Partner-8.png",
  "/imagesPartners/Partner-9.png",
  "/imagesPartners/Partner-10.png",
  "/imagesPartners/Partner-11.png",
  "/imagesPartners/Partner-12.png",
  "/imagesPartners/Partner-3.png",
];

export const Partners = () => {
  const media = useMediaQuery("(max-width:1024px)");
  const tablet = useMediaQuery("(max-width:675px)");
  const mobile = useMediaQuery("(max-width:650px)");

  return (
    <Box>
      <Stack mb="62px" flexDirection="row" alignItems="center" justifyContent={media ? "center" : "space-between"}>
        <Typography color="#F1F1F3" fontSize="24px" fontWeight="700">
          Partners and Integratons
        </Typography>
      </Stack>
      {mobile ? (
        <Twins />
      ) : (
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={tablet ? "12px" : "36px"}
          justifyContent="center"
          mb="40px"
          flexWrap={"wrap"}
        >
          {images.map((img) => (
            <img style={{ width: "min(100%, 288px)" }} key={img} src={img} />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export const Twins = () => {
  return (
    <Grid mb="40px" columnSpacing={"12px"} container spacing={"12px"}>
      {images.map((img) => (
        <Grid item xs={6} key={img}>
          <img style={{ width: "100%" }} src={img} />
        </Grid>
      ))}
    </Grid>
  );
};
