export const Bing = ({className}:{className:string}) => {
  return (
    <svg
      className={className}
      width="69"
      height="21"
      viewBox="0 0 69 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.0898 6.93042H39.5294V15.3974H37.0898V6.93042Z"
        fill="#273852"
      />
      <path
        d="M35.5177 10.3849C35.2546 9.90409 34.8786 9.49311 34.4249 9.18899C34.9858 8.55525 35.3165 7.71357 35.2761 6.79215C35.1936 4.96493 33.6266 3.55444 31.7924 3.55444H26.707V15.3964H32.5256C34.4052 15.3964 35.9293 13.8783 35.9293 12.0034C35.9293 11.4173 35.7808 10.8666 35.5177 10.3849ZM32.147 13.3867H29.1458V10.4803H32.1297C32.9132 10.4803 33.5763 11.1009 33.5763 11.9343C33.5763 12.7571 32.9148 13.3867 32.147 13.3867ZM31.5095 8.47059H29.1458V5.56496H31.4913C32.2757 5.56496 32.9388 6.18472 32.9388 7.01819C32.9371 7.84015 32.2757 8.47059 31.5095 8.47059Z"
        fill="#273852"
      />
      <path
        d="M68.3881 3.90576H65.5815L63.3894 7.40814L61.198 3.90576H58.3906L61.9865 9.65127L58.3906 15.3976H61.198L63.3894 11.8952L65.5815 15.3976H68.3881L64.7931 9.65127L68.3881 3.90576Z"
        fill="#273852"
      />
      <path
        d="M48.2301 10.6876V15.3966H45.7913V10.266C45.7913 9.51962 45.1736 8.91712 44.4181 8.93849C43.6907 8.96069 43.129 9.59442 43.129 10.321V15.395H40.6895V6.92798H43.129V7.33156C43.6783 7.07264 44.2903 6.92798 44.9377 6.92798C45.2618 6.92798 45.5777 6.96414 45.8812 7.03319C47.2329 7.33978 48.188 8.54313 48.2226 9.92403C48.2276 10.1723 48.2301 10.4287 48.2301 10.6876Z"
        fill="#273852"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.0898 3.55444H39.5294V5.9858H37.0898V3.55444Z"
        fill="#273852"
      />
      <path
        d="M57.7356 6.93011H53.5286C53.5146 6.93011 53.5014 6.92847 53.4865 6.92847C51.141 6.92847 49.2391 8.82473 49.2391 11.1632C49.2391 13.4902 51.122 15.3774 53.4502 15.3947C53.461 15.3971 53.4733 15.3971 53.4865 15.3971C54.3921 15.3971 55.296 15.6618 55.296 16.4468C55.296 17.2433 54.477 17.9197 53.4865 17.9197C52.4878 17.9197 51.6779 17.1126 51.6779 16.1172H49.2383C49.2383 18.4548 51.141 20.3511 53.4857 20.3511C55.8329 20.3511 57.7331 18.4548 57.7331 16.1172C57.7331 16.1172 57.7801 14.8522 56.7038 13.9266C57.3471 13.1861 57.7339 12.2219 57.7339 11.1632C57.7339 10.347 57.503 9.58587 57.1014 8.93981H57.7356V6.93011ZM53.4865 12.9658C52.4878 12.9658 51.6779 12.1586 51.6779 11.1632C51.6779 10.1678 52.4878 9.36065 53.4865 9.36065C54.4853 9.36065 55.296 10.1678 55.296 11.1632C55.296 12.1586 54.4853 12.9658 53.4865 12.9658Z"
        fill="#273852"
      />
      <path
        d="M22.7036 0.624023C20.0941 0.624023 17.5143 1.28159 15.2413 2.52686C14.024 3.19347 12.8999 4.02037 11.9011 4.98617C11.6661 5.21221 11.436 5.44894 11.2158 5.69059C10.4793 6.4994 9.82607 7.38712 9.27762 8.32827C9.08298 8.66609 8.8669 8.9957 8.63597 9.31051C8.60546 9.35161 8.57659 9.3927 8.5436 9.43298C6.52381 12.097 3.44671 13.6242 0.101562 13.6242V18.5461C2.71104 18.5461 5.29083 17.8885 7.56381 16.644C8.77865 15.9791 9.90277 15.1505 10.904 14.1847C11.1391 13.9579 11.3692 13.7212 11.5902 13.4803C12.3275 12.6707 12.9782 11.7846 13.5267 10.8426L13.563 10.7818C13.7485 10.4645 13.9531 10.1555 14.1683 9.86122C14.1988 9.82095 14.2294 9.77903 14.2615 9.73628C16.2805 7.07313 19.3584 5.5451 22.7036 5.5451V0.624023Z"
        fill="#273852"
      />
      <path
        d="M22.7036 13.6255C19.3584 13.6255 16.2805 12.0975 14.2599 9.43353C14.2302 9.39326 14.1988 9.35298 14.1683 9.30942C13.9382 8.99707 13.7221 8.66664 13.5275 8.328C12.9791 7.38767 12.3267 6.50078 11.5894 5.69114C11.3692 5.45113 11.1391 5.21441 10.904 4.98755C9.90524 4.02174 8.78195 3.19403 7.56381 2.52824C5.29083 1.28379 2.71104 0.626221 0.101562 0.626221V5.54812C3.44671 5.54812 6.52464 7.07533 8.54525 9.7393C8.57659 9.77958 8.60628 9.8215 8.6368 9.86178C8.8537 10.1569 9.05824 10.4667 9.24133 10.7807L9.28009 10.8448C9.82772 11.7843 10.4793 12.6704 11.2158 13.4809C11.4351 13.7209 11.6661 13.9576 11.9011 14.1845C12.9015 15.1503 14.0256 15.9788 15.2413 16.6438C17.5143 17.8882 20.0941 18.5458 22.7036 18.5458V13.6255Z"
        fill="#273852"
      />
    </svg>
  );
};
