import { Chain } from "@rainbow-me/rainbowkit";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { mainnet, sepolia } from "viem/chains";

export const chains = [mainnet, sepolia];

// export const wagmiConfig = getDefaultConfig({
//   appName: "UBDN App",
//   projectId: "13325d8ac5a301835db32cc39e7f2388",
//   chains: [
//     // sepolia,
//     // mainnet,
//     {
//       ...mainnet,
//       rpcUrls: {
//         default: {
//           http: [
//             "https://rpc.ankr.com/eth/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             "https://eth.llamarpc.com",
//           ],
//           webSocket: [
//             "wss://rpc.ankr.com/eth/ws/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             "wss://ethereum-rpc.publicnode.com",
//           ],
//         },
//       },
//     },
//     {
//       ...sepolia,
//       rpcUrls: {
//         default: {
//           http: [
//             "https://rpc.ankr.com/eth_sepolia/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             "https://gateway.tenderly.co/sepolia",
//           ],
//           webSocket: [
//             "wss://rpc.ankr.com/eth_sepolia/ws/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             "wss://sepolia.gateway.tenderly.co",
//           ],
//         },
//       },
//     },
//   ],
// });

export const wcProjectId = "13325d8ac5a301835db32cc39e7f2388";

export const wagmiConfig = defaultWagmiConfig({
  chains: [sepolia, mainnet],
  projectId: wcProjectId,
  metadata: {
    name: "UBDN App",
    description: "Exclusiveness of Inheritance & Long Term Investments with no Human Factors involved.",
    url: "https://app.ubdn.com",
    icons: ["https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/fe085738-3178-4d34-0563-b5e832245600/sm"],
  },
  // ...wagmiOptions // Optional - Override createConfig parameters
});

// const { publicClient, webSocketPublicClient, chains } = configureChains(
//   [mainnet, sepolia],
//   [
//     jsonRpcProvider({
//       rpc: (chain: { id: any }) => {
//         switch (chain.id) {
//           case sepolia.id:
//             return {
//               http: "https://rpc.ankr.com/eth_sepolia/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//               webSocket:
//                 "wss://rpc.ankr.com/eth_sepolia/ws/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             };
//           case mainnet.id:
//           default:
//             return {
//               http: "https://rpc.ankr.com/eth/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//               webSocket:
//                 "wss://rpc.ankr.com/eth/ws/1834d2b0ecb7a90f519238e352c3f0836a2074170ac57ce5f53154c3415423d1",
//             };
//         }

//         return { http: "https://rpc.ankr.com/eth" };
//       },
//     }),
//   ],
// );

// export const metaMaskConnector = new MetaMaskConnector({
//   chains,
//   options: {
//     shimDisconnect: true,
//   },
// });

// export const walletConnectConnector = new WalletConnectConnector({
//   chains,
//   options: {
//     projectId: "13325d8ac5a301835db32cc39e7f2388",
//     relayUrl: "wss://relay.walletconnect.com",
//     metadata: {
//       name: "UBDN App",
//       description: "UBDN App",
//       url: "https://app.ubdn.com",
//       icons: ["https://ubdn.com/android-chrome-512x512.png"],
//     },
//   },
// });

// // Coinbase requires Buffer to be available globally
// window.Buffer = window.Buffer || Buffer;

// export const coinbaseConnector = new CoinbaseWalletConnector({
//   chains,
//   options: {
//     appName: "UBDN App",
//   },
// });

// export const injectedConnector = new InjectedConnector({ chains });

// export const trustConnector = new TrustWalletConnector({
//   chains,
//   options: {
//     shimDisconnect: true,
//   },
// });

// export const wagmiConfig = createConfig({
//   autoConnect: true,
//   publicClient,
//   webSocketPublicClient,
//   connectors: [
//     metaMaskConnector,
//     walletConnectConnector,
//     coinbaseConnector,
//     injectedConnector,
//     trustConnector,
//   ],
// });

export const resolveChainFromName = (name: string) => {
  let chain: Chain | undefined;

  switch (name) {
    case "mainnet":
      chain = mainnet;
      break;
    case "sepolia":
      chain = sepolia;
      break;
    default:
      chain = sepolia;
  }

  return chain as Chain;
};

export const resolveNameFromChain = (chain: Chain, defaultResolve = true) => {
  let name: string | undefined;

  switch (chain.id) {
    case mainnet.id:
      name = "mainnet";
      break;
    case sepolia.id:
      name = "sepolia";
      break;
    default:
      if (defaultResolve) name = "sepolia";
  }

  return name;
};
