export const Formal = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2339_3187)">
        <path
          d="M22.75 0.8125H3.25C1.90381 0.8125 0.8125 1.90381 0.8125 3.25V22.75C0.8125 24.0962 1.90381 25.1875 3.25 25.1875H22.75C24.0962 25.1875 25.1875 24.0962 25.1875 22.75V3.25C25.1875 1.90381 24.0962 0.8125 22.75 0.8125Z"
          fill="url(#paint0_linear_2339_3187)"
          fill-opacity="0.6"
        />
        <path
          d="M22.4262 1.94995H3.57617C2.67871 1.94995 1.95117 2.67749 1.95117 3.57495V22.425C1.95117 23.3224 2.67871 24.05 3.57617 24.05H22.4262C23.3236 24.05 24.0512 23.3224 24.0512 22.425V3.57495C24.0512 2.67749 23.3236 1.94995 22.4262 1.94995Z"
          fill="url(#paint1_linear_2339_3187)"
        />
        <path
          d="M11.8773 13.1999H10.0061L9.68188 15.0549H8.4082L9.30212 9.99255H12.8314L12.6346 11.0902H10.3697L10.1937 12.1022H12.0649L11.875 13.1999H11.8773Z"
          fill="#DA5C57"
        />
        <path
          d="M14.8903 13.8252L16.2265 9.99255H17.5975L15.6777 15.0549H13.4268L13.3711 9.99255H14.749L14.7282 13.8252H14.8903Z"
          fill="#DA5C57"
        />
        <path
          d="M8.41211 16.0346V17.0512C8.41211 17.1439 8.48393 17.2181 8.57428 17.2181H17.4345C17.5249 17.2181 17.5966 17.1439 17.5966 17.0512V16.0346C17.5966 15.942 17.5225 15.8679 17.4345 15.8679H8.57428C8.48393 15.8679 8.41211 15.942 8.41211 16.0346Z"
          fill="#8B8C90"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.81949 7.58186C8.85654 7.66523 8.9191 7.71386 8.98857 7.71386H16.9665C17.0336 7.71386 17.0985 7.66291 17.1355 7.58186L17.6982 6.31511C17.784 6.1229 17.689 5.86816 17.5315 5.86816H8.43046C8.27292 5.86816 8.17802 6.1229 8.26138 6.31511L8.82412 7.58186H8.81949Z"
          fill="#DA5C57"
        />
        <path
          d="M19.0752 6.53552H18.9942L18.3365 7.68416C18.3365 7.68416 18.3319 7.69111 18.3296 7.69342H19.0752C19.3161 7.69342 19.5129 7.89027 19.5129 8.13112V18.536C19.5129 18.7769 19.3161 18.9737 19.0752 18.9737H6.93348C6.69264 18.9737 6.49579 18.7769 6.49579 18.536V8.13112C6.49579 7.89027 6.69264 7.69342 6.93348 7.69342H7.62359C7.62359 7.69342 7.61896 7.68648 7.61664 7.68416L6.95895 6.53552H6.93348C6.05348 6.53552 5.33789 7.25111 5.33789 8.13112V18.536C5.33789 19.416 6.05348 20.1316 6.93348 20.1316H19.0752C19.9552 20.1316 20.6708 19.416 20.6708 18.536V8.13112C20.6708 7.25111 19.9552 6.53552 19.0752 6.53552Z"
          fill="#8B8C90"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2339_3187"
          x1="13.0008"
          y1="25.1886"
          x2="13.0008"
          y2="0.812921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5F5F5F" />
          <stop offset="0.99" stop-color="#CBCBCB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2339_3187"
          x1="13.0012"
          y1="24.3069"
          x2="13.0012"
          y2="1.69297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9E9E9" />
          <stop offset="0.630208" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_2339_3187">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
