import { IconProps } from "@mui/material";

export const UbdnIcon = ({ style }: IconProps) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M21 39.375C16.1266 39.375 11.4529 37.4391 8.00691 33.9931C4.56093 30.5471 2.625 25.8734 2.625 21C2.625 16.1266 4.56093 11.4529 8.00691 8.00691C11.4529 4.56093 16.1266 2.625 21 2.625C25.8734 2.625 30.5471 4.56093 33.9931 8.00691C37.4391 11.4529 39.375 16.1266 39.375 21C39.375 25.8734 37.4391 30.5471 33.9931 33.9931C30.5471 37.4391 25.8734 39.375 21 39.375ZM21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42Z"
      fill="currentColor"
    />
    <path
      d="M20.8828 35.3208C17.0537 35.3208 13.3815 33.7997 10.674 31.0922C7.9664 28.3846 6.44531 24.7124 6.44531 20.8833C6.44531 17.0542 7.9664 13.382 10.674 10.6744C13.3815 7.96689 17.0537 6.4458 20.8828 6.4458C24.7119 6.4458 28.3841 7.96689 31.0917 10.6744C33.7992 13.382 35.3203 17.0542 35.3203 20.8833C35.3203 24.7124 33.7992 28.3846 31.0917 31.0922C28.3841 33.7997 24.7119 35.3208 20.8828 35.3208ZM20.8828 36.6333C22.9511 36.6333 24.9992 36.2259 26.9101 35.4344C28.821 34.6429 30.5572 33.4828 32.0197 32.0202C33.4823 30.5577 34.6424 28.8214 35.4339 26.9106C36.2254 24.9997 36.6328 22.9516 36.6328 20.8833C36.6328 18.815 36.2254 16.7669 35.4339 14.856C34.6424 12.9452 33.4823 11.2089 32.0197 9.74637C30.5572 8.28385 28.821 7.12371 26.9101 6.3322C24.9992 5.54069 22.9511 5.1333 20.8828 5.1333C16.7057 5.1333 12.6996 6.79267 9.74588 9.74637C6.79218 12.7001 5.13281 16.7061 5.13281 20.8833C5.13281 25.0605 6.79218 29.0665 9.74588 32.0202C12.6996 34.9739 16.7057 36.6333 20.8828 36.6333Z"
      fill="currentColor"
    />
    <path d="M16.4092 30.0483L20.5295 34L17.4363 25.6412L12.3379 21.5373L16.4092 30.0483Z" fill="currentColor" />
    <path d="M26.1301 30.0483L30.2014 21.5373L25.1559 25.6134L22.0097 34L26.1301 30.0483Z" fill="currentColor" />
    <path d="M12.8034 16.5047L21.272 21.5373L21.2673 8L12.8034 16.5047Z" fill="#273852" />
    <path
      d="M29.7359 16.5047L21.2673 8M29.7359 16.5047L21.272 21.5373M29.7359 16.5047V18.4247L28.3373 19.4937M21.2673 8L12.8034 16.5047M21.2673 8L21.272 21.5373M21.272 21.5373L12.8034 16.5047M12.8034 16.5047V18.4524L14.202 19.4937M20.5295 34L16.4092 30.0483L12.3379 21.5373M20.5295 34L19.3146 23.4433L14.202 19.4937M20.5295 34L17.4363 25.6412L12.3379 21.5373M20.5295 34H22.0097M12.3379 21.5373L14.202 19.4937M22.0097 34L23.2247 23.4433L28.3373 19.4937M22.0097 34L26.1301 30.0483L30.2014 21.5373M22.0097 34L25.1559 25.6134L30.2014 21.5373M28.3373 19.4937L30.2014 21.5373"
      stroke="currentColor"
      strokeWidth="0.864"
    />
  </svg>
);
