export const Gold = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2322_2954)">
        <mask id="mask0_2322_2954" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="27">
          <path
            d="M22.752 0.00012207H3.25195C1.45703 0.00012207 0.00195312 1.4552 0.00195312 3.25012V22.7501C0.00195312 24.545 1.45703 26.0001 3.25195 26.0001H22.752C24.5469 26.0001 26.002 24.545 26.002 22.7501V3.25012C26.002 1.4552 24.5469 0.00012207 22.752 0.00012207Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2322_2954)">
          <path
            d="M7.05529 6.06044C7.10532 5.99611 7.18223 5.9585 7.26372 5.9585H18.7416C18.8231 5.9585 18.8999 5.99611 18.95 6.06044L19.7128 7.04124C19.8477 7.21469 19.7241 7.46742 19.5044 7.46742H6.50088C6.28114 7.46742 6.15753 7.21469 6.29244 7.04124L7.05529 6.06044Z"
            fill="#E5453D"
          />
          <path
            d="M21.4272 8.87964H4.57747C4.31571 8.87964 4.10352 9.09184 4.10352 9.3536V19.6646C4.10352 19.9263 4.31571 20.1385 4.57747 20.1385H21.4272C21.689 20.1385 21.9012 19.9263 21.9012 19.6646V9.3536C21.9012 9.09184 21.689 8.87964 21.4272 8.87964Z"
            fill="#D1D1D3"
          />
          <g opacity="0.8">
            <path
              d="M3.69727 9.35345C3.69727 8.86733 4.09134 8.47327 4.57747 8.47327H19.1009C19.5871 8.47327 19.9811 8.86733 19.9811 9.35345V10.2336H3.69727V9.35345Z"
              fill="#74757A"
            />
          </g>
          <path
            opacity="0.9"
            d="M12.2467 11.9941V12.7208H11.8337L7.8277 19.0358H5.85834L5.83912 12.6901H5.23391L5.20508 11.9941H7.64517V15.515L9.48001 12.6185V11.9941H12.2467Z"
            fill="#E5453D"
          />
          <path
            opacity="0.8"
            d="M15.2656 12.5461V13.454C15.2656 13.5367 15.3359 13.6036 15.4225 13.6036H20.6414C20.7281 13.6036 20.7983 13.5367 20.7983 13.454V12.5461C20.7983 12.4635 20.7281 12.3965 20.6414 12.3965H15.4225C15.3359 12.3965 15.2656 12.4635 15.2656 12.5461Z"
            fill="#5D5E64"
          />
          <path
            opacity="0.8"
            d="M13.7559 15.2623V16.1701C13.7559 16.2528 13.8244 16.3198 13.9089 16.3198H20.6444C20.729 16.3198 20.7975 16.2528 20.7975 16.1701V15.2623C20.7975 15.1796 20.729 15.1127 20.6444 15.1127H13.9089C13.8244 15.1127 13.7559 15.1796 13.7559 15.2623Z"
            fill="#5D5E64"
          />
          <path
            opacity="0.8"
            d="M12.248 17.9783V18.8862C12.248 18.9688 12.316 19.0359 12.3997 19.0359H20.647C20.7308 19.0359 20.7986 18.9688 20.7986 18.8862V17.9783C20.7986 17.8957 20.7308 17.8287 20.647 17.8287H12.3997C12.316 17.8287 12.248 17.8957 12.248 17.9783Z"
            fill="#5D5E64"
          />
          <path
            d="M21.4272 8.87964H4.57747C4.31571 8.87964 4.10352 9.09184 4.10352 9.3536V19.6646C4.10352 19.9263 4.31571 20.1385 4.57747 20.1385H21.4272C21.689 20.1385 21.9012 19.9263 21.9012 19.6646V9.3536C21.9012 9.09184 21.689 8.87964 21.4272 8.87964Z"
            stroke="#171921"
            stroke-width="0.8125"
          />
          <path
            d="M0.00195312 3.25012C0.00195312 1.4552 1.45703 0.00012207 3.25195 0.00012207H22.752C24.5468 0.00012207 26.002 1.4552 26.002 3.25012V22.7501C26.002 24.545 24.5468 26.0001 22.752 26.0001H3.25195C1.45703 26.0001 0.00195312 24.545 0.00195312 22.7501V3.25012Z"
            fill="#2F3038"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.752 1.62512H3.25195C2.35449 1.62512 1.62695 2.35266 1.62695 3.25012V22.7501C1.62695 23.6476 2.35449 24.3751 3.25195 24.3751H22.752C23.6494 24.3751 24.377 23.6476 24.377 22.7501V3.25012C24.377 2.35266 23.6494 1.62512 22.752 1.62512ZM3.25195 0.00012207C1.45703 0.00012207 0.00195312 1.4552 0.00195312 3.25012V22.7501C0.00195312 24.545 1.45703 26.0001 3.25195 26.0001H22.752C24.5468 26.0001 26.002 24.545 26.002 22.7501V3.25012C26.002 1.4552 24.5468 0.00012207 22.752 0.00012207H3.25195Z"
            fill="url(#paint0_linear_2322_2954)"
          />
          <path
            d="M22.1085 8.18677H3.89534C3.54001 8.18677 3.25195 8.47488 3.25195 8.83019V20.6869C3.25195 21.0422 3.54001 21.3303 3.89534 21.3303H22.1085C22.4639 21.3303 22.752 21.0422 22.752 20.6869V8.83019C22.752 8.47488 22.4639 8.18677 22.1085 8.18677Z"
            fill="url(#paint1_linear_2322_2954)"
          />
          <path
            opacity="0.8"
            d="M13.7637 15.1819V16.1252C13.7637 16.2107 13.833 16.2801 13.9185 16.2801H20.7248C20.8103 16.2801 20.8796 16.2107 20.8796 16.1252V15.1819C20.8796 15.0964 20.8103 15.0271 20.7248 15.0271H13.9185C13.833 15.0271 13.7637 15.0964 13.7637 15.1819Z"
            fill="#5D5E64"
          />
          <path
            opacity="0.8"
            d="M12.1348 18.1035V19.0468C12.1348 19.1323 12.2041 19.2016 12.2895 19.2016H20.6971C20.7826 19.2016 20.8519 19.1323 20.8519 19.0468V18.1035C20.8519 18.018 20.7826 17.9487 20.6971 17.9487H12.2895C12.2041 17.9487 12.1348 18.018 12.1348 18.1035Z"
            fill="#5D5E64"
          />
          <path
            opacity="0.8"
            d="M15.4668 12.2648V13.2082C15.4668 13.2936 15.5361 13.3629 15.6216 13.3629H20.7687C20.8542 13.3629 20.9235 13.2936 20.9235 13.2082V12.2648C20.9235 12.1793 20.8542 12.11 20.7687 12.11H15.6216C15.5361 12.11 15.4668 12.1793 15.4668 12.2648Z"
            fill="#5D5E64"
          />
          <path
            opacity="0.45"
            d="M3.90018 8.18677H22.1037C22.4617 8.18677 22.752 8.47707 22.752 8.83506V10.1798H3.25195V8.83506C3.25195 8.47707 3.5422 8.18677 3.90018 8.18677Z"
            fill="#555D64"
          />
          <path
            opacity="0.9"
            d="M12.4741 12.1099V12.8404H12.0291L7.72857 19.2017H5.61457L5.60006 12.8016H4.947L4.91797 12.1099H7.53506V15.651L9.49909 12.7387V12.1099H12.4741Z"
            fill="#171921"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0916 6.62427H5.91772C5.58877 6.62427 5.39043 6.29532 5.57909 6.0486L6.50789 4.82955C6.58529 4.72797 6.71107 4.66992 6.84652 4.66992H19.1386C19.2741 4.66992 19.3999 4.72797 19.4773 4.82955L20.4254 6.0486C20.6141 6.29049 20.4206 6.62427 20.0867 6.62427H20.0916Z"
            fill="#E5453D"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2322_2954"
          x1="-4.94629"
          y1="-2.26257"
          x2="40.7691"
          y2="16.268"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00726675" stop-color="#7D571B" />
          <stop offset="0.0982138" stop-color="#AE8035" />
          <stop offset="0.38988" stop-color="#EBD25B" />
          <stop offset="0.494047" stop-color="#F4EB85" />
          <stop offset="0.624255" stop-color="#FEDC39" />
          <stop offset="1" stop-color="#B18433" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2322_2954"
          x1="-0.459231"
          y1="7.04296"
          x2="28.858"
          y2="24.6738"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00726675" stop-color="#7D571B" />
          <stop offset="0.0982138" stop-color="#AE8035" />
          <stop offset="0.38988" stop-color="#EBD25B" />
          <stop offset="0.494047" stop-color="#F4EB85" />
          <stop offset="0.624255" stop-color="#FEDC39" />
          <stop offset="1" stop-color="#B18433" />
        </linearGradient>
        <clipPath id="clip0_2322_2954">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
