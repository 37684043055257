import { CircularProgress, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Form } from "react-router-dom";
import { instance } from "~/shared/lib/axios";

const createLink = async (name: string | void) => {
  const data = (await instance.post(`/links/create`, { name })).data;
  return data;
};

export const CreateReferral = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, error, isPending } = useMutation({
    mutationFn: (newLink) => createLink(newLink),
    onSuccess: () => {
      enqueueSnackbar("Successfully create new invite link");
      queryClient.invalidateQueries({
        queryKey: ["inviteLinks"],
      });
    },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Invalid name", { variant: "error" });
    }
  }, [error]);

  const onSubmit = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const fieldset: any = Object.fromEntries(formData);
    mutate(fieldset.name);
    return event.target.reset();
  };

  return (
    <Stack
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 10,
          maxWidth: "400px",
        }}
      >
        {isPending && (
          <Stack flex={1} alignItems={"center"}>
            <CircularProgress />
          </Stack>
        )}
      </Form>
    </Stack>
  );
};
